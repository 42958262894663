import { WebStorageStateStore } from 'oidc-client-ts';
import { environment } from '../../environments/environment';

function getAppSettings() {
  const isTemplate = environment.isTemplate;
  let authority: string | null = environment.auth_settings.authority,
    clientId: string | null = environment.auth_settings.client_id,
    host: string | null =
      environment.api_url ||
      window.location.protocol + '//' + window.location.host,
    cmsUrl: string | null = environment.cms_url,
    casesUrl: string | null = environment.cases_url,
    path: string | null = '/',
    culture: string | null = environment.culture,
    version: string | null = '1.0.0',
    postLogoutRedirectUri: string | null,
    scope: string | null = environment.auth_settings.scope,
    recaptchaPublicKey: string | null = environment.recaptchaPublicKey,
    msg_url: string | null = environment.msg_url;
  let services_api_url: string | null = environment.services_api_url,
    cmsApiKey: string | null = environment.cmsApiKey,
    nbg_portal_api_url: string | null = environment.nbg_portal_api_url;

  if (isTemplate) {
    const appRoot = document.getElementsByTagName('app-root')[0];
    authority = appRoot.getAttribute('authority');
    clientId = appRoot.getAttribute('clientId');
    host = appRoot.getAttribute('host');
    cmsUrl = appRoot.getAttribute('cmsUrl');
    casesUrl = appRoot.getAttribute('casesUrl');
    path = appRoot.getAttribute('path');
    culture = appRoot.getAttribute('culture');
    version = appRoot.getAttribute('version');
    postLogoutRedirectUri = appRoot.getAttribute('postLogoutRedirectUri');
    scope = appRoot.getAttribute('scope');
    recaptchaPublicKey = appRoot.getAttribute('recaptchaPublicKey');
    cmsApiKey = appRoot.getAttribute('cmsApiKey');
    services_api_url = appRoot.getAttribute('servicesApiUrl');
    nbg_portal_api_url = appRoot.getAttribute('nbgPortalApiUrl');

    appRoot.attributes.removeNamedItem('authority');
    appRoot.attributes.removeNamedItem('clientId');
    appRoot.attributes.removeNamedItem('host');
    appRoot.attributes.removeNamedItem('cmsUrl');
    appRoot.attributes.removeNamedItem('casesUrl');
    appRoot.attributes.removeNamedItem('path');
    appRoot.attributes.removeNamedItem('culture');
    appRoot.attributes.removeNamedItem('version');
    appRoot.attributes.removeNamedItem('postLogoutRedirectUri');
    appRoot.attributes.removeNamedItem('scope');
    appRoot.attributes.removeNamedItem('recaptchaPublicKey');
    appRoot.attributes.removeNamedItem('cmsApiKey');
    appRoot.attributes.removeNamedItem('servicesApiUrl');
    appRoot.attributes.removeNamedItem('ecommerceBaseUrl');
    appRoot.attributes.removeNamedItem('ecommerceMerchantId');
    appRoot.attributes.removeNamedItem('ecommerceApiUsername');
    appRoot.attributes.removeNamedItem('ecommerceApiPassword');
    appRoot.attributes.removeNamedItem('nbgPortalApiUrl');
  }

  var settings = {
    api_url: host,
    cms_url: cmsUrl,
    cases_url: casesUrl,
    msg_url: msg_url,
    auth_settings: {
      authority: authority,
      client_id: clientId,
      filterProtocolClaims: environment.auth_settings.filterProtocolClaims,
      loadUserInfo: environment.auth_settings.loadUserInfo,
      post_logout_redirect_uri: !isTemplate
        ? environment.auth_settings.post_logout_redirect_uri
        : [
          host?.replace(/\/$/su, ''),
          path?.replace(/(^\/)|(\/$)/gsu, ''),
          environment.auth_settings.post_logout_redirect_uri,
        ]
          .filter((x) => x && x.length > 0)
          .join('/'),
      redirect_uri: !isTemplate
        ? environment.auth_settings.redirect_uri
        : [
          host?.replace(/\/$/su, ''),
          path?.replace(/(^\/)|(\/$)/gsu, ''),
          environment.auth_settings.redirect_uri,
        ]
          .filter((x) => x && x.length > 0)
          .join('/'),
      response_type: environment.auth_settings.response_type,
      scope: scope,
      silent_redirect_uri: !isTemplate
        ? environment.auth_settings.silent_redirect_uri
        : [
          host?.replace(/\/$/su, ''),
          path?.replace(/(^\/)|(\/$)/gsu, ''),
          environment.auth_settings.silent_redirect_uri,
        ]
          .filter((x) => x && x.length > 0)
          .join('/'),
      revokeAccessTokenOnSignout:
        environment.auth_settings.revokeAccessTokenOnSignout,
      accessTokenExpiringNotificationTime:
        environment.auth_settings.accessTokenExpiringNotificationTime,
      monitorSession: environment.auth_settings.monitorSession,
      automaticSilentRenew: environment.auth_settings.automaticSilentRenew,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    } as IAuthSettings,
    culture: culture,
    isTemplate: environment.isTemplate,
    production: environment.production,
    version: version,
    recaptchaPublicKey: recaptchaPublicKey,
    cmsApiKey: cmsApiKey,
    services_api_url: services_api_url,
    nbg_portal_api_url: nbg_portal_api_url
  };

  return settings;
}

export interface IAppSettings {
  api_url: string;
  api_docs: string;
  auth_settings: IAuthSettings;
  culture: string;
  isTemplate: boolean;
  production: boolean;
  version: string;
}

export interface IAuthSettings {
  authority: string;
  client_id: string;
  filterProtocolClaims: boolean;
  loadUserInfo: boolean;
  post_logout_redirect_uri: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  silent_redirect_uri: string;
  revokeAccessTokenOnSignout: boolean;
  accessTokenExpiringNotificationTime: number;
  monitorSession: boolean;
  automaticSilentRenew: boolean;
}

export const appSettings = getAppSettings();
