<div class="rounded-medium bg-white py-6 px-4 min-w-[251px]">
    <div class="flex flex-col gap-6">
        <i class="icon-{{ hyperIcon }} text-3xl"></i>
        <div class="flex flex-col gap-2">
            <p class="font-semibold">{{ hyperTitle }}</p>
            <!-- <p class="font-semibold">{{card-hypertitle}}</p> -->
            <h3 class="h3 text-primary">{{ title }}</h3>
            <!-- <h3 class="h3 text-primary">{{card-title}}</h3> -->
        </div>
        <a (click)="proceedToPay()"
            class="btn-primary-sm text-center flex gap-2 items-center justify-center cursor-pointer"><i
                class="icon-{{ buttonIcon }} text-xl text-white"></i>{{ buttonText }}</a>
        <!-- <a class="btn-primary-sm text-center flex gap-2 items-center justify-center" href="#"><i
                class="icon-{{card-cta-icon}} text-xl text-white"></i>{{card-cta-name}}</a> -->
    </div>
</div>