<section class="pt-6 pb-12" *transloco="let t; prefix: 'property-details.map-area-section'">
  <h2 class="h2 text-secondary pb-6">{{t('header')}}</h2>
  <div class="aspect-[800/400]" #gMapContainer>
    <google-map [mapId]="'f5552e3916e0e5d7'" #googleMap [options]="mapOptions" #propertyMap [width]="gMapContainer.clientWidth">
      <map-marker *ngFor="let marker of markers$ | async" [position]="marker.position" [icon]="marker.icon"
        class="text-white">
      </map-marker>
    </google-map>
  </div>
  <div class="flex flex-col gap-5">
    <div class="flex flex-col gap-2 pt-8 2xl:pt-0">
      <p class="font-semibold">{{t('distanceInfoText')}}</p>
      <form class="group/input max-w-[350px]">
        <div
          class="flex text-gray-500 rounded-full bg-white border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
          <button class="flex justify-center items-center gap-1">
            <i class="icon-search text-tone-07 text-xl"></i>
          </button>
          <app-google-maps-autocomplete (setAddress)="addPlace($event)"
            [adressType]="'establishment'"></app-google-maps-autocomplete>
        </div>
      </form>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
      <app-point-of-interest [lat]="lon" [lon]="lon" [circleColor]="'primary'" [icon]="'apartment'"
        [distanceFromProperty]="0" [isPropertyPin]="true"></app-point-of-interest>
      <ng-container #placesHistoryListContainer></ng-container>
      <!-- {{> components/point-of-interest circle-color="primary" point-icon="hospital" text-color="primary"
            metric=false property=true property-text="Property location" points=false}}
            {{> components/point-of-interest circle-color="secondary" point-icon="apartment" text-color="secondary-dark"
            metric=true property=false points=true point-hypertitle="Hospital" point-title="Asklipeioi Voulas Hospital"
            metric-number="5600 m"}}
            {{> components/point-of-interest circle-color="secondary" point-icon="apartment" text-color="secondary-dark"
            metric=true property=false points=true point-hypertitle="Hospital" point-title="Asklipeioi Voulas Hospital"
            metric-number="5600 m"}}
            {{> components/point-of-interest circle-color="secondary" point-icon="apartment" text-color="secondary-dark"
            metric=true property=false points=true point-hypertitle="Hospital" point-title="Asklipeioi Voulas Hospital"
            metric-number="5600 m"}}
            {{> components/point-of-interest circle-color="secondary" point-icon="apartment" text-color="secondary-dark"
            metric=true property=false points=true point-hypertitle="Hospital" point-title="Asklipeioi Voulas Hospital"
            metric-number="5600 m"}}
            {{> components/point-of-interest circle-color="secondary" point-icon="apartment" text-color="secondary-dark"
            metric=true property=false points=true point-hypertitle="Hospital" point-title="Asklipeioi Voulas Hospital"
            metric-number="5600 m"}} -->
    </div>
  </div>
</section>