<div class="chart-container flex flex-col min-w-[323px]" *transloco="let t; prefix: 'shared.loan-calculator.doughnut-chart'">
  <div class="flex justify-center">
    <div class="relative">
      <canvas id="yearsChart">{{ chart }}</canvas>
      <div class="absolute inset-0 flex justify-center items-center">
        <div class="text-center">
          <span class="font-semibold text-gray-700 block">{{t('text.totalLoanAmount')}}</span>
          <span
            class="text-secondary-900 text-[2.5rem] font-bold block">€{{loanValues?.loanCalculatorValues?.loanAmount | number:'1.0':'el'}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-6 min-h-[180px]">
    <div class="flex items-start gap-3"
      *ngIf="loanValues?.loanCalculatorValues && loanValues?.loanCalculatorValues?.loanFixedDuration && loanValues?.payments && loanValues?.payments?.paymentAmountInitial">
      <div
        class="rounded-full bg-secondary-900 text-white font-semibold flex items-center justify-center w-[40px] h-[40px] text-sm leading-none">
        {{loanValues?.loanCalculatorValues?.loanFixedDuration}}{{t('text.yearAbbreviation')}}
      </div>
      <div>
        <p class="text-black font-semibold text-sm">
          <span class="text-secondary-900 text-[1.75rem]">€{{loanValues?.payments?.paymentAmountInitial |
            number:'1.2-2':'el'}}</span> / {{t('text.month')}} {{loanValues?.loanCalculatorValues!.loanFixedDuration <
            loanValues?.loanCalculatorValues!.loanDuration ? t('text.first') + ' ' +
            loanValues?.loanCalculatorValues?.loanFixedDuration + ' ' + t('text.years') : '' }} </p>
            <p class="text-gray-700 text-sm mb-3">{{t('text.fixedInterestRate')}}: {{this.loanValues!.fixedRate}} %</p>
      </div>
    </div>
    <div class="flex items-start gap-3  mb-4"
      *ngIf="loanValues?.loanCalculatorValues && loanValues?.loanCalculatorValues!.loanDuration !== loanValues?.loanCalculatorValues!.loanFixedDuration && loanValues?.payments && (loanValues?.payments!.paymentAmountInitial || loanValues?.payments!.paymentAmountFinal)">
      <div
        class="rounded-full bg-primary-500 text-white font-semibold flex items-center justify-center w-[40px] h-[40px] text-sm leading-none">
        {{loanValues?.loanCalculatorValues!.loanDuration - loanValues?.loanCalculatorValues!.loanFixedDuration}}y
      </div>
      <div>
        <p class="text-black font-semibold text-sm">
          <span class="text-primary-500 text-[1.75rem]">€{{(loanValues?.payments!.paymentAmountFinal > 0 ?
            loanValues?.payments?.paymentAmountFinal : loanValues?.payments?.paymentAmountInitial) |
            number:'1.2-2':'el'}}</span> / {{t('text.month')}} {{ loanValues?.loanCalculatorValues!.loanDuration >
          loanValues?.loanCalculatorValues!.loanFixedDuration && loanValues?.loanCalculatorValues!.loanFixedDuration !==
          0 ? t('text.remaining') + ' ' + (loanValues?.loanCalculatorValues!.loanDuration -
          loanValues?.loanCalculatorValues!.loanFixedDuration) + ' ' + t('text.years') : '' }}
        </p>
        <p class="text-gray-700 text-sm mb-3">{{t('text.variableRate')}}: {{t('text.currently')}} {{loanValues?.variableRate}} %</p>
      </div>
    </div>
    @if (showEstimatedFees && mortgageLoanSelected) {
    <div class="border-t pt-4 flex flex-row gap-x-2 items-center min-h-[60px]">
      <i class="icon-info-circle text-gray-700 text-lg"></i>
      <span class="text-gray-700 text-sm  max-w-[320px] font-normal">{{t('text.estimatedFees')}}:
        €{{totalEstimatedFees | number:'1.2-2':'el' }}</span>
    </div>
    }
  </div>
</div>