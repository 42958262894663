import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, mergeMap, switchMap } from 'rxjs';
import { ReactiveComponentBase } from 'src/app/core/components/ReactiveComponentBase';
import { PortalApiService, PropertyListFilter, PropertyTag } from 'src/app/services/portal-api.service';
import { PropertyListingService } from 'src/app/services/property.service';

@Component({
  selector: 'app-property-tag-filters',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './property-tag-filters.component.html',
  styleUrl: './property-tag-filters.component.css'
})
export class PropertyTagFiltersComponent extends ReactiveComponentBase<propertyTagView[]>{
  allTagsIcon: string | undefined;
  filters: PropertyListFilter | undefined
  noTags: any;
  tags: any;

  constructor(private apiService: PortalApiService,
    private activatedRoute: ActivatedRoute,
    private propertyListingService: PropertyListingService,
    private router: Router) {
    super();

  }

  override onGetModel(model: propertyTagView[]) {

    model.forEach(x => x.isSelected = (this.filters?.tag?.includes(x.id ?? "") ?? false) || (this.filters?.tag?.includes(x.name ?? "") ?? false)) //fill with filters.
  }


  override getModel(): Observable<propertyTagView[]> {
    return this.activatedRoute.queryParamMap.pipe(
      map(params => this.filters = this.propertyListingService.getSearchFiltersFromUrl(params)),
      switchMap(() => this.apiService.getPropertyTags()),
      map(tags => {
        return tags.map(x => {
          let res: propertyTagView = x;
          res.icon = this.getTagIcon(x.id);
          return res;
        });
      })
    );


  }

  getTagIcon(type: string | undefined): string | undefined {
    if (!type) {
      return undefined;
    }
    switch (type) {
      case "489955e7-976c-425b-97f0-192dd5db6c55":
        //luxury
        return "icon-Hotel text-2xl";
      case "ea9592d1-ee29-486e-a4d1-27ffc276d9f9":
        //with view  
        return "icon-Hotel text-2xl";
      case "4cf94163-417d-424c-830b-35b54ac30a5f":
        //island
        return "icon-Hotel text-2xl";
      case "983fef87-3e53-4de8-b65f-667f2adc5264":
        //by the sea
        return "icon-Hotel text-2xl";
      case "9fb2e6d3-7786-4316-8950-9065f5ef57a6":
        //mountains
        return "icon-Hotel text-2xl";
      case "d4245882-e655-4908-bf61-a736dae96437":
        //green 
        return "icon-Hotel text-2xl";
      default:
        return undefined
    }
  }

  toggleFilter(tagId: string) {
    if (this.filters) {
      if (this.filters?.tag?.includes(tagId)) {
        this.filters.tag = undefined;

      }
      else {
        this.filters.tag = [tagId]
      }
      this.router.navigate(['/properties'],
        {
          queryParams: { ...this.filters },
          queryParamsHandling: 'merge'
        }
      )
    }
  }

}

export interface propertyTagView extends PropertyTag {
  icon?: string,
  isSelected?: boolean
}


