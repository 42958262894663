<div class="accordion" [ngClass]="{ 'collapsed': isCollapsed }">
    <div class="flex flex-col justify-between text-tone-09 w-full">
        <button (click)="toggleCollapse()"
            class="flex justify-between items-center py-6 px-4 hover:bg-black/03 duration-300 ease-out">
            <h5 class="text-xl font-light">{{ question }}</h5>
            <i class="icon-chevron-down text-[20px]"
                [ngClass]="{ 'icon-chevron-down' : !isCollapsed, 'icon-chevron-up': isCollapsed }"></i>
        </button>
        <div class="accordion-details">
            <div class="flex flex-col justify-between gap-6 text-tone-09 pb-6 px-4">
                <p>{{ answer }}</p>
            </div>
        </div>
        <div *ngIf="!isLastItem" class="px-4">
            <div class="border-b border-b-tone-05"></div>
        </div>
    </div>
</div>