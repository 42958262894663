<div class="w-full bg-actionbar-light" *transloco="let t; prefix: 'faq'">
  <div class="container flex py-7">
    <h2 class="h2">{{t('headers.primary')}}</h2>
  </div>
</div>
<!-- {{ this.model.properties!['faqGroup'].items }} -->
<section class="rfs:pb-24" *transloco="let t; prefix: 'faq'">
  <div class="container md:flex pt-10 gap-[84px]">
    <div class="flex-1 max-w-[332px] hidden lg:block">
      <app-indication-side-panel [inputData]="sidePanelData"></app-indication-side-panel>
    </div>
    <div class="flex-1">
      <div class="flex flex-col rfs:gap-[72px]">
        <div class="flex flex-col gap-6">
          <ng-container *ngFor="let faqGroup of this.faqGroup">
            <h2 [attr.scroll-anchor]="faqGroup.content.properties.groupTitle" class="h2 text-secondary">
              {{ faqGroup.content.properties.groupTitle }}
            </h2>
            <div class="">
              <ng-container *ngFor="
                  let faqItem of faqGroup.content.properties['faqItem'].items;
                  index as index
                ">
                <app-faq-item [question]="faqItem.content.properties.question"
                  [answer]="faqItem.content.properties.answer" [isLastItem]="
                    index + 1 ===
                    faqGroup.content.properties['faqItem'].items.length
                  "></app-faq-item>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <h3 class="h3 text-secondary">{{t('headers.secondary')}}</h3>
        <ng-container *ngIf="!isLoggedIn">
          <div class="bg-white rounded-small p-4">
            <div class="flex flex-col md:flex-row justify-between items-center">
              <div class="flex gap-4">
                <img class="rounded-full w-[50px] h-[50px]" src="../../assets/img/static/checker.png" />
                <div class="flex flex-col justify-center">
                  <p class="uppercase text-[0.625rem] text-tone-07">
                    {{t('paragraph')}}
                  </p>
                  <h4 class="font-semibold text-xl text-tone-09">
                    Dimitra Papadopoulou
                  </h4>
                </div>
              </div>
              <div>
                <button class="btn-secondary flex items-center">
                  <i class="icon-clock text-xl"></i> {{t('button')}}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isLoggedIn">
          <div>
            <div class="pt-6">
              <div class="bg-white rounded-small p-6">
                <div class="flex flex-col gap-4 items-center justify-center">
                  <div class="h-[78px]">
                    <img class="w-full h-full object-cover object-center" src="../../assets/img/static/faq-advisor.png"
                      alt="avatars of advisors" />
                  </div>
                  <h3 class="h3">
                    {{t('headers.third')}}
                  </h3>
                  <div class="max-w-[360px] w-full">
                    <button class="btn-secondary flex items-center justify-center w-full">
                      <i class="icon-clock text-xl"></i> {{t('button')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>