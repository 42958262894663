import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { InsertOrderItemRequest, InsertOrderRequest, OrderResponse, ServicesPaymentsApiService } from 'src/app/core/services/payments-api-service';

@Component({
  selector: 'app-document-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './document-card.component.html',
  styleUrl: './document-card.component.css'
})
export class DocumentCardComponent {
  @Input() hyperIcon: string = "";
  @Input() hyperTitle: string = "";
  @Input() title: string = "";
  @Input() buttonText: string = "";

  @Input() buttonIcon: string = "";
  @Input() product: Product | undefined;
  constructor(private _paymentsApiService: ServicesPaymentsApiService, private _router: Router) { }

  protected proceedToPay(): void {
    if (this.product) {
      let orderItems: InsertOrderItemRequest[] = [];
      orderItems.push(new InsertOrderItemRequest({
        sku: this.product.sku,
        quantity: this.product.quantity,
        propertyId: this.product.propertyId,
      }));

      let body = new InsertOrderRequest({
        currency: 'EUR',
        orderItems: orderItems,
      });
      this._paymentsApiService.createMyOrder(body).pipe(
        tap((response: OrderResponse) => {
          if (response && response.number) {
            this._router.navigate([`/checkout/${response.number}`]);
          }
        })

      ).subscribe();
    }

  }
}

export class Product {
  sku?: string;
  quantity: number = 1;
  propertyId?: string;
}
