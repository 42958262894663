<div class="max-w-[1008px] w-full">
  <ul class="flex gap-1 py-4">
    <li *ngFor="let step of Steps; let i = index"
      class="flex flex-col sm:flex-row pb-4 sm:gap-2 border-b-4 border-white w-full justify-center items-center"
      [ngClass]="{'step_buyer_active': StepNo > i+1, 'step_buyer_next': StepNo === i+1, 'step_buyer_default': StepNo < i+1}">
      <p class="text-[10px] font-semibold text-tone-07">{{step}}</p>
      <div class="icon-wrapper h-3 w-3 bg-secondary rounded-full relative"><i
          class="absolute top-[2px] left-[2px] icon-check text-[8px] text-white"></i></div>
    </li>
  </ul>
</div>