<div (clickOutside)="closeDropdown()">
    <div class="flex flex-col gap-1 min-w-[115px]">
        <button (click)="toggleDropdown()" class="btn-text flex items-center justify-between"> {{selectedLabel}} <i
                class="ml-3 text-xl text-black"
                [ngClass]="showResults? 'icon-chevron-up':'icon-chevron-down'"></i></button>
    </div>

    <div *ngIf="showResults"
        class="absolute top-[100%] translate-y-4 left-0 rounded-xl px-4 py-4 shadow-2xl bg-gray-50 z-50 max-h-[300px] overflow-y-auto overflow-x-hidden w-[270px]">

        <label *ngFor="let item of dropdownItems; let i = index" role="button" [attr.for]="'menu-item-radio-'+i"
            class="menu-item-checkbox small pb-2">
            <input type="checkbox" class="hidden" [attr.id]="'menu-item-radio-'+i" name="menu-item-radio-group1"
                (change)="ItemSelected(item)" [checked]="item.checked===true">
            <div class="menu-item-radio-content border-b border-gray-500">
                <div>
                    <!-- <i class="group-disabled/menu-item:text-gray-50 mr-4" [ngClass]="item.icon"></i> -->
                    <span class="menu-item-radio-text">{{item.label}}</span>
                </div>
            </div>
        </label>
    </div>
</div>
