<div class="flex flex-col flex-1" *transloco="let t; prefix: 'onboarding.onboarding-buyer'">
  <ng-container [ngSwitch]="Step">
    <ng-container *ngSwitchCase="'Step1'">
      <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
        <!-- STEP 1 -->
        <progress-stepper class="progress-stepper-onboarding" [StepNo]=1 [Steps]=Steps></progress-stepper>
        <div class="flex flex-col gap-12 items-center justify-center">
          <div class="flex flex-col gap-4">
            <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepOne')}}</h2>
            <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepOne')}}
            </p>
          </div>
          <app-property-type-buttons (SelectedItems)="buttonSelected($event)" [IsMultiple]="true"
            [ToggledButtons]="PropertyTypes"></app-property-type-buttons>
        </div>
      </div>

      <app-navigation-buttons [HidePrevious]="true" (ExitButtonClicked)="openModalComponent()"
        (NextButtonClicked)="SaveStep1()" (SkipButtonClicked)="Goto('Step2')"></app-navigation-buttons>
    </ng-container>

    <!-- STEP 2 -->
    <ng-container *ngSwitchCase="'Step2'">
      <div class="flex flex-1 flex-col h-full">
        <div class="container flex-1 flex flex-col justify-between rfs:gap-28 items-center">
          <progress-stepper [StepNo]=2 [Steps]=Steps></progress-stepper>
          <div class="flex flex-col gap-12 items-center justify-center">
            <div class="flex flex-col gap-4">
              <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepTwo')}}</h2>
              <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepTwo')}}
              </p>
            </div>
            <form class="flex flex-col gap-4 justify-center">
              <div class="flex flex-row items-center gap-3">
                {{t('labels.from')}}
                <fieldset class="rounded-full max-w-[144px] px-4 py-3 border border-tone-05 bg-tone-00">
                  <input type="number" max="PriceMax" class="bg-transparent max-w-[112px] outline-none no-spinner"
                    placeholder="€10 000" #buyerMinPrice (keyup)="SetMinPrice(buyerMinPrice.value)" [value]="PriceMin">
                </fieldset>
                {{t('labels.to')}}
                <fieldset class="rounded-full max-w-[144px] px-4 py-3 border border-tone-05 bg-tone-00">
                  <input type="number" min="PriceMin" class="bg-transparent max-w-[112px] outline-none no-spinner"
                    placeholder="€100 000" #buyerMaxPrice (keyup)="SetMaxPrice(buyerMaxPrice.value)" [value]="PriceMax">
                </fieldset>
              </div>
            </form>
          </div>
          <div></div>
        </div>
        <app-navigation-buttons (ExitButtonClicked)="openModalComponent()" (NextButtonClicked)="SaveStep2()"
          (PreviousButtonClicked)="Goto('Step1')" (SkipButtonClicked)="Goto('Step3')"></app-navigation-buttons>
      </div>
    </ng-container>

    <!-- STEP 3 -->
    <ng-container *ngSwitchCase="'Step3'">
      <div class="container flex flex-1 flex-col justify-between rfs:gap-28 items-center">
        <progress-stepper [StepNo]=3 [Steps]=Steps></progress-stepper>
        <div class="flex flex-col gap-12 items-center justify-center max-w-[800px] w-full">
          <div class="flex flex-col gap-4">
            <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepThree')}}</h2>
            <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepThree')}}
            </p>
          </div>
          <div class="flex justify-center w-full relative">
            <fieldset
              class="flex flex-col gap-2 group/input w-full bg-tone-00 py-3 border-tone-05 border rounded-[24px] px-4 hover:bg-tone-01 focus-within:border-[1.5px] focus-within:border-tone-07 transition-colors duration-300">
              <div class="flex justify-between text-tone-09 rounded-full outline-none">
                <p>
                  <i class="icon-search mr-[1.125rem] text-tone-07 text-xl"></i>
                  <app-google-maps-autocomplete (setAddress)="PlaceSelected($event)"
                    [disableSearch]="disableSearchSubject.asObservable()"
                    [clearSearch]="clearSearchSubject.asObservable()">
                  </app-google-maps-autocomplete>
                </p>
                <button class="flex justify-center items-center">
                  <i class="icon-arrow-right text-secondary text-xl" (click)="AddPlace()"></i>
                </button>
              </div>
            </fieldset>
          </div>
          <div class="flex justify-center items-start">
            <ul>
              <li *ngFor="let place of SelectedPlaces"
                class="text-black bg-white p-4 flex justify-between items-center mx-auto max-w-xl shadow-sm m-1 ">
                {{place.FormatedAddress}}
                <svg viewBox="0 0 24 24" class="h-6 w-6 p-1 fill-current text-black-500 hover:cursor-pointer"
                  (click)="DeletePlace(place)">
                  <path
                    d="M17.657 6.343a.999.999 0 0 0-1.414 0L12 10.586 7.757 6.343a.999.999 0 1 0-1.414 1.414L10.586 12l-4.243 4.243a.999.999 0 1 0 1.414 1.414L12 13.414l4.243 4.243a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L13.414 12l4.243-4.243a.999.999 0 0 0 0-1.414z" />
                </svg>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <app-navigation-buttons (ExitButtonClicked)="openModalComponent()" (NextButtonClicked)="SaveStep3()"
        (PreviousButtonClicked)="Goto('Step2')" (SkipButtonClicked)="Goto('Step4')"></app-navigation-buttons>
    </ng-container>

    <!-- STEP 4 -->
    <ng-container *ngSwitchCase="'Step4'">
      <div class="flex flex-1 flex-col h-full">
        <div class="container flex-1 flex flex-col justify-between rfs:gap-28 items-center">
          <div class="max-w-[1008px] w-full">
          </div>
          <div class="flex flex-col gap-12 items-center justify-center max-w-[800px]">
            <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepFour')}} {{SearchText}}</h2>
            <div class="flex justify-center">
              <div class="flex flex-col gap-4 items-center justify-center">
                <div class="w-[221px] h-[211px]">
                  <img class="object-center object-contain" src="assets/on-boarding/bg-4.webp"
                    alt="property with society karikatur">
                </div>
                <p class="text-chip-dark" [innerHTML]="t('infoText.stepFour')"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-1 items-end pt-8">
          <div class="w-full border-t border-actionbar-light">
            <div
              class="bg-primary-very-light py-6 px-3 lg:px-[72px] flex flex-col md:flex-row items-center justify-between gap-2">
              <div>
                <button hidden class="btn-text" (click)="openModalComponent()">{{t('buttons.exit')}}</button>
              </div>
              <div class="flex flex-col md:flex-row gap-4 justify-between">
                <button class="hidden btn-secondary min-w-[288px] flex items-center justify-center"><i
                    class="icon-arrow-left text-[20px]"></i>{{t('buttons.previous')}}</button>
                <button (click)="SaveAnswers()" class="btn-primary min-w-[288px] flex items-center justify-center">
                  {{PlacesText}}<i class="icon-arrow-right text-[20px]"></i></button>
              </div>
              <div class="flex">
                <button class="hidden btn-text flex items-center justify-center gap-2">{{t('buttons.skipStep')}}<i class="icon-arrow-right text-[20px]"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

</div>
