import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

export interface INbgBranchesApiService {
    findBranches(coords: { Latitude?: number, Longitude?: number }): Observable<IBranchResponse>;
}

@Injectable({
    providedIn: 'root'
})
export class NbgBranchesApiService implements INbgBranchesApiService {
    private url = 'https://www.nbg.gr/nbgapi/branches/findbranches';

    constructor(private _http: HttpClient) { }

    findBranches(coords: { Latitude?: number, Longitude?: number }): Observable<IBranchResponse> {
        const body = { Coords: coords };
        return this._http.post<any>(this.url, body).pipe(
            map(response => new BranchResponse(response))
        );
    }
}

export interface IBranch {
    wWorkingHours: string;
    itemID: string | null;
    sitecoreName: string | null;
    path: string | null;
    name: string | null;
    address: string | null;
    addressNumber: string | null;
    branchId: string | null;
    code: string | null;
    areaId: string | null;
    area: string | null;
    cityId: string | null;
    city: string | null;
    regionId: string | null;
    region: string | null;
    postalCode: string | null;
    location: string | null;
    isBranch: boolean | null;
    isBranchWithATM: boolean | null;
    hasIBankPass: boolean | null;
    hasIBankPassPlus: boolean | null;
    hasAps: boolean | null;
    hasCashPayments: boolean | null;
    hasAtmCashDeposit: boolean | null;
    areaItem: string | null;
    areaTitle: string | null;
    link: string | null;
    friendlyUrl: string | null;
    featureTitles: string | null;
    features: string | null;
    site: string | null;
    phoneOne: string | null;
    phoneTwo: string | null;
    latitude: string | null;
    longitude: string | null;
    zoom: string | null;
}

export interface IBranchResponse {
    breadcrumbData: any;
    total: number;
    branchMapList: IBranch[];
}

export class BranchResponse implements IBranchResponse {
    breadcrumbData: any;
    total: number;
    branchMapList: IBranch[];

    constructor(data: any) {
        this.breadcrumbData = data.BreadcrumbData;
        this.total = data.Total;
        this.branchMapList = data.BranchMapList.map((item: any) => ({
            workingHours: item.WorkingHours,
            itemID: item.ItemID,
            sitecoreName: item.SitecoreName,
            path: item.Path,
            name: item.Name,
            address: item.Address,
            addressNumber: item.AddressNumber,
            branchId: item.BranchId,
            code: item.Code,
            areaId: item.AreaId,
            area: item.Area,
            cityId: item.CityId,
            city: item.City,
            regionId: item.RegionId,
            region: item.Region,
            postalCode: item.PostalCode,
            location: item.Location,
            isBranch: item.IsBranch,
            isBranchWithATM: item.IsBranchWithATM,
            hasIBankPass: item.HasIBankPass,
            hasIBankPassPlus: item.HasIBankPassPlus,
            hasAps: item.HasAps,
            hasCashPayments: item.HasCashPayments,
            hasAtmCashDeposit: item.HasAtmCashDeposit,
            areaItem: item.AreaItem,
            areaTitle: item.AreaTitle,
            link: item.Link,
            friendlyUrl: item.FriendlyUrl,
            featureTitles: item.FeatureTitles,
            features: item.Features,
            site: item.Site,
            phoneOne: item.PhoneOne,
            phoneTwo: item.PhoneTwo,
            latitude: item.Latitude,
            longitude: item.Longitude,
            zoom: item.Zoom,
        }));
    }
}