import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaceViewModel, PropertyListingService } from 'src/app/services/property.service';
import { DropdownMenuComponent } from "../../../shared/components/dropdown-menu/dropdown-menu.component";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PropertyCardItemComponent, } from 'src/app/shared/components/property-card-item/property-card-item.component';
import { PropertyListFilter, PropertySummary, PropertySummaryResultSet } from 'src/app/services/portal-api.service';
import { mergeMap, scan, switchMap, tap } from 'rxjs/operators';
import { MapComponent } from "../../../shared/components/map/map.component";
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Modal, ModalService } from '@indice/ng-components';
import { SaveSearchModalComponent } from '../../save-search-modal/save-search-modal.component';
import { Toast, ToastType, ToasterService } from 'src/app/services/toaster.service';
import { ToasterContainerComponent } from 'src/app/shared/components/toast/toast-container.component';
import { GeocodeService } from 'src/app/core/services/geocode.service';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { SortDropdownComponent } from "../../../shared/components/sort-dropdown/sort-dropdown.component";
import { FilterDropdownItem } from '../filter-dropdown/filter-dropdown.component';
import { SaveSearchAnonymousModalComponent } from '../../save-search-modal/save-search-anonymous-modal/save-search-anonymous-modal.component';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-grid-list-cards',
  standalone: true,
  imports: [
    CommonModule,
    PropertyCardItemComponent,
    DropdownMenuComponent,
    MapComponent,
    RouterModule,
    ToasterContainerComponent,
    TranslocoDirective,
    SortDropdownComponent
  ],
  templateUrl: './grid-list-cards.component.html',
  styleUrl: './grid-list-cards.component.css'
})
export class GridListCardsComponent {

  private searchFilter: PropertyListFilter | undefined
  private page: number = 1;
  private readonly size: number = 8;
  private readonly acceptLanguage = "";
  protected displayMap: boolean = false;
  protected loading: boolean = false;
  private modelCount: number = 0;
  private searchTerm: string | undefined;
  protected pageByLoadMoreSubject = new BehaviorSubject(1);
  protected googleMapItems = new BehaviorSubject<PropertySummary[]>([]);
  showSaveSearch: boolean = false;
  toast$: Observable<Toast> = new Observable();
  public criteriaAdjusted = false;

  orderByItems: FilterDropdownItem[] = [
    {
      key: 'newestFirst',
      label: 'Newest first'
    },
    {
      key: 'oldestFirst',
      label: 'Oldest first'
    },
    {
      key: 'underOffer',
      label: 'Under offer'
    },
    {
      key: 'AuctionStartsSoon',
      label: 'Auction starts soon'
    }
  ];

  private mergePropertyResults = (all: PropertySummaryResultSet, current: PropertySummaryResultSet) => {
    if (this.page == 1) {
      all.items = current.items;
    }
    else {
      all.items!.push(...current.items!);
    }
    all.count = current.count;
    this.modelCount = current.count || 0;
    this.googleMapItems.next(all.items!);
    return all;
  }
  mapSearch: boolean = false;

  model$: Observable<PropertySummaryResultSet> = this.pageByLoadMoreSubject.pipe(
    tap(t => this.loading = true),
    mergeMap((page: number) => this.propertyListingService.getProperties(this.acceptLanguage, page, this.size, undefined, this.searchTerm, this.searchFilter), 1),
    switchMap((res) => {
      this.criteriaAdjusted = res.criteriaAdjusted;
      return of(res.properties);
    }),
    scan(this.mergePropertyResults, new PropertySummaryResultSet({ count: 0, items: [] })), //scan is same as reduce, but also executes
    tap(t => this.loading = false),
  );
  isLoggedIn: boolean = false;

  constructor(private propertyListingService: PropertyListingService,
    private readonly activatedRoute: ActivatedRoute,
    private _modalService: ModalService,
    @Inject(ToasterService) private _toastService: ToasterService,
    private geocodeService: GeocodeService,
    private router: Router,
    private transloco: TranslocoService,
    private authService: AuthService) {
    this.transloco.selectTranslateObject('find-property.grid-list').pipe(
      tap(t => {
        this.applyTranslations(t);
      })
    ).subscribe();
  }
  ngOnInit() {
    this.authService.isLoggedIn().subscribe(x => this.isLoggedIn = x)
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.showSaveSearch = true;
      }
      else {
        this.showSaveSearch = false;
      }
      this.searchFilter = this.propertyListingService.getSearchFiltersFromUrl(params);
      this.page = 1;
      this.pageByLoadMoreSubject.next(this.page);
      this.mapSearch = params.get("mapSearch") == "true";
    });
  }
  protected loadMoreGridItems() {
    if (this.loading) return;
    if (this.page * this.size > this.modelCount) return;
    this.page++;
    this.pageByLoadMoreSubject.next(this.page);
  }

  private applyTranslations(t: any) {
    this.orderByItems.forEach((item, index) => {
      if (t && t[item.key]) {
        item.label = t[item.key]
      }
    });
  }
  protected toggleListMap(event?: MouseEvent) {
    let element = event?.target as HTMLElement;
    let isActive = element.classList.contains("active");
    if (!isActive) {
      this.displayMap = !this.displayMap
    }
  }
  public propertyClickedInMap(id: string) {
    console.log(document.getElementById(id));
  }

  public SaveSearch() {
    let saveSearchModal: Modal<SaveSearchModalComponent> = new Modal<SaveSearchModalComponent>;
    saveSearchModal.onHidden?.subscribe((r: any) => {
      if (r?.result?.success == true) {
        this._toastService.show(ToastType.Success, 'Search saved!', 'You can find it in your "saved searches" or in the search bar!', 3000);
      }
    });

    if (!this.isLoggedIn) {
      this._modalService.show(SaveSearchAnonymousModalComponent, {
        keyboard: true,
      })
    }

    else {
      saveSearchModal = this._modalService.show(SaveSearchModalComponent, {
        keyboard: true,

        initialState: {
          SearchFilters: this.searchFilter
        }
      })

    }
  }
  toggleMapSearch() {
    if (this.mapSearch) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { mapSearch: undefined, area: undefined },
          queryParamsHandling: 'merge'
        }
      );
    }
    if (!this.mapSearch) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { mapSearch: true },
          queryParamsHandling: 'merge'
        }
      );
    }
  }
  orderByChanged(sortByKey: string | undefined) {
    let sortString;
    switch (sortByKey) {
      case 'newestFirst':
        sortString = 'CreatedOn-'
        break;
      case 'oldestFirst':
        sortString = 'CreatedOn+'
        break;
      case 'underOffer':
        sortString = 'offer-'
        break;
      case 'AuctionStartsSoon':
        sortString = 'auctionDate-'
        break;
      default: break;
    }

    if (sortByKey) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { sort: sortString },
          queryParamsHandling: 'merge'
        }
      );
    }
    else {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { sort: undefined },
          queryParamsHandling: 'merge'
        }
      );
    }
  }
  public mapViewChanged(bounds: google.maps.LatLngBounds) {

    if (this.mapSearch) {
      let place: PlaceViewModel = {
        Bounds: {
          SwLon: bounds.getSouthWest().lng() ?? undefined,
          NeLat: bounds.getNorthEast().lat() ?? undefined,
          SwLat: bounds.getSouthWest().lat() ?? undefined,
          NeLon: bounds.getNorthEast().lng() ?? undefined
        }
      }
      let encodedMessage = this.geocodeService.protoEncodeGeoSearch(place);
      let urlencodedMessage = btoa(String.fromCharCode(...encodedMessage));
      let filter: PropertyListFilter = new PropertyListFilter({
        area: urlencodedMessage
      })
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { ...filter },
          queryParamsHandling: 'merge'
        }
      );
    }
  }

}

