<section class="py-8 container mx-auto" *transloco="let t; prefix: 'find-property.grid-list-cards'">
    <ng-container *ngIf="model$ | async; let model">
        @if (criteriaAdjusted && model.count !== 0) {
        <div class="border-t pt-4 flex flex-row gap-x-2 items-center min-h-[60px]">
            <i class="icon-info-circle text-gray-700 text-lg"></i>
            <span class="text-gray-700 text-sm font-normal">{{t('searchCriteriaAdjusted')}}</span>
        </div>
        }
        <div class="flex md:flex-row flex-col gap-2 justify-between">
            <h2 class=" text-[2rem] font-medium text-center">{{ model.count }} {{t('properties')}}</h2>
            <div class="flex sm:flex-row flex-col md:justify-start justify-between gap-6 items-center">
                <button *ngIf="showSaveSearch" (click)="SaveSearch()"
                    class="btn-text flex justify-center items-center gap-2 items-center justify-center"><i
                        class="icon-heart-line text-[20px]"></i> {{t('buttons.saveSearch')}} </button>
                <app-sort-dropdown selectedKey="newestFirst" [dropdownItems]="orderByItems"
                    (selectedItem)="orderByChanged($event)"></app-sort-dropdown>
                <div class="flex rounded-full bg-tone-01 items-center justify-center p-1 border border-gray-300">
                    <span class="chip small border-none" [ngClass]="{'active' : !displayMap }"
                        (click)="toggleListMap($event)">
                        <i class="icon-list mr-2"></i>
                        {{t('chips.list')}}
                    </span>
                    <span class="chip small border-none" [ngClass]="{'active' : displayMap }"
                        (click)="toggleListMap($event)">
                        <i class="icon-location mr-2"></i>
                        {{t('chips.map')}}
                    </span>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 gap-8 mt-8"
            [ngClass]="{'lg:grid-cols-2 lg:h-[1030px] lg:overflow-y-hidden': displayMap}">
            <div class="flex flex-col gap-6" [ngClass]="{'lg:overflow-y-auto': displayMap}">
                <div class="grid grid-cols-1 gap-x-8 gap-y-6 " [ngClass]="{ 'lg:grid-cols-2 ' : !displayMap }">
                    <app-property-card-item *ngFor="let modelItem of model.items" id="{{modelItem.id}}"
                        [propertySummary]="modelItem" [routerLink]="['/properties/'+ modelItem.id]"
                        class="hover: cursor-pointer"></app-property-card-item>
                </div>
                <div *ngIf="model.count != model.items?.length" class="flex justify-center">
                    <button class="btn-secondary bg-transparent"
                        (click)="loadMoreGridItems()">{{t('buttons.loadMore')}}</button>
                </div>
            </div>
            <div class="aspect-video lg:h-full w-full order-first lg:order-last relative" *ngIf="displayMap">
                <app-map [propertySummaries$]="googleMapItems.asObservable()"
                    (propertyClicked)="propertyClickedInMap($event)" (scrollChanged)="mapViewChanged($event)"></app-map>
                <div
                    class="hover:cursor-pointer absolute top-0 left-0 translate-y-4 translate-x-4 transition-all hover:border-primary-900 drop-shadow-2xl  bg-white border border-gray-300 rounded-full py-2 px-4">
                    <label for="checkbox-selector-14" class="checkbox-selector secondary">
                        <input type="checkbox" class="hidden" id="checkbox-selector-14" name="checkbox-selector-"
                            [checked]="mapSearch" (click)="toggleMapSearch()">
                        <div class="checkbox-selector-content">
                            {{t('mapSearchInfo')}}
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <ng-container>
            <lib-toaster-container></lib-toaster-container>
        </ng-container>
    </ng-container>
</section>