import { Component } from '@angular/core';
import { DocumentCardComponent, Product } from './document-card/document-card.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { tap } from 'rxjs';
import { TranslocoDirective } from '@jsverse/transloco';
import { PaymentsApiService, UnikoServicesConfig, UnikoServiceTypes } from 'src/app/services/payments-api.service';

@Component({
  selector: 'app-documents-section',
  standalone: true,
  templateUrl: './documents-section.component.html',
  styleUrl: './documents-section.component.css',
  imports: [CommonModule, DocumentCardComponent, TranslocoDirective]
})
export class DocumentsSectionComponent {
  product1: Product | undefined;
  product2: Product | undefined;
  product3: Product | undefined;
  propertyId: string | any;
  constructor(
    private activatedRoute: ActivatedRoute
  ) {

    this.activatedRoute.params.pipe(
      tap((params: Params) => {
        this.propertyId = params['propertyId'];

        this.product2 = {
          sku: UnikoServicesConfig.find(f => f.type == UnikoServiceTypes.legalDueDiligence)?.sku,
          propertyId: this.propertyId,
          quantity: 1,
        };

        this.product3 = {
          sku: UnikoServicesConfig.find(f => f.type == UnikoServiceTypes.TechnicalReport)?.sku,
          propertyId: this.propertyId,
          quantity: 1,
        };
      })
    ).subscribe()
  }

}