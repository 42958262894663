import { map, mergeMap } from 'rxjs/operators';
import { UserProfile } from 'oidc-client-ts';
import { AuthService } from './../../../core/services/auth.service';
import { Component, HostListener, Inject, InjectionToken, OnInit, Optional } from '@angular/core';
import { of } from 'rxjs';
import { ResolveEnd, Router, RouterModule } from '@angular/router';
import { Modal, ModalService } from '@indice/ng-components';
import { ProfileMenuComponent } from '../../../shared/components/profile-menu/profile-menu.component';
import { CommonModule } from '@angular/common';
import { IDENTITY_API_BASE_URL } from 'src/app/services/identity-api.service';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [RouterModule, CommonModule, TranslocoDirective],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  isLoggedIn = false;
  greeting: string | undefined;
  fullName: string | undefined;
  readonly authorityUrl: string;
  // Boolean to control dynamic header classes
  public headerFixed = false;
  alternav = false;
  alternavTitle = '';
  onboardingNav = false;
  isHomePage = false;
  public isPropertyDetails = false;
  public currentRoute: string = '';

  // Boolean to control menu open/close
  public showMenu: boolean = false;

  // Current year
  public copyrightYear = new Date().getFullYear();
  public isMobileMenuOpen = false;

  showUserMenu = false;

  constructor(
    private _authService: AuthService,
    private router: Router,
    private _modalService: ModalService,
    private _translate: TranslocoService,
    @Optional() @Inject(IDENTITY_API_BASE_URL) authUrl?: string
  ) {
    this.authorityUrl = authUrl !== undefined && authUrl !== null ? authUrl : "";
  }

  ngOnInit(): void {
    this.getUser();

    const userLoaded$ = this._authService.userLoaded;
    userLoaded$
      .pipe(
        mergeMap((user) => {
          if (user) {
            this.isLoggedIn = true;
            this.fullName = `${user.profile?.given_name} ${user.profile?.family_name}`;
            return this._authService.getUserProfile();
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe((response: UserProfile | undefined) => {
        if (response) {
          this.greeting = response.name || response.email;
        }
      });

    // Get current route
    this.router.events.subscribe((routerData) => {
      if (routerData instanceof ResolveEnd) {
        this.currentRoute = routerData.url;

        // Check if you are on the home page
        if (this.currentRoute.includes("home") || this.currentRoute === "/") {
          this.isHomePage = true;
        }
        else {
          this.isHomePage = false;
        }

        // Check if you are on the onboarding page
        if (this.currentRoute.includes("welcome") || this.currentRoute.includes("on-boarding")) {
          this.alternav = true;
          this.alternavTitle = "Tell us about your needs"
        }
        else if (routerData.url.includes("property-upload-wizard")) {
          this.alternav = true;
          this.alternavTitle = "Create a listing"
        }
        else {
          this.alternav = false
        }

        // Check if you are on the property page with id
        if (this.currentRoute.includes("properties/")) {
          this.isPropertyDetails = true;
        }
        else {
          this.isPropertyDetails = false
        }

        this.showUserMenu = false;
      }
    });
  }

  handleMobileMenu(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    if (this.isMobileMenuOpen) {
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.paddingRight = '0.35rem';
    } else {
      document.documentElement.style.removeProperty('overflow');
      document.documentElement.style.removeProperty('padding-right');
    }
  }

  login(): void {
    this._authService.signinRedirect(this.currentRoute);
  }

  logout(): void {
    this._authService.signoutRedirect();
  }

  public translate() {
    let langToSet = this._translate.getActiveLang() === 'en' ? 'el' : 'en';
    this._translate.setActiveLang(langToSet);
  }

  /**
   * @description: Function to add dynamic classes to header
   * @param: none
   * @returns: none
   * @memberof NavigationComponent
   */
  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.pageYOffset > 0) {
      this.headerFixed = true;
    } else {
      this.headerFixed = false;
    }
  }

  /**
   * @description: Function to open/close menu and handle body scroll
   * @param: none
   * @returns: none
   * @memberof NavigationComponent
   */
  handleOpenMenu(): void {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '0.35rem';
    } else {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');
    }
  }
  private getUser() {
    this._authService
      .isLoggedIn()
      .pipe(
        map((res) => {
          this.isLoggedIn = res;

          if (this.isLoggedIn) {
            this._authService
              .getUserProfile()
              .pipe(
                map((profile) => {
                  this.greeting = profile?.name || profile?.email;
                  this.fullName = `${profile?.given_name} ${profile?.family_name}`;
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();
  }
  public goToFavorites(): void {
    this.router.navigateByUrl('/properties?favorites=1');
  }

  profileModal: Modal<ProfileMenuComponent> | undefined;
  public showUserProfile() {
    if (this.profileModal) {
      this.profileModal.hide();
    }
    this.profileModal = this._modalService.show(ProfileMenuComponent, {
      keyboard: true,
      class: 'my-profile-modal',
    });
    this.profileModal
      .onHidden!.pipe(
        map((res: any) => {
          console.log('Result', res);
          if (res.result) {
          }
        })
      )
      .subscribe();
  }
}
