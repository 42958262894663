import { TranslocoDirective } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AuctionRoundFilter, BedroomFilter, FloorFilter, HeatingType, PropertyListFilter } from 'src/app/services/portal-api.service';
import { PropertyListingService } from 'src/app/services/property.service';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';

@Component({
  selector: 'app-advanced-filters-drawer',
  standalone: true,
  imports: [CommonModule, DatePickerComponent, TranslocoDirective],
  templateUrl: './advanced-filters-drawer.component.html',
  styleUrl: './advanced-filters-drawer.component.css'
})
export class AdvancedFiltersDrawerComponent {
  @Input() Toggle$: Subject<void> = new Subject()
  @Output() FilterChanged: EventEmitter<PropertyListFilter> = new EventEmitter<PropertyListFilter>()
  @Output() OnHidden: EventEmitter<boolean> = new EventEmitter<boolean>()

  isOpen = false;
  filter: PropertyListFilter = new PropertyListFilter()
  minConstructionYear: Date | undefined
  maxConstructionYear: Date | undefined
  floors: toggleButton<FloorFilter>[] = [
    {
      key: FloorFilter.First,
      label: "1",
      toggled: false,
    },
    {
      key: FloorFilter.Second,
      label: "2",
      toggled: false,
    },
    {
      key: FloorFilter.Third,
      label: "3",
      toggled: false,
    },
    {
      key: FloorFilter.FourthPlus,
      label: "4+",
      toggled: false,
    },
    {
      key: FloorFilter.TopFloor,
      label: "Top floor",
      toggled: false,
    }
  ]
  bedrooms: toggleButton<BedroomFilter>[] = [
    {
      key: BedroomFilter.One,
      label: "1",
      toggled: false,
    },
    {
      key: BedroomFilter.Two,
      label: "2",
      toggled: false,
    },
    {
      key: BedroomFilter.Three,
      label: "3",
      toggled: false,
    },
    {
      key: BedroomFilter.Four,
      label: "4",
      toggled: false,
    },
    {
      key: BedroomFilter.FivePlus,
      label: "5+",
      toggled: false,
    }
  ]
  auctionRound: toggleButton<AuctionRoundFilter>[] = [
    {
      key: AuctionRoundFilter.First,
      label: "1st",
      toggled: false,
    },
    {
      key: AuctionRoundFilter.Second,
      label: "2nd",
      toggled: false,
    },
    {
      key: AuctionRoundFilter.Third,
      label: "3rd",
      toggled: false,
    },
    {
      key: AuctionRoundFilter.Fourth,
      label: "4th",
      toggled: false,
    }
  ]
  characteristics: toggleButton<string>[] = [
    { key: "hasPool", label: "Has Pool", toggled: false },
    { key: "hasGarden", label: "Has Garden", toggled: false },
    { key: "isPenthouse", label: "Is Penthouse", toggled: false },
    { key: "suitableForTouristUse", label: "Suitable for Tourist Use", toggled: false },
    { key: "suitableForProfessionalUse", label: "Suitable for Professional Use", toggled: false },
    { key: "hasAlarm", label: "Has Alarm", toggled: false },
    { key: "isBright", label: "Is Bright", toggled: false },
    { key: "hasNightElectricCurrent", label: "Has Night Electric Current", toggled: false },
    { key: "hasElevator", label: "Has Elevator", toggled: false },
    { key: "isAiry", label: "Is Airy", toggled: false },
    { key: "sieves", label: "Sieves", toggled: false },
    { key: "hasSolarWaterHeating", label: "Has Solar Water Heating", toggled: false },
    { key: "hasBoiler", label: "Has Boiler", toggled: false },
    { key: "accessibilityForDisabled", label: "Accessibility for Disabled", toggled: false },
    { key: "hasPlayroom", label: "Has Playroom", toggled: false },
    { key: "petsAreAllowed", label: "Pets are Allowed", toggled: false },
    { key: "hasTents", label: "Has Tents", toggled: false },
    { key: "isFloorApartment", label: "Is Floor Apartment", toggled: false },
    { key: "internalStaircase", label: "Internal Staircase", toggled: false }
  ]
  heatingtype: toggleButton<HeatingType>[] = [
    {
      key: HeatingType.ElectricAirConditioning,
      label: "Electric air conditioning",
      toggled: false,
    },
    {
      key: HeatingType.ElectricHeatPump,
      label: "Electric heat pump",
      toggled: false,
    },
    {
      key: HeatingType.ElectricHeatPumpWaterHeater,
      label: "Electric heat pump water heater",
      toggled: false,
    },
    {
      key: HeatingType.ElectricRadiators,
      label: "Electric radiators",
      toggled: false,
    },
    {
      key: HeatingType.EnergyEfficientFireplace,
      label: "Energy efficient fireplace",
      toggled: false,
    },
    {
      key: HeatingType.FireplaceSimple,
      label: "Fireplace simple",
      toggled: false,
    },
    {
      key: HeatingType.Geothermal,
      label: "Geothermal",
      toggled: false,
    },
    {
      key: HeatingType.HeatPump,
      label: "Heatpump",
      toggled: false,
    },
    {
      key: HeatingType.HeatingOil,
      label: "Heating oil",
      toggled: false,
    },
    {
      key: HeatingType.NaturalGas,
      label: "Natural gas",
      toggled: false,
    },
    {
      key: HeatingType.OtherHeating,
      label: "Other",
      toggled: false,
    }
  ]


  constructor(private activatedRoute: ActivatedRoute, private propertyListingService: PropertyListingService) { }
  ngOnInit() {
    this.Toggle$.subscribe(() => this.toggleDrawer());
    this.activatedRoute.queryParamMap.subscribe(params => {

      this.filter = this.propertyListingService.getSearchFiltersFromUrl(params);
      //Type filter
      if (this.filter.minConstructionYear) {
        this.minConstructionYear = new Date(this.filter.minConstructionYear, 1, 1);
      }
      if (this.filter.maxConstructionYear) {
        this.minConstructionYear = new Date(this.filter.maxConstructionYear, 1, 1);
      }
      this.handleFilters();
    })


  }

  toggleDrawer() {
    this.isOpen = !this.isOpen;
    this.OnHidden.emit(!this.isOpen);
  }

  floorsChange(input: FloorFilter) {
    if (this.filter.floor && this.filter.floor.length > 0) {
      let index = this.filter.floor.indexOf(input);
      if (index > -1) {
        this.filter.floor.splice(index, 1);
      }
      else {
        this.filter.floor.push(input)
      }
    }
    else {
      this.filter.floor = [input];
    }
    if (this.filter.floor.length == 0) {
      this.filter.floor = undefined;
    }
    this.filterChanged();
  }

  roomsChange(input: BedroomFilter) {
    if (this.filter.bedrooms && this.filter.bedrooms.length > 0) {
      let index = this.filter.bedrooms.indexOf(input);
      if (index > -1) {
        this.filter.bedrooms.splice(index, 1);
      }
      else {
        this.filter.bedrooms.push(input)
      }
    }
    else {
      this.filter.bedrooms = [input];
    }
    if (this.filter.bedrooms.length == 0) {
      this.filter.bedrooms = undefined;
    }
    this.filterChanged();
  }

  auctionRoundChange(input: AuctionRoundFilter) {
    if (this.filter.auctionRound && this.filter.auctionRound.length > 0) {
      let index = this.filter.auctionRound.indexOf(input);
      if (index > -1) {
        this.filter.auctionRound.splice(index, 1);
      }
      else {
        this.filter.auctionRound.push(input)
      }
    }
    else {
      this.filter.auctionRound = [input];
    }
    if (this.filter.auctionRound.length == 0) {
      this.filter.auctionRound = undefined;
    }
    this.filterChanged();
  }

  toggleCharacteristics(key: string) {
    switch (key) {
      case "hasPool": {
        if (this.filter.hasPool) {
          this.filter.hasPool = !this.filter.hasPool
        }
        else {
          this.filter.hasPool = true;
        }
        break;
      }
      case "hasGarden": {
        if (this.filter.hasGarden) {
          this.filter.hasGarden = !this.filter.hasGarden
        }
        else {
          this.filter.hasGarden = true;
        }
        break;
      }
      case "isPenthouse": {
        if (this.filter.isPenthouse) {
          this.filter.isPenthouse = !this.filter.isPenthouse
        }
        else {
          this.filter.isPenthouse = true;
        }
        break;
      }
      case "suitableForTouristUse": {
        if (this.filter.suitableForTouristUse) {
          this.filter.suitableForTouristUse = !this.filter.suitableForTouristUse
        }
        else {
          this.filter.suitableForTouristUse = true;
        }
        break;
      }
      case "suitableForProfessionalUse": {
        if (this.filter.suitableForProfessionalUse) {
          this.filter.suitableForProfessionalUse = !this.filter.suitableForProfessionalUse
        }
        else {
          this.filter.suitableForProfessionalUse = true;
        }
        break;
      }
      case "hasAlarm": {
        if (this.filter.hasAlarm) {
          this.filter.hasAlarm = !this.filter.hasAlarm
        }
        else {
          this.filter.hasAlarm = true;
        }
        break;
      }
      case "isBright": {
        if (this.filter.isBright) {
          this.filter.isBright = !this.filter.isBright
        }
        else {
          this.filter.isBright = true;
        }
        break;
      }
      case "hasNightElectricCurrent": {
        if (this.filter.hasNightElectricCurrent) {
          this.filter.hasNightElectricCurrent = !this.filter.hasNightElectricCurrent
        }
        else {
          this.filter.hasNightElectricCurrent = true;
        }
        break;
      }
      case "hasElevator": {
        if (this.filter.hasElevator) {
          this.filter.hasElevator = !this.filter.hasElevator
        }
        else {
          this.filter.hasElevator = true;
        }
        break;
      }

      case "isAiry": {
        if (this.filter.isAiry) {
          this.filter.isAiry = !this.filter.isAiry
        }
        else {
          this.filter.isAiry = true;
        }
        break;
      }
      case "sieves": {
        if (this.filter.sieves) {
          this.filter.sieves = !this.filter.sieves
        }
        else {
          this.filter.sieves = true;
        }
        break;
      }
      case "hasSolarWaterHeating": {
        if (this.filter.hasSolarWaterHeating) {
          this.filter.hasSolarWaterHeating = !this.filter.hasSolarWaterHeating
        }
        else {
          this.filter.hasSolarWaterHeating = true;
        }
        break;
      }
      case "hasBoiler": {
        if (this.filter.hasBoiler) {
          this.filter.hasBoiler = !this.filter.hasBoiler
        }
        else {
          this.filter.hasBoiler = true;
        }
        break;
      }
      case "accessibilityForDisabled": {
        if (this.filter.accessibilityForDisabled) {
          this.filter.accessibilityForDisabled = !this.filter.accessibilityForDisabled
        }
        else {
          this.filter.accessibilityForDisabled = true;
        }
        break;
      }
      case "hasPlayroom": {
        if (this.filter.hasPlayroom) {
          this.filter.hasPlayroom = !this.filter.hasPlayroom
        }
        else {
          this.filter.hasPlayroom = true;
        }
        break;
      }
      case "petsAreAllowed": {
        if (this.filter.petsAreAllowed) {
          this.filter.petsAreAllowed = !this.filter.petsAreAllowed
        }
        else {
          this.filter.petsAreAllowed = true;
        }
        break;
      }
      case "hasTents": {
        if (this.filter.hasTents) {
          this.filter.hasTents = !this.filter.hasTents
        }
        else {
          this.filter.hasTents = true;
        }
        break;
      }
      case "isFloorApartment": {
        if (this.filter.isFloorApartment) {
          this.filter.isFloorApartment = !this.filter.isFloorApartment
        }
        else {
          this.filter.isFloorApartment = true;
        }
        break;
      }
      case "isFloorApartment": {
        if (this.filter.isFloorApartment) {
          this.filter.isFloorApartment = !this.filter.isFloorApartment
        }
        else {
          this.filter.isFloorApartment = true;
        }
        break;
      }
      case "internalStaircase": {
        if (this.filter.internalStaircase) {
          this.filter.internalStaircase = !this.filter.internalStaircase
        }
        else {
          this.filter.internalStaircase = true;
        }
        break;
      }
    }
    this.filterChanged();
  }

  parkingSelected(input: boolean) {
    if (this.filter.hasParking == input) {
      this.filter.hasParking = undefined;
    }
    else {
      this.filter.hasParking = input;
    }
    this.filterChanged();
  }

  preaprovedChanged(input: boolean) {
    if (this.filter.preApproved == input) {
      this.filter.preApproved = undefined;
    }
    else {
      this.filter.preApproved = input;
    }
    this.filterChanged();
  }

  auctionChanged(input: boolean) {
    if (this.filter.inAuction == input) {
      this.filter.inAuction = undefined;
    }
    else {
      this.filter.inAuction = input;
    }
    this.filterChanged();
  }

  heatingTypeChanged(input: HeatingType) {
    if (this.filter.heatingType && this.filter.heatingType.length > 0) {
      let index = this.filter.heatingType.indexOf(input);
      if (index > -1) {
        this.filter.heatingType.splice(index, 1);
      }
      else {
        this.filter.heatingType.push(input)
      }
    }
    else {
      this.filter.heatingType = [input];
    }
    if (this.filter.heatingType.length == 0) {
      this.filter.heatingType = undefined;
    }
    this.filterChanged();
  }

  constructionYearFrom(input: Date) {
    this.filter.minConstructionYear = input.getFullYear();
    if (!this.filter.maxConstructionYear || this.filter.maxConstructionYear < this.filter.minConstructionYear) {
      this.filterChanged();
    }
  }

  constructionYearTo(input: Date) {
    this.filter.maxConstructionYear = input.getFullYear();
    if (!this.filter.minConstructionYear || this.filter.maxConstructionYear < this.filter.minConstructionYear) {
      this.filterChanged();
    }
  }
  clearMaxConstructionYear() {
    this.filter.maxConstructionYear = undefined;
    this.filterChanged();
  }
  clearMinConstructionYear() {
    this.filter.minConstructionYear = undefined;
    this.filterChanged();
  }

  private handleFloorsFilter() {
    this.floors.forEach(element => {
      element.toggled = this.filter.floor?.includes(element.key) ?? false
    });
  }
  private handleBedroomsFilter() {
    this.bedrooms.forEach(element => {
      element.toggled = this.filter.bedrooms?.includes(element.key) ?? false
    });
  }
  private handleAuctionRoundFilter() {
    this.auctionRound.forEach(element => {
      element.toggled = this.filter.auctionRound?.includes(element.key) ?? false
    });
  }
  private handleHeatingTypeFilter() {
    this.heatingtype.forEach(element => {
      element.toggled = this.filter.heatingType?.includes(element.key) ?? false
    });
  }
  private handleCharacteristic() {
    this.characteristics.forEach(element => {
      switch (element.key) {
        case "hasPool": {
          element.toggled = this.filter.hasPool ?? false;
          break;
        }
        case "hasGarden": {
          element.toggled = this.filter.hasGarden ?? false;
          break;
        }
        case "isPenthouse": {
          element.toggled = this.filter.isPenthouse ?? false;
          break;
        }
        case "suitableForTouristUse": {
          element.toggled = this.filter.suitableForTouristUse ?? false;
          break;
        }
        case "suitableForProfessionalUse": {
          element.toggled = this.filter.suitableForProfessionalUse ?? false;
          break;
        }
        case "hasAlarm": {
          element.toggled = this.filter.hasAlarm ?? false;
          break;
        }
        case "isBright": {
          element.toggled = this.filter.isBright ?? false;
          break;
        }
        case "hasNightElectricCurrent": {
          element.toggled = this.filter.hasNightElectricCurrent ?? false;
          break;
        }
        case "hasElevator": {
          element.toggled = this.filter.hasElevator ?? false;
          break;
        }

        case "isAiry": {
          element.toggled = this.filter.isAiry ?? false;
          break;
        }
        case "sieves": {
          element.toggled = this.filter.sieves ?? false;
          break;
        }
        case "hasSolarWaterHeating": {
          element.toggled = this.filter.hasSolarWaterHeating ?? false;
          break;
        }
        case "hasBoiler": {
          element.toggled = this.filter.hasBoiler ?? false;
          break;
        }
        case "accessibilityForDisabled": {
          element.toggled = this.filter.accessibilityForDisabled ?? false;
          break;
        }
        case "hasPlayroom": {
          element.toggled = this.filter.hasPlayroom ?? false;
          break;
        }
        case "petsAreAllowed": {
          element.toggled = this.filter.petsAreAllowed ?? false;
          break;
        }
        case "hasTents": {
          element.toggled = this.filter.hasTents ?? false;
          break;
        }
        case "isFloorApartment": {
          element.toggled = this.filter.isFloorApartment ?? false;
          break;
        }
        case "isFloorApartment": {
          element.toggled = this.filter.isFloorApartment ?? false;
          break;
        }
        case "internalStaircase": {
          element.toggled = this.filter.internalStaircase ?? false;
          break;
        }
      }
    });
  }

  private handleFilters() {
    this.handleFloorsFilter();
    this.handleBedroomsFilter();
    this.handleAuctionRoundFilter();
    this.handleHeatingTypeFilter();
    this.handleCharacteristic();
  }
  private filterChanged = () => this.FilterChanged.emit(this.filter);
}


interface toggleButton<T> {
  key: T,
  label: string,
  toggled: boolean,
  icon?: string
}