import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, input } from '@angular/core';
import { ClickOutsideDirective } from '../../../shared/directives/click-outside.directive';
import { NumericOnlyDirective } from 'src/app/shared/directives/number-only.directive';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-range-dropdown',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective, NumericOnlyDirective, TranslocoDirective],
  templateUrl: './range-dropdown.component.html',
  styleUrl: './range-dropdown.component.css'
})
export class RangeDropdownComponent {
  @Input() dropdownItems: RangeDropdownItem[] = [];
  @Input() title: string | undefined;
  @Input() preselectedRange: RangeDropdownItem | undefined;
  @Input() allValuesLabel: string | undefined;
  @Input() prefix: string | undefined;
  @Input() prefixIcon: string | undefined;
  @Input() postfix: string | undefined;
  @Input() postfixIcon: string | undefined;
  @Output() selectedRangeEvent: EventEmitter<RangeDropdownItem | undefined> = new EventEmitter<RangeDropdownItem | undefined>();

  selectedLabel: string | undefined;
  showResults: boolean = false;
  MultipleSelectedString: string = "Multiple"
  selectedRange: RangeDropdownItem | undefined;
  minValue: number | null = null;
  maxValue: number | null = null;

  ngOnInit() {
    this.setDefaultValue();
  }
  ngOnChanges() {
    this.setDefaultValue();
  }
  setDefaultValue() {
    if (this.preselectedRange) {
      this.itemSelected(this.preselectedRange);
      this.selectedRange = this.preselectedRange;
    }
    else {
      this.selectedLabel = this.allValuesLabel;
    }
  }
  itemSelected(item: RangeDropdownItem) {
    this.selectedRange = item;
    this.resolveViewLabel()
    this.selectedRangeEvent.emit(this.selectedRange);
    this.minValue = item.from ?? null;
    this.maxValue = item.to ?? null;
    this.closeDropdown();
  }
  ClearFilter() {
    this.selectedRange = undefined;
    this.selectedLabel = this.allValuesLabel
    this.minValue = null;
    this.maxValue = null;
    this.selectedRangeEvent.emit(undefined);
    this.closeDropdown();
  }

  toggleDropdown() {
    this.showResults = !this.showResults;
  }

  closeDropdown() {
    this.showResults = false;
  }

  resolveViewLabel() {
    if (this.selectedRange) {
      this.selectedLabel = this.selectedRange.label;
    }
    else {
      this.selectedLabel = this.allValuesLabel;
    }
  }

  minValueChanged(event: Event) {
    let val = (event.target as HTMLTextAreaElement).value
    this.minValue = val !== '' ? parseFloat(val) : null;
    if (this.maxValue && this.minValue && this.minValue > this.maxValue) {
      this.minValue = this.maxValue;
    }
    this.selectedRange = {
      from: this.minValue !== null ? this.minValue : undefined,
      to: this.maxValue ?? undefined,
      label: `${this.minValue ?? ''}- ${this.maxValue ?? ''}`
    }
    this.selectedRangeEvent.emit(this.selectedRange);
    this.resolveViewLabel();
  }
  maxValueChanged(event: Event) {
    let val = (event.target as HTMLTextAreaElement).value
    this.maxValue = val !== '' ? parseFloat(val) : null;
    if (this.minValue && this.maxValue && this.minValue > this.maxValue) {
      this.maxValue = this.minValue;
    }
    this.selectedRange = {
      from: this.minValue ?? undefined,
      to: this.maxValue !== null ? this.maxValue : undefined,
      label: `${this.minValue ?? ''}- ${this.maxValue ?? ''}`
    }
    this.selectedRangeEvent.emit(this.selectedRange);
    this.resolveViewLabel();
  }
}
export interface RangeDropdownItem {
  from?: number,
  to?: number,
  label: string
}
