import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { combineLatest } from 'rxjs';
import { CompleteRequestCallbackComponent, IRequestData } from '../../complete-request-callback/complete-request-callback.component';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-onboarding-welcome',
  standalone: true,
  imports: [CommonModule, RouterModule, CompleteRequestCallbackComponent, TranslocoDirective],
  templateUrl: './onboarding-welcome.component.html',
  styleUrl: './onboarding-welcome.component.css'
})
export class OnboardingWelcomeComponent {
  intent: string | undefined;
  buyer: string = 'on-boarding-buyer';
  request: string = 'request';
  seller: string = 'on-boarding-seller';
  nav: string = 'home';
  requestData: IRequestData | undefined;
  isRequest = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {

    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams]).subscribe(res=>{
      const params = res[0];
      const queryParams = res[1];

      this.requestData = queryParams as IRequestData;

      if(this.requestData?.action=== 'callback' || this.requestData?.action === 'walkthrough' ){
        this.isRequest = true;
      }

      this.intent = params['intent'];
      switch (this.intent) {
        case "buyer":
          this.nav = this.buyer;
          break;
        case "seller":
          this.nav = this.seller;
          break;
        case "home":
        default:
          this.nav = "home"
      }
    })
  }

  continue = () => this.router.navigate([this.nav]);

  skip = () => this.router.navigate(['home']);
}
