<ng-container *transloco="let t; prefix: 'find-property.advanced-filters-drawer'">
    <div *ngIf="isOpen">
        <div class="fixed inset-0 bg-black bg-opacity-50 z-0" (click)="toggleDrawer()"></div>
        <div
            class="advance-filter-wrapper z-50 p-4 bg-gray-50 h-[calc(100vh-84px)] overflow-y-auto  fixed right-0 top-[84px]">
            <div class="advance-filter-header">
                <h4>{{t('header')}}</h4>
                <button role="button" class="cursor-pointer">
                    <i class="icon-close" (click)="toggleDrawer()"></i>
                </button>
            </div>

            <div class="advance-filter-body">
                <form>
                    <div class="filter-section-wrapper">
                        <h5 class="filter-title">{{t('filters.floor')}}</h5>
                        <div class="section-items">
                            <label *ngFor="let button of floors" class="filter-radio-wrapper">
                                {{button.label}}
                                <input type="checkbox" class="hidden" [attr.id]="button.key"
                                    name="menu-item-radio-floor" [checked]="button.toggled"
                                    (click)="floorsChange(button.key)">
                            </label>
                        </div>
                    </div>

                    <div class="divider-line1 my-4"></div>

                    <div class="filter-section-wrapper">
                        <h5 class="filter-title">{{t('filters.parking')}}</h5>
                        <div class="section-items">
                            <label class="filter-radio-wrapper">
                                {{t('labels.yes')}}
                                <input type="checkbox" class="hidden" id="parking-yes" name="menu-item-radio-parking"
                                    (click)="parkingSelected(true)" [checked]="filter.hasParking">
                            </label>
                            <label class="filter-radio-wrapper">
                                {{t('labels.no')}}
                                <input type="checkbox" class="hidden" id="parking-no" name="menu-item-radio-parking"
                                    (click)="parkingSelected(false)"
                                    [checked]="filter.hasParking!= undefined && !filter.hasParking">
                            </label>
                        </div>
                    </div>

                    <div class="divider-line1 my-4"></div>

                    <div class="filter-section-wrapper">
                        <h5 class="filter-title">{{t('filters.bedrooms')}}</h5>
                        <div class="section-items">
                            <label *ngFor="let button of bedrooms" class="filter-radio-wrapper">
                                {{button.label}}
                                <input type="checkbox" class="hidden" [attr.id]="button.key"
                                    name="menu-item-radio-beedrooms" [checked]="button.toggled"
                                    (click)="roomsChange(button.key)">
                            </label>

                        </div>
                    </div>

                    <div class="divider-line1 my-4"></div>

                    <div class="filter-section-wrapper">
                        <h5 class="filter-title">{{t('filters.constructionYear')}}</h5>
                        <div>
                            <fieldset class="flex flex-col gap-2 group/input mb-4">
                                <label
                                    class="font-semibold flex items-center justify-between text-gray-950 group-disabled:text-gray-300">{{t('labels.from')}}</label>
                                <div
                                    class="flex text-gray-500 rounded-full bg-white border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    <date-picker [yearsOnly]="true"
                                        (valueChange)="constructionYearFrom($event)"></date-picker>
                                    <input
                                        class="outline-none flex-1 group-hover/input:bg-white transition-colors duration-300"
                                        [disabled]="true" [placeholder]="t('placeholders.yearFrom')"
                                        [value]="filter.minConstructionYear ?? null">
                                    <i *ngIf="filter.minConstructionYear" class="icon-close"
                                        (click)="clearMinConstructionYear()"></i>
                                </div>
                            </fieldset>
                            <fieldset class="flex flex-col gap-2 group/input">
                                <label
                                    class="font-semibold flex items-center justify-between text-gray-950 group-disabled:text-gray-300">{{t('labels.to')}}</label>
                                <div
                                    class="flex text-gray-500 rounded-full bg-white border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                                    <!-- <i class="icon-calendar text-gray-700 text-xl"></i> -->
                                    <date-picker [yearsOnly]="true"
                                        (valueChange)="constructionYearTo($event)"></date-picker>
                                    <input
                                        class="outline-none flex-1 group-hover/input:bg-white transition-colors duration-300"
                                        [disabled]="true" [placeholder]="t('placeholders.yearTo')"
                                        [value]="filter.maxConstructionYear ?? null">
                                    <i *ngIf="filter.minConstructionYear" class="icon-close"
                                        (click)="clearMaxConstructionYear()"></i>
                                </div>
                            </fieldset>
                        </div>

                        <div class="divider-line1 my-4"></div>

                        <div class="filter-section-wrapper">
                            <h5 class="filter-title">{{t('filters.preApproved')}}</h5>
                            <div class="section-items">
                                <label class="filter-radio-wrapper">
                                    {{t('labels.yes')}}
                                    <input type="checkbox" class="hidden" id="preapproved-yes"
                                        name="menu-item-radio-preapproved" (click)="preaprovedChanged(true)"
                                        [checked]="filter.preApproved">
                                </label>
                                <label class="filter-radio-wrapper">
                                    {{t('labels.no')}}
                                    <input type="checkbox" class="hidden" id="preapproved-no"
                                        name="menu-item-radio-preapproved" (click)="preaprovedChanged(false)"
                                        [checked]="filter.preApproved!= undefined && !filter.preApproved">
                                </label>
                            </div>
                        </div>

                        <div class="divider-line1 my-4"></div>

                        <div class="filter-section-wrapper">
                            <h5 class="filter-title">{{t('filters.auction')}}</h5>
                            <div class="section-items">
                                <label class="filter-radio-wrapper">
                                    {{t('labels.yes')}}
                                    <input type="checkbox" class="hidden" id="auction-yes"
                                        name="menu-item-radio-auction" (click)="auctionChanged(true)"
                                        [checked]="filter.inAuction">
                                </label>
                                <label class="filter-radio-wrapper">
                                    {{t('labels.no')}}
                                    <input type="checkbox" class="hidden" id="auction-no" name="menu-item-radio-auction"
                                        (click)="auctionChanged(false)"
                                        [checked]="filter.inAuction!= undefined && !filter.inAuction">
                                </label>
                            </div>
                        </div>

                        <div class="divider-line1 my-4"></div>

                        <div class="filter-section-wrapper">
                            <h5 class="filter-title">{{t('filters.auctionRound')}}</h5>
                            <div class="section-items">
                                <label *ngFor="let button of auctionRound" class="filter-radio-wrapper">
                                    {{button.label}}
                                    <input type="checkbox" class="hidden" [attr.id]="button.key"
                                        name="menu-item-radio-auctionround" [checked]="button.toggled"
                                        (click)="auctionRoundChange(button.key)">
                                </label>
                            </div>
                        </div>

                        <div class="divider-line1 my-4"></div>

                        <div class="filter-section-wrapper">
                            <h5 class="filter-title">{{t('filters.characteristics')}}</h5>
                            <div class="section-items">
                                <label *ngFor="let button of characteristics" class="filter-radio-wrapper">
                                    <i class="mr-2" [ngClass]="button.icon ?? ''"></i>{{button.label}}
                                    <input type="checkbox" class="hidden" [attr.id]="button.key"
                                        name="menu-item-radio-characteristics" [checked]="button.toggled"
                                        (click)="toggleCharacteristics(button.key)">
                                </label>
                            </div>
                        </div>

                        <div class="divider-line1 my-4"></div>

                        <div class="filter-section-wrapper">
                            <h5 class="filter-title">{{t('filters.heatingType')}}</h5>
                            <div class="section-items">
                                <label *ngFor="let button of heatingtype" class="filter-radio-wrapper">
                                    <i class="mr-2" [ngClass]="button.icon ?? ''"></i> {{button.label}}
                                    <input type="checkbox" class="hidden" [attr.id]="button.key"
                                        name="menu-item-radio-heatingtype" [checked]="button.toggled"
                                        (click)="heatingTypeChanged(button.key)">
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-container>