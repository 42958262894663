import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IndicationSidePanelComponent } from "../../shared/components/indication-side-panel/indication-side-panel.component";
import { IndicationSidePanelInputModel } from 'src/app/shared/components/indication-side-panel/IndicationSidePanelInputModel';
import { FaqItemComponent } from "./faq-item/faq-item.component";
import { CmsApiService, IApiContentResponseModel, ICmsApiService } from 'src/app/services/cms-api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CmsService } from 'src/app/services/cms.service';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-faq',
  standalone: true,
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.css',
  imports: [CommonModule, IndicationSidePanelComponent, FaqItemComponent, TranslocoDirective]
})
export class FaqComponent {
  protected sidePanelData: Array<IndicationSidePanelInputModel> = [];
  model: IApiContentResponseModel = new IApiContentResponseModel();
  faqGroup: any;
  isLoggedIn: boolean = false;

  constructor(private modelService: CmsService, private authService: AuthService) {
    this.modelService.getContentItemByPath("faq-page", undefined, "properties[$all]", undefined, false, "faq-page").subscribe(apiResponse => {
      this.model = apiResponse;
      this.faqGroup = this.model.properties!['faqGroup'].items;

      for (var group of this.faqGroup) {
        let panelItem = new IndicationSidePanelInputModel();
        panelItem.label = group.content.properties.groupTitle;
        panelItem.scrollTo = group.content.properties.groupTitle;

        this.sidePanelData.push(panelItem);
      }
    });

    this.authService.isLoggedIn().subscribe(s => this.isLoggedIn = s);
  }
}
