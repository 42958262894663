import { Routes } from '@angular/router';
import { AuthCallbackComponent } from './core/components/auth-callback/auth-callback.component';
import { AuthRenewComponent } from './core/components/auth-renew/auth-renew.component';
import { PortalShellComponent } from './layout/components/portal-shell/portal-shell.component';
import { OnboardingBuyerComponent } from './features/onboarding/onboarding-buyer/onboarding-buyer.component';
import { OnboardingGuardService } from './core/services/guard-services/onboarding-guard.service';
import { OnboardingSellerComponent } from './features/onboarding/onboarding-seller/onboarding-seller.component';
import { RegistrationComponent } from './features/registration/registration.component';
import { LoginComponent } from './features/login/login.component';
import { PropertyDetailsComponent } from './features/property-details/property-details.component';
import { GridListCardsComponent } from './features/find-property/grid-list-cards/grid-list-cards.component';
import { OnboardingWelcomeComponent } from './features/onboarding/onboarding-welcome/onboarding-welcome.component';
import { AuthGuardService } from './core/services/guard-services/auth-guard.service';
import { FaqComponent } from './features/faq/faq.component';
import { PropertyListingComponent } from './features/property-listing/property-listing.component';
import { WelcomePageComponent } from './features/property-upload-wizard/welcome-page/welcome-page.component';
import { PropertyUploadWizardComponent } from './features/property-upload-wizard/property-upload-wizard.component';
import { FindPropertyComponent } from './features/find-property/find-property.component';
import { CheckoutComponent } from './shared/components/checkout/checkout.component';
import { CheckoutProcessingComponent } from './shared/components/checkout/checkout-processing/checkout-processing.component';
import { CheckoutResultComponent } from './shared/components/checkout/checkout-result/checkout-result.component';
import { CheckoutFailComponent } from './shared/components/checkout/checkout-fail/checkout-fail.component';
import { PropertyOwnershipGuardService } from './core/services/guard-services/property-ownership-guard.service';
import { FinancingComponent } from './features/financing/financing.component';
import { LoanApplicationWizardComponent } from './features/loan-application-wizard/loan-application-wizard.component';
import { LoanEligibilityWizardComponent } from './features/loan-eligibility-wizard/loan-eligibility-wizard.component';
import { ServicesComponent } from './features/services/services.component';
import { ServiceDetailsComponent } from './features/services/service-details/service-details.component';
import { ServicePropertySelectComponent } from './features/services/service-property-select/service-property-select.component';

export const routes: Routes = [
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'auth-renew', component: AuthRenewComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'login', component: LoginComponent },
  { path: 'on-boarding-buyer', component: OnboardingBuyerComponent },
  { path: 'on-boarding-seller', component: OnboardingSellerComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'financing', component: FinancingComponent },
  { path: 'services', component: ServicesComponent },
  {
    path: '', canActivate: [OnboardingGuardService], children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: PortalShellComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./features/home/home.module').then((x) => x.HomeModule),
          },
        ]
      },
      {
        path: 'properties/:propertyId',
        component: PropertyDetailsComponent,
        children: []
      },
      {
        path: 'properties',
        component: FindPropertyComponent,
        children: []
      },
      {
        path: 'welcome/:intent',
        component: OnboardingWelcomeComponent,
        children: []
      },
      {
        path: 'faq',
        component: FaqComponent,
        children: []
      },
      {
        path: 'account-settings',
        loadChildren: () =>
          import('./features/account-settings/account-settings.module').then((x) => x.AccountSettingsModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'listing/:propertyCode',
        component: PropertyListingComponent,
        canActivate: [AuthGuardService, PropertyOwnershipGuardService]
      },
      {
        path: 'sell-property-get-started',
        component: WelcomePageComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'property-upload-wizard',
        component: PropertyUploadWizardComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./features/my-profile/my-profile.routes').then(mod => mod.MY_PROFILE_ROUTES),
      },
      {
        path: 'checkout/:orderNumber',
        component: CheckoutComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'checkout/:orderNumber/transaction/:transactionId',
        component: CheckoutProcessingComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'checkout/:orderNumber/complete',
        component: CheckoutResultComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'checkout/:orderNumber/fail',
        component: CheckoutFailComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'loan-application',
        component: LoanApplicationWizardComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'loan-eligibility',
        component: LoanEligibilityWizardComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'services/:serviceType',
        component: ServiceDetailsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'services/:serviceType/select-property',
        component: ServicePropertySelectComponent,
        canActivate: [AuthGuardService]
      },
    ]
  }
];
