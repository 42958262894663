<section class="pt-6 pb-12" *transloco="let t; prefix: 'property-details.features-section'">
    <h2 class="h2 text-secondary pb-6">{{t('header')}}</h2>
    <div class="flex flex-wrap gap-4 overflow-hidden" [ngClass]="{ 'max-h-[304px]': this.isCollapsed }">
        <app-features-visual-checkbox *ngIf="model.amenities?.hasAlarm" [visCheckClass]="'primary'" [icon]="'alarm'"
            [iconClass]="''" [spanClass]="''" [mainText]="'shared.amenities.hasAlarm' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.hasBoiler" [visCheckClass]="'primary'" [icon]="'boiler'"
            [iconClass]="''" [spanClass]="''" [mainText]="'shared.amenities.hasBoiler' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.hasElevator" [visCheckClass]="'primary'"
            [icon]="'elevator'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.hasElevator' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.hasGarden" [visCheckClass]="'primary'" [icon]="'garden'"
            [iconClass]="''" [spanClass]="''" [mainText]="'shared.amenities.hasGarden' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.hasNightElectricCurrent" [visCheckClass]="'primary'"
            [icon]="'night-electric-current'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.hasNightElectricCurrent' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.hasPlayroom" [visCheckClass]="'primary'"
            [icon]="'playroom'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.hasPlayroom' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.hasPool" [visCheckClass]="'primary'" [icon]="'pool'"
            [iconClass]="''" [spanClass]="''" [mainText]="'shared.amenities.hasPool' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.hasSafetyDoor" [visCheckClass]="'primary'"
            [icon]="'safety-door'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.hasSafetyDoor' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.hasSolarWaterHeating" [visCheckClass]="'primary'"
            [icon]="'solar-water-heating'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.hasSolarWaterHeating' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.hasStorageRoom" [visCheckClass]="'primary'"
            [icon]="'storage-room'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.hasStorageRoom' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.hasTents" [visCheckClass]="'primary'" [icon]="'fireplace'"
            [iconClass]="''" [spanClass]="''" [mainText]="'shared.amenities.hasTents' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.internalStaircase" [visCheckClass]="'primary'"
            [icon]="'staircase'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.internalStaircase' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.isAiry" [visCheckClass]="'primary'" [icon]="'airy'"
            [iconClass]="''" [spanClass]="''" [mainText]="'shared.amenities.isAiry' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.isBright" [visCheckClass]="'primary'" [icon]="'bright'"
            [iconClass]="''" [spanClass]="''" [mainText]="'shared.amenities.isBright' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.isFloorApartment" [visCheckClass]="'primary'"
            [icon]="'floor-apartment'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.isFloorApartment' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.isPenthouse" [visCheckClass]="'primary'"
            [icon]="'penthouse'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.isPenthouse' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.petsAreAllowed" [visCheckClass]="'primary'" [icon]="'pet'"
            [iconClass]="''" [spanClass]="''" [mainText]="'shared.amenities.petsAreAllowed' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.sieves" [visCheckClass]="'primary'" [icon]="'sieve'"
            [iconClass]="''" [spanClass]="''" [mainText]="'shared.amenities.sieves' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.suitableForProfessionalUse" [visCheckClass]="'primary'"
            [icon]="'suitable-for-professional-use'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.suitableForProfessionalUse' | transloco"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.suitableForTouristUse" [visCheckClass]="'primary'"
            [icon]="'suitable-for-tourist-use'" [iconClass]="''" [spanClass]="''"
            [mainText]="'Suitable for tourist use'"></app-features-visual-checkbox>

        <app-features-visual-checkbox *ngIf="model.amenities?.accessibilityForDisabled" [visCheckClass]="'primary'"
            [icon]="'disabled'" [iconClass]="''" [spanClass]="''"
            [mainText]="'shared.amenities.accessibilityForDisabled' | transloco"></app-features-visual-checkbox>

    </div>
    <button (click)="toggleCollapse($event)" class="btn-text flex gap-2 items-center py-[6px]"><i
            class="icon-chevron-down text-xl button-chevron transition-all duration-500"
            [ngClass]="{ 'icon-chevron-down': this.isCollapsed,'icon-chevron-up': !this.isCollapsed, }"></i>{{t('buttons.collapse')}}</button>
</section>