<div class="flex flex-col flex-1" *transloco="let t; prefix: 'onboarding.onboarding-seller'">
    <ng-container [ngSwitch]="Step">
        <!-- STEP 1 -->
        <ng-container *ngSwitchCase="'Step1'">
            <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                <div class="h-[67px]"></div>
                <div class="flex flex-col gap-12 items-center justify-center">
                    <div class="flex flex-col gap-4">
                        <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepOne')}}
                        </h2>
                        <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepOne')}}
                        </p>
                    </div>
                </div>
            </div>
            <app-navigation-buttons [HidePrevious]="true" (ExitButtonClicked)="openModalComponent()"
                (NextButtonClicked)="Goto('Step2')" (SkipButtonClicked)="Goto('Step2')"></app-navigation-buttons>
        </ng-container>

        <!-- STEP 2 -->
        <ng-container *ngSwitchCase="'Step2'">
            <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]=1 [Steps]=Steps></progress-stepper>
                <div class="flex flex-col gap-12 items-center justify-center">
                    <div class="flex flex-col gap-4">
                        <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepTwo')}}
                        </h2>
                        <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepTwo')}}
                        </p>
                    </div>
                    <app-property-type-buttons (SelectedItems)="buttonSelected($event)" [IsMultiple]="false"
                        [ToggledButtons]="SelectedPropertyType? [SelectedPropertyType]:[]"></app-property-type-buttons>
                </div>
            </div>
            <app-navigation-buttons (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="Goto('Step1')"
                (NextButtonClicked)="SaveStep2()" (SkipButtonClicked)="Goto('Step3')"></app-navigation-buttons>
        </ng-container>

        <!-- STEP 3 -->
        <ng-container *ngSwitchCase="'Step3'">
            <div class="container flex flex-1 flex-col rfs:gap-28 items-center">
                <progress-stepper class="progress-stepper-onboarding" [StepNo]=2 [Steps]=Steps></progress-stepper>
                <div class="flex flex-col gap-12 items-center justify-center">
                    <div class="flex flex-col gap-4">
                        <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepThree')}}
                        </h2>
                        <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepThree')}}
                        </p>
                    </div>
                    <div class="flex md:flex-row flex-col gap-4 justify-center max-w-[515px] w-full">
                        <ng-container *ngFor="let button of SupportServices">

                            <label [attr.for]="button.id" class="visual-checkbox flex flex-col flex-1">
                                <input type="checkbox" [attr.id]="button.id" class="opacity-0 absolute h-0 w-0"
                                    (click)="toggleStep3Select(button.id)" [checked]="button.isToggled">
                                <i class="text-[40px]" [ngClass]="button.icon"></i>
                                <span class="">{{button.title}}</span>
                            </label>

                        </ng-container>
                    </div>
                </div>
            </div>

            <app-navigation-buttons (ExitButtonClicked)="openModalComponent()" (PreviousButtonClicked)="Goto('Step2')"
                (NextButtonClicked)="SaveStep3()" (SkipButtonClicked)="Goto('Step2')"></app-navigation-buttons>
        </ng-container>

        <!-- Step 4 -->
        <ng-container *ngSwitchCase="'Step4'">
            <div class="flex flex-1 flex-col h-full">
                <div class="container flex-1 flex flex-col justify-between rfs:gap-28 items-center">
                    <div class="max-w-[1008px] w-full">
                    </div>
                    <div class="flex flex-col gap-12 items-center justify-center max-w-[800px]">
                        <div class="flex justify-center">
                            <div class="flex flex-col gap-4 items-center justify-center">
                                <div class="w-[221px] h-[211px]">
                                    <img class="object-center object-contain" src="assets/on-boarding/bg-4.webp"
                                        alt="property with society karikatur">
                                </div>
                            </div>
                        </div>
                        <h2 class="rfs:text-[40px] text-center font-semibold text-secondary">{{t('headers.stepFour')}}
                        </h2>
                        <p class="rfs:text-xl text-center font-light text-tone-09">{{t('infoText.stepFour')}}
                        </p>
                    </div>
                </div>
                <div class="flex flex-1 items-end pt-8">
                    <div class="w-full border-t border-actionbar-light">
                        <div
                            class="bg-primary-very-light py-6 px-3 lg:px-[72px] flex flex-col md:flex-row items-center justify-center gap-2">
                            <div class="flex flex-col md:flex-row gap-4 items-center justify-between">
                                <button (click)="SaveAnswers()"
                                    class="btn-primary min-w-[288px] flex items-center justify-center">{{t('buttons.saveAnswers')}}<i
                                        class="icon-arrow-right text-[20px]"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>