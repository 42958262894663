import { CommonModule } from '@angular/common';
import { ProgressStepperComponent } from 'src/app/shared/components/progress-stepper/progress-stepper.component';
import { Component } from '@angular/core';
import * as _ from 'lodash';
import { OnboardingData, Step1Button } from './models';
import { GoogleMapsAutocompleteComponent } from 'src/app/shared/components/google-maps-autocomplete/google-maps-autocomplete.component';
import { AddressComponentModel, AddressComponent } from 'src/app/shared/components/google-maps-autocomplete/helper';
import { Subject, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { LookingForPropertyOfType, ProfilingAnswers } from 'src/app/services/portal-api.service';
import { Router, RouterModule } from '@angular/router';
import { ModalService } from '@indice/ng-components';
import { OnboardingExitModalComponent } from '../onboarding-exit-modal/onboarding-exit-modal.component';
import { NavigationButtonsComponent } from 'src/app/shared/components/navigation-buttons/navigation-buttons.component';
import { PropertyTypeButtonsComponent } from 'src/app/shared/components/property-type-buttons/property-type-buttons.component';
import { PlaceBoundsViewModel, PlaceViewModel } from 'src/app/services/property.service';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-onboarding-buyer',
  standalone: true,
  imports: [CommonModule, ProgressStepperComponent, GoogleMapsAutocompleteComponent, RouterModule, NavigationButtonsComponent, PropertyTypeButtonsComponent, TranslocoDirective],
  templateUrl: './onboarding-buyer.component.html',
  styleUrl: './onboarding-buyer.component.css'
})
export class OnboardingBuyerComponent {
  Step: string = "Step1"
  Steps: string[] = [
    "1. IDEAL PROPERTY TYPES",
    "2. PRICE RANGE",
    "3. LOCATION"
  ]
  debounceTime = 800;
  PropertyTypes: LookingForPropertyOfType[] = []
  PriceMin: number | undefined;
  PriceMax: number | undefined;
  PriceMin$: Subject<number> = new Subject();
  PriceMax$: Subject<number> = new Subject();
  SearchText: string = "";
  PlacesText: string = "";
  OnboardingData: OnboardingData = new OnboardingData;
  SelectedPlaces: PlaceViewModel[] = [];
  disableSearchSubject: Subject<void> = new Subject<void>;
  clearSearchSubject: Subject<void> = new Subject<void>;
  Place: PlaceViewModel | undefined;
  constructor(private _addressComponentHelper: AddressComponent, private onboardingService: OnboardingService, private _router: Router, private modalService: ModalService) { }
  ngOnInit() {
    this.PriceMin$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged()
    ).subscribe(x => {
      this.PriceMin = x;
      if (this.PriceMax && this.PriceMax < this.PriceMin) {
        this.PriceMin = this.PriceMax;
      }
    })

    this.PriceMax$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged()
    ).subscribe(x => {
      this.PriceMax = x;
      if (this.PriceMin && this.PriceMax < this.PriceMin) {
        this.PriceMax = this.PriceMin;
      }
    })
  }
  buttonSelected(selection: LookingForPropertyOfType[]) {
    this.PropertyTypes = selection;
  }
  Goto(step: string) {
    this.OnboardingDataToString()
    this.Step = step;
  }
  private OnboardingDataToString() {
    this.SearchText = "";
    this.PlacesText = "";
    if (this.OnboardingData && this.OnboardingData.propertyTypes) {
      this.SearchText += this.OnboardingData.propertyTypes.join(" and ");
    }
    if (this.OnboardingData && this.OnboardingData.locations && this.OnboardingData.locations.length > 0) {
      this.SearchText += this.SearchText.length > 0 ? " in " : "in ";
      this.SearchText += _.uniq(this.OnboardingData.locations.map(x => x.Prefecture)).join(", ");
      this.PlacesText += `See property in ${_.first(this.OnboardingData.locations)?.Prefecture}`;
    }
    else {
      this.PlacesText += `See related properties`
    }

  }

  SaveStep1() {
    this.OnboardingData.propertyTypes = this.PropertyTypes;
    this.Goto("Step2")
    console.log(this.OnboardingData);
  }
  SaveStep2() {
    this.OnboardingData.priceMin = this.PriceMin;
    this.OnboardingData.priceMax = this.PriceMax;
    this.Goto("Step3")
    console.log(this.OnboardingData);
  }
  SaveStep3() {
    this.OnboardingData.locations = this.SelectedPlaces;
    this.OnboardingDataToString();
    this.Goto("Step4")
    console.log(this.OnboardingData);
  }
  SaveAnswers() {
    var locations: string[] | undefined

    if (this.OnboardingData.locations) {
      locations = this.OnboardingData.locations.map(x => x.FormatedAddress as string)
    }
    var profilingAnswers: ProfilingAnswers = new ProfilingAnswers({
      buyerPropertyLocations: locations,
      buyerPropertyPriceMax: this.OnboardingData.priceMax,
      buyerPropertyPriceMin: this.OnboardingData.priceMin,
      buyerPropertyTypes: this.OnboardingData.propertyTypes
    });
    this.onboardingService.updateAccountOwner(undefined, profilingAnswers).subscribe();
    this._router.navigate(['/login'])
  }
  PlaceSelected(selection: any) {
    let addressComponents: AddressComponentModel[] = selection['address_components']
    let geometry: google.maps.places.PlaceGeometry = selection['geometry'];
    let place: PlaceViewModel = {
      FormatedAddress: selection['formatted_address'],
      Country: this._addressComponentHelper.findCountry(addressComponents),
      Prefecture: this._addressComponentHelper.findLocality(addressComponents),
      AdministrativeAreas: this._addressComponentHelper.findAdministativeAreas(addressComponents),
      StreetName: this._addressComponentHelper.findRoute(addressComponents),
      StreetNumber: this._addressComponentHelper.findStreetNumber(addressComponents),
      PostalCode: this._addressComponentHelper.findPostalCode(addressComponents),
      Lon: geometry?.location?.lng() ?? null,
      Lat: geometry?.location?.lat() ?? null,
      Bounds: new PlaceBoundsViewModel()
    };
    this.Place = place;
  }
  AddPlace() {
    if (!this.Place) {
      return;
    }
    this.SelectedPlaces.push(this.Place);
    this.clearSearchSubject.next();
    if (this.SelectedPlaces.length > 4) {
      this.disableSearchSubject.next();
    }
  }
  DeletePlace(place: PlaceViewModel) {
    var index = this.SelectedPlaces.indexOf(place);
    let disabled = false;
    if (this.SelectedPlaces.length >= 5) {
      disabled = !disabled;
    }
    this.SelectedPlaces.splice(index, 1);
    if (this.SelectedPlaces.length < 5 && disabled) {
      this.disableSearchSubject.next();
    }
  }
  SetMinPrice(value: string) {
    this.PriceMin$.next(Number.parseFloat(value));
  }
  SetMaxPrice(value: string) {
    this.PriceMax$.next(Number.parseFloat(value));
  }
  openModalComponent() {
    const exitModal = this.modalService.show(OnboardingExitModalComponent, {
      animated: true,
      keyboard: true
    });
    exitModal.onHidden?.subscribe((response: any) => {

    });
  }
}