import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

@Component({
  selector: 'app-sort-dropdown',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
  templateUrl: './sort-dropdown.component.html',
  styleUrl: './sort-dropdown.component.css'
})
export class SortDropdownComponent {
  @Input() dropdownItems: FilterDropdownItem[] = [];
  @Input() title: string | undefined;
  @Input() selectedKey: string | undefined;
  @Input() allValuesLabel: string | undefined;
  @Output() selectedItem: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

  selectedLabel: string | undefined;
  showResults: boolean = false;
  // MultipleSelectedString: string = "Multiple"

  ngOnInit() {
    if (this.selectedKey) {
      this.resolveSelection()
    }
    else {
      this.selectedLabel = this.allValuesLabel;
    }
  }
  ItemSelected(item: FilterDropdownItem) {
    this.selectedKey = item.key;

    this.resolveSelection()
    this.selectedItem.emit(this.selectedKey);
  }
  ClearFilter() {
    this.selectedKey = this.allValuesLabel;
    this.resolveSelection();
    this.selectedItem.emit(undefined);
    this.closeDropdown();
  }

  toggleDropdown() {
    this.showResults = !this.showResults;
  }

  closeDropdown() {
    this.showResults = false;
  }

  private resolveSelection() {
    this.resolveViewLabel();
  }
  private resolveViewLabel() {
    if (this.selectedKey) {
      this.selectedLabel = this.dropdownItems.find(x => x.key == this.selectedKey)?.label ?? this.allValuesLabel;
    }
    else {
      this.selectedLabel = this.allValuesLabel;
    }
  }

}
export interface FilterDropdownItem {
  key: string,
  label: string,
  icon?: string,
  checked?: boolean
}
