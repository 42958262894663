<div class="flex items-end pt-8" *transloco="let t; prefix: 'shared.navigation-buttons'">
    <div class="w-full border-t border-gray-300">
        <div class="bg-gray-100 py-6 px-3 lg:px-[72px] flex flex-col md:flex-row items-center justify-between gap-2">
            <div>
                <button *ngIf="!HideExit" class="btn-text" (click)="ExitButton()">{{t('buttons.exit')}}</button>
            </div>
            <div class="flex flex-col md:flex-row gap-4 justify-between">
                <button *ngIf="!HidePrevious" class="btn-secondary min-w-[288px] flex items-center justify-center"
                    (click)="PreviousButton()"><i *ngIf="!HidePreviousArrow"
                        class="icon-arrow-left text-[20px]"></i>{{CustomPreviousTitle ?
                    CustomPreviousTitle : t('buttons.previous')}}</button>

                <button *ngIf="!HideNext" class="btn-primary min-w-[288px] flex items-center justify-center"
                    (click)="NextButton()">{{CustomNextTitle ? CustomNextTitle : t('buttons.next')}}<i
                        *ngIf="!HideNextArrow" class="icon-arrow-right text-[20px]"></i></button>
            </div>
            <div class="flex">
                <button *ngIf="!HideSkip" class="btn-text flex items-center justify-center gap-2"
                    (click)="SkipButton()">{{CustomSkipTitle ? CustomSkipTitle : t('buttons.skip')}}
                    <i class="icon-arrow-right text-[20px]"></i></button>
            </div>
        </div>
    </div>
</div>