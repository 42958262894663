import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { Photo, PhotoResultSet, PortalApiService, PropertyDetails } from 'src/app/services/portal-api.service';
import { Toast, ToastType, ToasterService } from 'src/app/services/toaster.service';
import { ModalService } from '@indice/ng-components';
import { ModalImageGalleryComponent } from './modal-image-gallery/modal-image-gallery.component';
import { Location } from '@angular/common';
import { RequestCallbackModalComponent } from 'src/app/shared/components/request-callback-modal/request-callback-modal.component';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { RequestWalkthroughModalComponent } from 'src/app/shared/components/request-walkthrough-modal/request-walkthrough-modal.coponent';
import { TranslocoDirective } from '@jsverse/transloco';
@Component({
  selector: 'app-hero-section',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './hero-section.component.html',
  styleUrl: './hero-section.component.css'
})
export class HeroSectionComponent {

  @Input() model = new PropertyDetails();
  photos: PhotoResultSet | undefined;
  toast$: Observable<Toast> = new Observable();

  constructor(private modalService: ModalService, private _location: Location, private _authService: AuthService, @Inject(ToasterService) private _toastService: ToasterService, private portalService: PortalApiService) { }
  ngOnInit() {
    if (this.model?.defaultPhoto) {
      this.model.defaultPhoto.uri = this.model.defaultPhoto.photoItems?.find(pi => pi.uri?.includes('-xl.webp'))?.uri ?? undefined
    }

    this.portalService.getPhotos(this.model.id ?? '').subscribe({
      next: (res) => { this.photos = res }
    })
  }
  openModalComponent() {
    const imageGalleryModal = this.modalService.show(ModalImageGalleryComponent, {
      animated: true,
      initialState: {
        title: this.model?.title,
        photos: this.photos
      },
      keyboard: true
    });
    imageGalleryModal.onHidden?.subscribe((response: any) => {

    });
  }
  GoBack() {
    this._location.back();
  }

  public requestCallback() {
    const currentUser = this._authService.getCurrentUser();
    const isLoggedIn = currentUser !== null;
    const requestCallbackModal = this.modalService.show(RequestCallbackModalComponent, {
      keyboard: true,
      class: isLoggedIn ? '' : 'modal-xl',
      initialState: {
        property: this.model,
        isLoggedIn
      }
    })
    requestCallbackModal.onHidden?.subscribe((r: any) => {
      if (r?.result?.success == true) {
        this._toastService.show(ToastType.Success, 'Callback request sent', `Your advisor ${this.model.brokerName ?? ''} will call you back on ${currentUser?.profile?.phone_number} within 1 business day.`, 3000);
      }
    });
  }

  public requestWalkthrough() {
    const currentUser = this._authService.getCurrentUser();
    const isLoggedIn = currentUser !== null;
    const requestCallbackModal = this.modalService.show(RequestWalkthroughModalComponent, {
      keyboard: true,
      class: isLoggedIn ? '' : 'modal-xl',
      initialState: {
        property: this.model,
        isLoggedIn
      }
    })
    // requestCallbackModal.onHidden?.subscribe((r: any) => {
    //   if (r?.result?.success == true) {
    //     this._toastService.show(ToastType.Success, 'Callback request sent', `Your advisor ${this.model.brokerName} will call you back on within 1 business day.`, 3000);
    //   }
    // });
  }
}
