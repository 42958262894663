import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalService, ToastType } from '@indice/ng-components';
import { TranslocoDirective, TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { catchError, filter, Observable, of, Subject, tap } from 'rxjs';
import { NbgBranchesApiService } from 'src/app/services/nbg-branches.services';
import { ProblemDetails, PropertySummary } from 'src/app/services/portal-api.service';
import { NavigationButtonsComponent } from 'src/app/shared/components/navigation-buttons/navigation-buttons.component';
import { ProgressStepperComponent } from 'src/app/shared/components/progress-stepper/progress-stepper.component';
import { ToasterContainerComponent } from 'src/app/shared/components/toast/toast-container.component';
import { OnboardingExitModalComponent } from '../onboarding/onboarding-exit-modal/onboarding-exit-modal.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip.directive';
import { LoanEligibilityResultComponent, ILoanEligibilityResultModel } from './loan-eligibility-result/loan-eligibility-result.component';
import { ToasterService } from 'src/app/services/toaster.service';
import { ILoanEligibilityModel, LoanEligibilityModel } from './model';
import { DoughnutChartComponent } from "../../shared/components/loan-calulator/doughnut-chart/doughnut-chart.component";
import { LoanService, LoanValues } from 'src/app/services/loan.service';
import { LoanCalulatorComponent } from "../../shared/components/loan-calulator/loan-calulator.component";
import { ActivatedRoute } from '@angular/router';
import { Financialinfo, GuarantorInfo, Loaninfo, MortgageCalculatorRequest, MortgageCalculatorResponse, Personalinfo, ServicesLoansApiService } from 'src/app/core/services/loans-api-service';


@Component({
  selector: 'app-loan-eligibility-wizard',
  standalone: true,
  imports: [CommonModule, ProgressStepperComponent, NavigationButtonsComponent, TranslocoDirective, TranslocoPipe, ReactiveFormsModule, ToasterContainerComponent,
    NgxMaskDirective, TooltipDirective, LoanEligibilityResultComponent, DoughnutChartComponent, LoanCalulatorComponent],
  templateUrl: './loan-eligibility-wizard.component.html',
  styleUrl: './loan-eligibility-wizard.component.css',
  providers: [provideNgxMask()],
})
export class LoanEligibilityWizardComponent {
  protected stepsMap: { [key: string]: string } = {
    //values will be replaced by translation
    'loan': '1. YOUR LOAN',
    'household': '2. YOUR HOUSEHOLD',
    'guarantor': '3. YOUR GUARANTOR',
  };
  protected propertyId: string | undefined;
  protected propertyValue: number | undefined;
  protected loanForm: FormGroup;
  protected stepKeys: string[] = Object.keys(this.stepsMap);
  protected stepValues: string[] = [];
  protected activeStep: string = this.stepKeys[0];
  protected showSingleProperty = false;
  protected propertySummary: PropertySummary | undefined;
  protected thousandSeparator: string = '.';
  protected currentYear = new Date().getFullYear();
  protected loanResultData: ILoanEligibilityResultModel | any;
  protected loanFormData: ILoanEligibilityModel | any;
  protected showResultPage: boolean = false;


  constructor(
    private _modalService: ModalService,
    private _nbgBranchesService: NbgBranchesApiService,
    private _translocoService: TranslocoService,
    private _fb: FormBuilder,
    private _loanService: LoanService,
    private _route: ActivatedRoute,
    private _loansApiService: ServicesLoansApiService,
    @Inject(ToasterService) private _toastService: ToasterService
  ) {
    _translocoService.selectTranslateObject('loan-wizard').pipe(
      tap(t => {
        this.applyTranslations(t);
      })
    ).subscribe();

    this.propertyId = this._route.snapshot.queryParams["property"];
    this.propertyValue = this._route.snapshot.queryParams["propertyValue"];
    this.loanForm = this._fb.group({
      loan: this._fb.group({
        categoryId: ['', Validators.required],
        realEstateValue: ['', Validators.required],
        amount: ['', Validators.required],
        duration: ['', Validators.required],
        fixedInterestDuration: ['', Validators.required],
      }),
      household: this._fb.group({
        yearBorn: ['', [Validators.required, this.validYearValidator()]],
        marriageStatus: ['', Validators.required],
        children: ['', Validators.required],
        householdIncomePerYear: ['', Validators.required],
        taxPerYear: ['', Validators.required],
        householdDebt: ['', Validators.required],
        isNbgCustomer: [undefined, Validators.required],
      }),
      guarantor: this._fb.group({
        wantGuarantor: [undefined, Validators.required],
        yearBornGuarantor: [''],
        childrenGuarantor: [''],
        marriageStatusGuarantor: [''],
        householdIncomeGuarantor: [''],
        taxPerYearGuarantor: [''],
        householdDebtGuarantor: [''],
      }),
    });

    this.loanForm.get('guarantor.wantGuarantor')?.valueChanges.pipe(
      tap(t => this.setGuarantorValidators(t)),
    ).subscribe();

    this._loanService.currentLoanValues.pipe(
      tap((t: LoanValues) => {
        this.loanForm.get('loan.realEstateValue')?.setValue(t.loanCalculatorValues.propertyValue);
        this.loanForm.get('loan.amount')?.setValue(t.loanCalculatorValues.loanAmount);
        this.loanForm.get('loan.duration')?.setValue(t.loanCalculatorValues.loanDuration);
        this.loanForm.get('loan.fixedInterestDuration')?.setValue(t.loanCalculatorValues.loanFixedDuration);
      })
    ).subscribe();

    this._loanService.currentSelectedCategory.pipe(
      tap(t => this.loanForm.get('loan.categoryId')?.setValue(t)),
    ).subscribe();
  }

  private applyTranslations(t: any) {
    this.stepKeys.forEach((key, index) => {
      if (t?.stesps && t?.steps[key]) {
        this.stepsMap[key] = `${index + 1}. ${t?.steps[key]}` ?? this.stepsMap[key];
      }
    });
    this.stepValues = Object.values(this.stepsMap);
  }

  protected onNextClick(): void {
    if (!this.isFormValid()) {
      this.markAllAsTouched(this.loanForm.get(this.activeStep) as FormGroup);

      return;
    }

    const currnetIndex = this.stepKeys.indexOf(this.activeStep);
    const nextIndex = this.stepKeys[currnetIndex + 1];

    if (this.stepKeys.indexOf(nextIndex) > 0) {
      this.activeStep = this.stepKeys[currnetIndex + 1];

      return;
    }

    this.goToResults();
  }

  private goToResults(): void {
    const hasGuarantor: boolean = this.loanForm.get('guarantor.wantGuarantor')?.value;
    let body: MortgageCalculatorRequest = new MortgageCalculatorRequest({
      loanInfo: new Loaninfo({
        amount: this.loanForm.get('loan.amount')?.value,
        categoryId: this.loanForm.get('loan.categoryId')?.value,
        duration: this.loanForm.get('loan.duration')?.value,
        fixedInterestRate: this.loanForm.get('loan.fixedInterestDuration')?.value,
        realEstateValue: this.loanForm.get('loan.realEstateValue')?.value,
        interestRate: "Fixed",
      }),
      financialInfo: new Financialinfo({
        familyIncome: this.loanForm.get('household.householdIncomePerYear')?.value,
        totalDebt: this.loanForm.get('household.householdDebt')?.value,
        totalTaxValue: this.loanForm.get('household.taxPerYear')?.value,
        underageKidsNumber: this.loanForm.get('household.children')?.value,
      }),
      personalInfo: new Personalinfo({
        birthYear: this.loanForm.get('household.yearBorn')?.value,
        isCustomer: this.loanForm.get('household.isNbgCustomer')?.value,
        maritalStatus: this.loanForm.get('household.marriageStatus')?.value,
        underageKidsNumber: this.loanForm.get('household.children')?.value,
      }),
      hasGuarantor: hasGuarantor,
      guarantor: hasGuarantor ? new GuarantorInfo({
        birthYearOfGuarantor: this.loanForm.get('guarantor.yearBornGuarantor')?.value,
        debt: this.loanForm.get('guarantor.householdDebtGuarantor')?.value,
        income: this.loanForm.get('guarantor.householdIncomeGuarantor')?.value,
        taxValue: this.loanForm.get('guarantor.taxPerYearGuarantor')?.value,
        underageKidsNumber: this.loanForm.get('guarantor.childrenGuarantor')?.value,
        maritalStatus: this.loanForm.get('guarantor.marriageStatusGuarantor')?.value,
      }) : undefined,
    });

    this._loansApiService.checkEligibility(body).pipe(
      catchError((error: any | ProblemDetails) => {
        this._toastService.show(ToastType.Error, 'Failed calculate eligibility', error.detail || 'Unknown error', 10000);

        return of(undefined);
      }),
      filter((result): result is MortgageCalculatorResponse => !!result),
      tap((t: MortgageCalculatorResponse | undefined) => {
        this.loanResultData = { //TODO: this object must be response from checkEligibility
          isEligible: t?.isEligible,
        }
        this.loanFormData = new LoanEligibilityModel(this.loanForm);
        this.showResultPage = true;
      })
    ).subscribe();


  }

  protected onPrevClick(): void {
    const currnetIndex = this.stepKeys.indexOf(this.activeStep);
    this.activeStep = this.stepKeys[currnetIndex - 1];
  }
  private isFormValid(): boolean {
    const subForm = this.loanForm.get(this.activeStep) as FormGroup;
    const result = subForm === null || subForm.valid;

    return result;
  }
  protected hasError(controlName: string): boolean {
    const control = this.loanForm.get(controlName) as FormControl;
    const result = control.invalid && control.touched;
    return result
  }

  private markAllAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        this.markAllAsTouched(control);
      } else {
        control?.markAsTouched();
      }
    });
  }
  private setGuarantorValidators(haveGuarantor: string): void {
    const guarantorGroup = this.loanForm.get('guarantor') as FormGroup;

    if (!!haveGuarantor) {
      guarantorGroup.get('yearBornGuarantor')?.setValidators([Validators.required, this.validYearValidator()]);
      guarantorGroup.get('childrenGuarantor')?.setValidators([Validators.required]);
      guarantorGroup.get('marriageStatusGuarantor')?.setValidators([Validators.required]);
      guarantorGroup.get('householdIncomeGuarantor')?.setValidators([Validators.required]);
      guarantorGroup.get('taxPerYearGuarantor')?.setValidators([Validators.required]);
      guarantorGroup.get('householdDebtGuarantor')?.setValidators([Validators.required]);
    } else {
      guarantorGroup.get('yearBornGuarantor')?.clearValidators();
      guarantorGroup.get('childrenGuarantor')?.clearValidators();
      guarantorGroup.get('marriageStatusGuarantor')?.clearValidators();
      guarantorGroup.get('householdIncomeGuarantor')?.clearValidators();
      guarantorGroup.get('taxPerYearGuarantor')?.clearValidators();
      guarantorGroup.get('householdDebtGuarantor')?.clearValidators();
    }

    // Update validity after changing validators
    guarantorGroup.get('yearBornGuarantor')?.updateValueAndValidity();
    guarantorGroup.get('childrenGuarantor')?.updateValueAndValidity();
    guarantorGroup.get('marriageStatusGuarantor')?.updateValueAndValidity();
    guarantorGroup.get('householdIncomeGuarantor')?.updateValueAndValidity();
    guarantorGroup.get('taxPerYearGuarantor')?.updateValueAndValidity();
    guarantorGroup.get('householdDebtGuarantor')?.updateValueAndValidity();

  }
  private validYearValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const year = control.value;

      if (year >= 1900 && year <= this.currentYear - 16) {
        return null; // Valid year
      } else {
        return { invalidYear: true }; // Invalid year
      }
    };
  }

  protected openModalComponent(): void {
    const exitModal = this._modalService.show(OnboardingExitModalComponent, {
      animated: true,
      keyboard: true
    });
    exitModal.onHidden?.subscribe((response: any) => {

    });
  }
}