<div class="m-4" *transloco="let t; prefix: 'shared.autocomplete.search-property'">
    <div class="relative">

        <fieldset class="flex-1 flex flex-col gap-2 group/input">
            <div
                class="flex items-center text-gray-500 rounded-full bg-white border border-gray-300 py-1 px-1 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300 outline-none">
                <span class="flex justify-center items-center mr-2 ml-3">
                    <i *ngIf="showSearchIcon" class="icon-search text-gray-700 text-xl"></i>
                </span>
                <div class="flex flex-row flex-1 flex-wrap items-center">
                    <div *ngFor="let selectedItem of selectedItems"
                        class="flex items-center text-center bg-gray-100 border border-gray-300 px-3 rounded py-1 text-sm text-gray-950 mx-2">
                        <span>{{selectedItem.FormatedAddress}}</span>
                        <i class="text-gray-500 hover:text-gray-700 icon-close mx-1"
                            (click)="onItemRemove(selectedItem)">
                        </i>
                    </div>
                    <input (click)="showDropDown()" (clickOutside)="hideDropdown()"
                        class="outline-none flex-1 group-hover/input:bg-white transition-colors duration-300 mr-2"
                        placeholder="Search area or address" (keyup)="onInputKeyUp($event)" #searchInput>
                </div>

                <button class="btn-icon-primary-sm flex justify-center items-center" (click)="searchBarSearchClicked()">
                    <i class="icon-arrow-right text-xl"></i>
                </button>
            </div>
        </fieldset>
        <div *ngIf="showResults && (items || savedSearches || historySearches)"
            class="absolute top-[100%] translate-y-4 left-0 w-full rounded-xl px-4 py-4 shadow-2xl bg-gray-50 text-xl z-50">

            <button type="button" *ngFor="let item of items; let i = index"
                class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light py-[2px] px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-start min-w-[280px] w-full"
                (click)="onListItemSelected(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
                    [ngTemplateOutletContext]="{ $implicit: item }">
                </ng-container>
            </button>

            <!-- SEARCH NEARBY TBD -->
            <!-- <section *ngIf="!items">
                <button class="m-4" (click)="SearchNearby()"
                    class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light py-3 px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
                    <div class="flex items-center text-xl">
                        <i class="group-disabled/menu-item:text-gray-50 icon-location mr-4"></i>
                        <span class="bg-transparent group-disabled/menu-item:text-gray-50">Properties near me</span>
                    </div>
                </button>
            </section> -->

            <section *ngIf="savedSearches && savedSearches.length > 0">
                <div class="flex items-center my-4">
                    <span class="font-semibold text-xl text-gray-950">{{t('text.savedSearches')}}</span>
                    <div class="flex-grow border-t border-gray-300 ml-2"></div>
                </div>
                <div>
                    <button *ngFor="let search of savedSearches" (click)="savedSearchSelected(search)"
                        class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light py-3 px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
                        <div class="flex items-center text-xl">
                            <i class="group-disabled/menu-item:text-gray-50 mr-4" [ngClass]="search.icon"></i>
                            <span class="bg-transparent group-disabled/menu-item:text-gray-50">{{search.name}}</span>
                        </div>
                    </button>
                </div>
            </section>
            <section *ngIf="historySearches && historySearches.length > 0">
                <div class="flex items-center my-4">
                    <span class="font-semibold text-xl text-gray-950">{{t('text.recentSearches')}}</span>
                    <div class="flex-grow border-t border-gray-300 ml-2"></div>
                </div>
                <div>
                    <button *ngFor="let search of historySearches" (click)="savedSearchSelected(search)"
                        class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light py-3 px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
                        <div class="flex items-center text-xl">
                            <i class="group-disabled/menu-item:text-gray-50 mr-4 icon-clock"></i>
                            <span class="bg-transparent group-disabled/menu-item:text-gray-50">{{search.name}}</span>
                        </div>
                    </button>
                </div>
            </section>
        </div>
    </div>

</div>

<ng-template #defaultNoResultsTemplate let-searchTerm>
    <div class="flex items-center" *transloco="let t; prefix: 'shared.autocomplete.search-property'">
        <span class="bg-transparent group-disabled/menu-item:text-tone-06">{{t('text.noResultsFoundFor')}}
            <i>{{ searchTerm }}</i> 
        </span>
    </div>
</ng-template>
<ng-template #defaultItemTemplate let-item>
    <div class="flex items-center justify-start text-left">
        <i class="group-disabled/menu-item:text-tone-06 icon-location mr-4"></i>
        <span class="bg-transparent group-disabled/menu-item:text-tone-06">{{
            item.description
            }}</span>
    </div>
</ng-template>