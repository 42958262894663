<div (clickOutside)="closeDropdown()">
    <div class="flex flex-col gap-1 min-w-[115px]">
        <span *ngIf="title"
            class=" text-[10px] pb-2 text-gray-700 leading-none border-b border-gray-300 font-semibold">{{title}}</span>
        <button (click)="toggleDropdown()" class="btn-text flex items-center justify-between"> {{selectedLabel}} <i
                class="ml-3 text-xl text-primary-500"
                [ngClass]="showResults? 'icon-chevron-up':'icon-chevron-down'"></i></button>
    </div>

    <div *ngIf="showResults"
        class="absolute top-[100%] translate-y-4 left-0 rounded-xl px-4 py-4 shadow-2xl bg-gray-50 z-50 max-h-[300px] overflow-y-auto overflow-x-hidden">

        <button (click)="ClearFilter()"
            class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light border-b-[1px] border-gray-300 py-3 px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
            <div class="flex items-center ">
                <span class="bg-transparent group-disabled/menu-item:text-gray-50">{{allValuesLabel}}</span>
            </div>
        </button>

        <label *ngFor="let item of dropdownItems; let i = index" role="button" [attr.for]="'menu-item-radio-'+i"
            class="menu-item-radio small">
            <input type="checkbox" class="hidden" [attr.id]="'menu-item-radio-'+i" name="menu-item-radio-group1"
                (change)="ItemSelected(item)" [checked]="item.checked===true">
            <div class="menu-item-radio-content">
                <div>
                    <!-- <i class="group-disabled/menu-item:text-gray-50 mr-4" [ngClass]="item.icon"></i> -->
                    <span class="menu-item-radio-text">{{item.label}}</span>
                </div>
            </div>
        </label>
    </div>
</div>
