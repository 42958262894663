import { Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-popular-areas',
  standalone: true,
  imports: [TranslocoDirective],
  templateUrl: './popular-areas.component.html',
  styleUrl: './popular-areas.component.css'
})
export class PopularAreasComponent {

}
