<section class="pb-8 container mx-auto" *transloco="let t; prefix: 'find-property.sponsored-properties'">
    <div class="relative">

        <div class="flex-col md:flex-row flex rounded-lg overflow-hidden min-h-[13.5rem]">
            <div
                class="md:aspect-[400/248] md:basis-2/6 relative md:min-w-[300px] max-h-[400px] md:max-h-none overflow-hidden">
                <img src="../../../../assets/property-apartment.jpg" alt="Placeholder"
                    class="object-cover object-center w-full h-full img-disable-context">
                <div class="absolute inset-0 flex flex-col justify-between items-start overflow-hidden">
                    <div class="flex gap-2 p-3 flex-wrap">
                        <div
                            class="py-1 px-2 rounded bg-primary text-white text-[0.625rem] font-bold w-fit border-[1px] border-primary leading-tight whitespace-nowrap">
                            {{t('columns.auction')}}</div>
                        <div
                            class="py-1 px-2 rounded bg-dogwood text-white text-[0.625rem] font-bold w-fit border-[1px] border-dogwood leading-tight whitespace-nowrap">
                            {{t('columns.sponsored')}}</div>
                        <div
                            class="py-1 px-2 rounded bg-aqua text-white text-[0.625rem] font-bold w-fit border-[1px] border-aqua leading-tight whitespace-nowrap">
                            {{t('columns.preApproved')}}</div>
                        <div
                            class="py-1 px-2 rounded bg-accent-02 text-white text-[0.625rem] font-bold w-fit border-[1px] border-strawberry leading-tight whitespace-nowrap">
                            {{t('columns.hot')}}</div>
                    </div>
                    <div class="p-3">
                        <button class="btn-icon-secondary-sm bg-opacity-80 flex items-center justify-center"><i
                                class="icon-heart-line text-[20px]"></i></button>
                    </div>

                </div>
            </div>
            <div class="py-4 px-6 flex flex-col lg:flex-row bg-white md:basis-4/6 gap-4">
                <div class="flex flex-col basis-2/3 rfs:pr-4">
                    <h2 class="h2 text-black">Title</h2>
                    <h5 class="text-gray-900 text-lg font-light leading-tight">Athens-North</h5>
                    <h5 class="text-secondary text-lg font-medium">€100,000 <span
                            class="line-through text-[#003841]/20">€181,000</span>
                    </h5>
                    <p class="line-clamp-3 my-4 flex-1">Pleasant and open plan 3rd floor modern apartment in the heart
                        of
                        Kipséli
                        with
                        immediate
                        proximity to many
                        parks and
                        green areas. Family-friendly floorplan and west-facing terrace.</p>
                    <div class="flex gap-x-6 whitespace-nowrap flex-wrap">
                        <div class="flex gap-1">
                            <i class="icon-maisonette flex justify-center items-center"></i>Maisonette
                        </div>
                        <div class="flex gap-1">
                            <i class="icon-expand-arrows flex justify-center items-center"></i>250 m2
                        </div>
                        <div class="flex gap-1">
                            <i class="icon-bed flex justify-center items-center"></i>1 bedrooms
                        </div>
                        <div class="flex gap-1">
                            <i class="icon-bath flex justify-center items-center"></i>1 bathrooms
                        </div>
                    </div>
                </div>
                <div class="border-l border-l-gray-300"></div>
                <div
                    class="flex lg:flex-col lg:max-w-[200px] w-full gap-4 lg:ml-4 flex-wrap justify-center items-center">
                    <div class="flex gap-2 justify-between items-center w-full">
                        <div class="flex gap-2 justify-start items-center">
                            <i class="icon-fireplace text-xl"></i>
                            <p>Fireplace</p>
                        </div>
                        <i class="icon-check text-primary text-xl"></i>
                    </div>
                    <div class="flex gap-2 justify-between items-center w-full">
                        <div class="flex gap-2 justify-start items-center">
                            <i class="icon-fireplace text-xl"></i>
                            <p>Garden</p>
                        </div>
                        <i class="icon-check text-primary text-xl"></i>
                    </div>
                    <div class="flex gap-2 justify-between items-center w-full">
                        <div class="flex gap-2 justify-start items-center">
                            <i class="icon-fireplace text-xl"></i>
                            <p>Fireplace</p>
                        </div>
                        <i class="icon-check text-primary text-xl"></i>
                    </div>
                    <div class="flex gap-2 justify-between items-center w-full">
                        <div class="flex gap-2 justify-start items-center">
                            <i class="icon-fireplace text-xl"></i>
                            <p>Fireplace</p>
                        </div>
                        <i class="icon-check text-primary text-xl"></i>
                    </div>
                    <div class="flex gap-2 justify-between items-center w-full">
                        <div class="flex gap-2 justify-start items-center">
                            <i class="icon-fireplace text-xl"></i>
                            <p>Wooden floors</p>
                        </div>
                        <i class="icon-check text-primary text-xl"></i>
                    </div>
                </div>
            </div>
        </div>


        <div class="absolute left-0 top-0 bottom-0 flex items-center -translate-x-1/2">
            <button class="btn-icon-primary flex items-center justify-center">
                <i class="icon-arrow-left text-[20px]"></i>
            </button>
        </div>
        <div class="absolute right-0 top-0 bottom-0 flex items-center translate-x-1/2">
            <button class="btn-icon-primary flex items-center justify-center">
                <i class="icon-arrow-right text-[20px]"></i>
            </button>
        </div>
    </div>
</section>