import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, RouterModule } from '@angular/router';
import { PortalApiService, PropertyListingDetails, PropertyListingDetailsUpsertRequest } from 'src/app/services/portal-api.service';
import { BasicInformationComponent } from './basic-information/basic-information.component';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { ContentComponent } from './content/content.component';
import { FeaturesComponent } from './features/features.component';
import { ConstructionComponent } from './construction/construction.component';
import { OwnershipComponent } from './ownership/ownership.component';
import { SurroundingsComponent } from './surroundings/surroundings.component';
import { PhotosComponent } from './photos/photos.component';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-property-listing',
  standalone: true,
  imports: [RouterModule, CommonModule, BasicInformationComponent, ContentComponent, FeaturesComponent, ConstructionComponent, SurroundingsComponent, OwnershipComponent, PhotosComponent, TranslocoDirective],
  templateUrl: './property-listing.component.html',
  styleUrl: './property-listing.component.css'
})
export class PropertyListingComponent implements OnInit {
  public propertyCode: string | undefined;

  public activeTab: PropertyListingTab = PropertyListingTab.BasicInfo;
  public propertyListingTab = PropertyListingTab;
  public listingDetails: PropertyListingDetails = new PropertyListingDetails();
  public listingForm: FormGroup<PropertyListingForm> | undefined;

  public tabsComplete = {
    basicInfo: false,
    content: false,
    features: false,
    construction: false,
    surroundings: false,
    ownership: false,
    photos: false,

  }
  public basicInfoComplete = false;

  constructor(private readonly _activatedRoute: ActivatedRoute, private _portalApi: PortalApiService) {
    this._activatedRoute.params.subscribe((params: Params) => {
      this.propertyCode = params['propertyCode'];
    });
  }

  ngOnInit(): void {
    if (this.propertyCode) {
      this._portalApi.getListingDetails(this.propertyCode).subscribe((listingDetails: PropertyListingDetails) => {
        this.listingDetails = listingDetails;
        this.initFormGroup();
        this.listingForm?.patchValue(this.listingDetails);
        this.setProggress();
        this.initAutoSave();
      });
    }
  }

  public onNextTab(tab: PropertyListingTab) {
    this.activeTab = tab;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  public setPhotosProggress(isComplete: boolean) {
    this.tabsComplete.photos = isComplete;
  }

  private initFormGroup() {
    this.listingForm = new FormGroup(new PropertyListingForm());
  }

  private setProggress() {
    this.tabsComplete.basicInfo = (this.listingForm?.controls?.basicInfo.valid && this.listingForm?.controls.basicInfo.controls.address.valid) ?? false;
    this.tabsComplete.content = (this.listingForm?.controls?.content.valid) ?? false;
    this.tabsComplete.features = (this.listingForm?.controls?.features.valid) ?? false;
    this.tabsComplete.construction = (this.listingForm?.controls?.construction.valid) ?? false;
    this.tabsComplete.surroundings = (this.listingForm?.controls?.surroundings.valid) ?? false;
    this.tabsComplete.ownership = (this.listingForm?.controls.ownership.valid) ?? false;
  }

  private initAutoSave() {
    this.listingForm?.valueChanges
      .pipe(
        debounceTime(3000),
        distinctUntilChanged(),
        switchMap(() => {
          return this._portalApi.updateListingDetails(PropertyListingDetailsUpsertRequest.fromJS(this.listingForm?.getRawValue())).pipe(
            switchMap(() => {
              return this._portalApi.getListingDetails(this.propertyCode ?? '').pipe(
                map((res: PropertyListingDetails) => {
                  this.listingDetails = res;
                  this.setProggress();
                })
              )
            })
          )
        })
      ).subscribe();
  }
}

export class PropertyListingForm {
  propertyId: FormControl = new FormControl<string | null>(null);
  basicInfo: FormGroup<BasicInfoFormGroup> = new FormGroup(new BasicInfoFormGroup())
  content: FormGroup<ContentFormGroup> = new FormGroup(new ContentFormGroup())
  features: FormGroup<FeaturesFormGroup> = new FormGroup(new FeaturesFormGroup())
  construction: FormGroup<ConstructionFormGroup> = new FormGroup(new ConstructionFormGroup())
  surroundings: FormGroup<SurroundingsFormGroup> = new FormGroup(new SurroundingsFormGroup())
  ownership: FormGroup<OwnershipFormGroup> = new FormGroup(new OwnershipFormGroup())
}

export class BasicInfoFormGroup {
  askingPrice: FormControl = new FormControl<number | null>(null, Validators.required)
  lowestPrice: FormControl = new FormControl<number | null>(null, Validators.required)
  type: FormControl = new FormControl<string | null>(null, Validators.required)
  area: FormControl = new FormControl<number | null>(null, Validators.required)
  address: FormGroup<AddressFormGroup> = new FormGroup(new AddressFormGroup())
}

export class AddressFormGroup {
  country: FormControl = new FormControl<string | null>(null)
  prefecture: FormControl = new FormControl<string | null>(null, Validators.required)
  administrativeAreas: FormControl = new FormControl<string | null>(null)
  streetName: FormControl = new FormControl<string | null>(null, Validators.required)
  streetNumber: FormControl = new FormControl<string | null>(null, Validators.required)
  postalCode: FormControl = new FormControl<string | null>(null, Validators.required)
  geoPoint: FormControl = new FormControl<string | null>(null, Validators.required)
}

export class ContentFormGroup {
  titleEl: FormControl = new FormControl<string | null>(null, [Validators.maxLength(1024), Validators.required])
  shortDescriptionEl: FormControl = new FormControl<string | null>(null, Validators.required)
  titleEn: FormControl = new FormControl<string | null>(null, [Validators.maxLength(1024), Validators.required])
  shortDescriptionEn: FormControl = new FormControl<string | null>(null, Validators.required)
}

export class FeaturesFormGroup {
  numberOfRooms: FormControl = new FormControl<number | null>(null, Validators.required)
  numberOfBathrooms: FormControl = new FormControl<number | null>(null, Validators.required)
  numberOfWC: FormControl = new FormControl<number | null>(null, Validators.required)
  amenities: FormGroup<AmenitiesFormGroup> = new FormGroup(new AmenitiesFormGroup())
  parkingType: FormControl = new FormControl<string | null>(null)
  parkingSpace: FormControl = new FormControl<number | null>(null)
  storageAreaAmount: FormControl = new FormControl<number | null>(null)
  storageArea: FormControl = new FormControl<number | null>(null)
}

export class AmenitiesFormGroup {
  accessibilityForDisabled: FormControl = new FormControl<boolean | null>(null)
  internalStaircase: FormControl = new FormControl<boolean | null>(null)
  hasGarden: FormControl = new FormControl<boolean | null>(null)
  hasPool: FormControl = new FormControl<boolean | null>(null)
  hasElevator: FormControl = new FormControl<boolean | null>(null)
  hasAlarm: FormControl = new FormControl<boolean | null>(null)
  isAiry: FormControl = new FormControl<boolean | null>(null)
  sieves: FormControl = new FormControl<boolean | null>(null)
  hasSolarWaterHeating: FormControl = new FormControl<boolean | null>(null)
  hasBoiler: FormControl = new FormControl<boolean | null>(null)
  suitableForProfessionalUse: FormControl = new FormControl<boolean | null>(null)
  suitableForTouristUse: FormControl = new FormControl<boolean | null>(null)
  hasNightElectricCurrent: FormControl = new FormControl<boolean | null>(null)
  hasSafetyDoor: FormControl = new FormControl<boolean | null>(null)
  isBright: FormControl = new FormControl<boolean | null>(null)
  hasPlayroom: FormControl = new FormControl<boolean | null>(null)
  isPenthouse: FormControl = new FormControl<boolean | null>(null)
  hasStorageRoom: FormControl = new FormControl<boolean | null>(null)
  petsAreAllowed: FormControl = new FormControl<boolean | null>(null)
  hasTents: FormControl = new FormControl<boolean | null>(null)
  isFloorApartment: FormControl = new FormControl<boolean | null>(null)
}

export class ConstructionFormGroup {
  heatingType: FormControl = new FormControl<string | null>(null);
  heatingControl: FormControl = new FormControl<string | null>(null);
  energyClass: FormControl = new FormControl<string | null>(null);
  constructionYear: FormControl = new FormControl<number | null>(null, Validators.required);
  constructionMaterial: FormControl = new FormControl<string[]>([]);
  floorMaterial: FormControl = new FormControl<string[]>([]);
  condition: FormControl = new FormControl<string | null>(null, Validators.required);
}

export class SurroundingsFormGroup {
  plotArea: FormControl = new FormControl<number | null>(null);
  floor: FormControl = new FormControl<number | null>(null, Validators.required);
  view: FormControl = new FormControl<string[]>([]);
  orientation: FormControl = new FormControl<string | null>(null);
  surrounding: FormControl = new FormControl<string[]>([]);
  nearbyHighwaysEl: FormControl = new FormControl<string | null>(null)
  nearbyHighwaysEn: FormControl = new FormControl<string | null>(null)
}

export class OwnershipFormGroup {
  kaek: FormControl = new FormControl<string | null>(null);
  usageCategory: FormControl = new FormControl<string | null>(null, Validators.required);
  ownershipType: FormControl = new FormControl<string | null>(null);
  ownershipPercentage: FormControl = new FormControl<number | null>(null);
  availability: FormControl = new FormControl<string | null>(null);
}

export enum PropertyListingTab {
  BasicInfo = 1,
  Content = 2,
  Features = 3,
  Construction = 4,
  Surroundings = 5,
  Ownership = 6,
  Photos = 7
}
