<div class="flex-1" *transloco="let t; prefix: 'property-listing.photos'">
    <div class="flex flex-col rfs:gap-[72px]">
        <div class="flex flex-col gap-6">
            <h2 class="h2 text-black">{{'property-listing.tabs.photos' | transloco}}</h2>
        </div>

        <div class="flex flex-col">
            <h3 class="h3 text-black mb-6">{{t('headers.coverPhoto')}}</h3>
            <div class="flex flex-col lg:flex-row gap-6">
                <div class="flex flex-col gap-6">
                    <p class="text-gray-900">{{t('infoText.coverPhoto')}}</p>
                    <div class="flex flex-col md:flex-row gap-6">
                        <button class="btn-primary" (click)="coverPhotoUpload.click()">
                            {{t('buttons.uploadCoverPhoto')}}
                        </button>
                        <button (click)="openPhotoServiceModal()" class="btn-text">
                            {{t('buttons.getProfessionalPhotos')}}
                        </button>
                    </div>
                </div>
                <div class="flex flex-col gap-2 max-w-[333px]">
                    <form action="">
                        <div class="border border-dashed border-gray-500 rounded-medium px-4 py-3 transition duration-500"
                            (dragover)="onDragOver($event); coverDragover = true;" (dragleave)="coverDragover = false;"
                            (drop)="onCoverDropSuccess($event)"
                            [ngClass]="coverDragover ? 'bg-primary scale-125': 'bg-gray-100'">
                            <div class="flex flex-col gap-4 items-center justify-center">
                                <i class="icon-image text-gray-700 text-[40px]"></i>
                                <div class="flex flex-col gap-2">
                                    <p class="text-black font-semibold">{{t('infoText.dropImage')}}</p>
                                    <div class="flex items-center justify-center gap-1">
                                        <span class="text-gray-900">{{t('infoText.or')}}</span>
                                        <label for="cover-photo-upload"
                                            class="custom-file-upload">{{t('infoText.chooseFile')}}</label>
                                        <input #coverPhotoUpload id="cover-photo-upload" type="file" [accept]="'.jpg'"
                                            (change)="onCoverPhotoSelected($event)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="coverPhoto.previewSrc" class="flex flex-col gap-2">
                        <div class="w-[210px] h-[138px]">
                            <picture>
                                <source [srcset]="coverPhoto.previewSrc" type="image/webp">
                                <source [srcset]="coverPhoto.fallbackSrc" type="image/jpeg">
                                <img class="rounded-medium w-full h-full object-center object-cover pointer-events-none img-disable-context"
                                    [src]="coverPhoto.fallbackSrc" alt="cover-photo">
                            </picture>
                        </div>
                        <div class="flex items-start justify-between text-center">
                            <button class="btn-text underline" (click)="onTagAdd(coverPhoto, false)">
                                {{ coverPhoto.tag ? t('buttons.editTags') : t('buttons.addTags') }}
                            </button>
                            <div *ngIf="coverPhoto.tag">{{coverPhoto.tag}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-6">
            <h3 class="h3 text-black">{{t('headers.otherPhotos')}}</h3>
            <p class="text-gray-900 pb-2">
                {{t('infoText.otherPhotos')}}
            </p>
            <div class="flex flex-wrap gap-2">
                <div class="w-[210px]">
                    <form action="">
                        <div class="bg-gray-100 border border-dashed border-gray-500 rounded-medium px-4 py-3 transition duration-500"
                            (dragover)="onDragOver($event); secondaryDragover = true;"
                            (dragleave)="secondaryDragover = false;" (drop)="onSecondaryDropSuccess($event)"
                            [ngClass]="secondaryDragover ? 'bg-primary scale-125': 'bg-gray-100'">
                            <div class="flex flex-col gap-4 items-center justify-center">
                                <i class="icon-image text-gray-700 text-[40px]"></i>
                                <div class="flex flex-col gap-2">
                                    <p class="text-black font-semibold">{{t('infoText.dropImage')}}</p>
                                    <div class="flex items-center justify-center gap-1">
                                        <span class="text-gray-900">{{t('infoText.or')}}</span>
                                        <label for="secondary-photos-upload"
                                            class="custom-file-upload">{{t('infoText.chooseFile')}}</label>
                                        <input #secondaryPhotosUpload id="secondary-photos-upload" type="file"
                                            [multiple]="true" [accept]="'.jpg'"
                                            (change)="onSecondaryPhotosSelected($event)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="flex flex-wrap gap-2" cdkDropList [cdkDropListOrientation]="'horizontal'"
                    [cdkDropListData]="secondaryPhotos" (cdkDropListDropped)="onSecondaryPhotoMove($event)">
                    <div cdkDrag [cdkDragData]="secondaryPhoto" *ngFor="let secondaryPhoto of secondaryPhotos;"
                        class="flex flex-col gap-2">
                        <div class="w-[210px] h-[138px] hover:scale-125 transition duration-500 cursor-pointer">
                            <picture>
                                <source [srcset]="secondaryPhoto.previewSrc" type="image/webp">
                                <source [srcset]="secondaryPhoto.fallbackSrc" type="image/jpeg">
                                <img class="rounded-medium w-full h-full object-center object-cover pointer-events-none img-disable-context"
                                    [src]="secondaryPhoto.fallbackSrc" alt="secondary-photo">
                            </picture>
                        </div>
                        <div class="flex items-start justify-between text-center">
                            <button class="btn-text underline" (click)="onTagAdd(secondaryPhoto, true)">
                                {{ coverPhoto.tag ? t('buttons.editTags') : t('buttons.addTags') }}
                            </button>
                            <div *ngIf="secondaryPhoto.tag">{{secondaryPhoto.tag}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pt-2 flex flex-col md:flex-row gap-6">
                <button class="btn-primary" (click)="secondaryPhotosUpload.click()">
                    {{t('buttons.uploadPhotos')}}
                </button>
                <button (click)="openPhotoServiceModal()" class="btn-text">
                    {{t('buttons.getProfessionalPhotos')}}
                </button>
            </div>
        </div>

        <app-advisor-callback [advisor]="advisor"></app-advisor-callback>

        <div class="border-t-2 pt-7 flex justify-between">
            <button (click)="navigateToTab(propertyListingTab.Ownership)"
                class="text-secondary-900 flex items-center gap-2 font-semibold hover:text-success transition-color">
                <i class="icon-arrow-left text-lg"></i>
                {{'property-listing.tabs.ownership' | transloco}}
            </button>
            <a class="btn-primary" [routerLink]="['/my-profile/selling']">
                {{t('buttons.sendForReview')}}
            </a>
        </div>
    </div>
</div>

<lib-toaster-container></lib-toaster-container>