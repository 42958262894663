<div class="flex-row flex rounded-lg overflow-hidden relative cursor-pointer h-full"
    [ngClass]="{'border border-gray-500': withBorder}" *transloco="let t; prefix: 'shared.property-card-item'"
    (click)="propertySummary?.isSold ? null : onPropertyClick(propertySummary?.id)">
    <div *ngIf="propertySummary?.isSold"
        class="bg-primary-500 absolute w-full h-full z-10 opacity-90 text-secondary-900 flex flex-col gap-4 justify-center items-center cursor-default">
        <p class="text-xl font-semibold ">{{t('sold.title')}}</p>
        <span class="text-base ">{{t('sold.price')}} $125.000</span>
        <button class="text-base font-semibold" (click)="toggleFavorite($event)">{{t('sold.button')}}</button>
    </div>
    <div class="aspect-[270/180] relative overflow-hidden flex-1 max-w-[263px]">
        <img [src]="propertySummary?.defaultPhoto?.uri ?? '../../../../../../assets/property-apartment.jpg'"
            alt="PropertyImage" class="object-cover object-center w-full h-full img-disable-context">
        <div class="absolute inset-0 flex flex-col justify-between items-start overflow-hidden">
            <div class="flex gap-2 p-3 flex-wrap">
                <div *ngIf="propertySummary?.auctionRound"
                    class="py-1 px-2 rounded bg-primary text-white text-[0.625rem] font-bold w-fit border-[1px] border-primary leading-tight whitespace-nowrap">
                    {{t('tags.auction')}}</div>
                <div *ngIf="propertySummary?.isSponsored"
                    class="py-1 px-2 rounded bg-dogwood text-white text-[0.625rem] font-bold w-fit border-[1px] border-dogwood leading-tight whitespace-nowrap">
                    {{t('tags.sponsored')}}</div>
                <div *ngIf="propertySummary?.preApproved"
                    class="py-1 px-2 rounded bg-aqua text-white text-[0.625rem] font-bold w-fit border-[1px] border-aqua leading-tight whitespace-nowrap">
                    {{t('tags.preApproved')}}</div>
                <div *ngIf="propertySummary?.isHot"
                    class="py-1 px-2 rounded bg-accent-02 text-white text-[0.625rem] font-bold w-fit border-[1px] border-strawberry leading-tight whitespace-nowrap">
                    {{t('tags.isHot')}}</div>
            </div>
            <div
                class="w-8 h-8 rounded-full bg-white mb-3 ml-2 flex items-center justify-center border-2 border-gray-500 opacity-30 hover:bg-primary-50 hover:opacity-40 transition-all">
                <button (click)="toggleFavorite($event)"
                    class="btn-icon-secondary-sm flex items-center justify-center"><i
                        [ngClass]="propertySummary?.isFavorite ? 'icon-heart-filled text-primary' : 'icon-heart-line'"></i></button>
            </div>
        </div>
    </div>
    <div class="py-4 pr-2 pl-6 flex flex-col bg-white justify-between flex-1">
        <div>
            <h4 *ngIf="propertySummary?.title" class="text-black h4">{{ propertySummary?.title }}</h4>
            <h5 *ngIf="propertySummary?.address?.prefecture" class="text-lg font-light leading-tight">{{
                propertySummary?.address?.prefecture }}
            </h5>
            <h5 *ngIf="propertySummary?.price" class="text-secondary text-lg font-medium">€{{ propertySummary?.price|
                number }}
                <span *ngIf="propertySummary?.previousPrice" class="line-through text-[#003841]/20">€{{
                    propertySummary?.previousPrice|
                    number }}</span>
            </h5>
        </div>
        <div class="flex gap-x-6 gap-y-0 whitespace-nowrap flex-wrap">
            <div class="flex gap-1" *ngIf="propertySummary?.type">
                <i [ngClass]="'icon-' + (propertySummary?.type?.toLowerCase() || '')"
                    class="flex justify-center items-center"></i>{{
                'shared.enum.propertyType.' + (propertySummary?.type ?? 'Unknown') | transloco }}
            </div>
            <div class="flex gap-1" *ngIf="propertySummary?.propertyArea">
                <i class="icon-expand-arrows flex justify-center items-center"></i>{{ propertySummary?.propertyArea
                |
                number }}
                m&#178;
            </div>
            <div class="flex gap-1" *ngIf="propertySummary?.numberOfRooms">
                <i class="icon-bed flex justify-center items-center"></i>{{ propertySummary?.numberOfRooms }}
                {{t('label.bedrooms')}}
            </div>

            <div class="flex gap-1"
                *ngIf="propertySummary?.numberOfBathrooms && propertySummary?.numberOfBathrooms != 0">
                <i class="icon-bath flex justify-center items-center"></i>{{
                propertySummary?.numberOfBathrooms }} {{ propertySummary!.numberOfBathrooms! > 1 ?
                t('label.bathrooms')
                : t('label.bathroom')}}
            </div>

            <div class="flex gap-1" *ngIf="propertySummary?.auctionRound && propertySummary?.auctionRound != 0">
                <i class="icon-maisonette flex justify-center items-center"></i>@switch (propertySummary?.auctionRound)
                {
                @case (1) { {{t('auctionRound.first')}} }
                @case (2) { {{t('auctionRound.second')}} }
                @case (3) { {{t('auctionRound.third')}} }
                @case (4) { {{t('auctionRound.fourth')}} }
                @default {}}
                round
            </div>

            <div class="flex gap-1" *ngIf="propertySummary?.auctionDateTime">
                <i class="icon-bath flex justify-center items-center"></i>{{ propertySummary?.auctionDateTime | date
                }}
            </div>

        </div>
    </div>
</div>