import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { GridListCardsComponent } from './grid-list-cards/grid-list-cards.component';
import { SponsoredPropertiesComponent } from './sponsored-properties/sponsored-properties.component';
import { PopularAreasComponent } from './popular-areas/popular-areas.component';
import { PropertyTagFiltersComponent } from './property-tag-filters/property-tag-filters.component';
import { SearchPropertyComponent } from 'src/app/shared/components/autocomplete/search-property/search-property.component';
import { GeocodeService } from 'src/app/core/services/geocode.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PropertyListFilter, PropertyType } from 'src/app/services/portal-api.service';
import { FilterDropdownComponent, FilterDropdownItem } from 'src/app/features/find-property/filter-dropdown/filter-dropdown.component';
import { PropertyListingService } from 'src/app/services/property.service';
import { property } from 'lodash';
import { RangeDropdownComponent, RangeDropdownItem } from './range-dropdown/range-dropdown.component';
import { AdvancedFiltersDrawerComponent } from './advanced-filters-drawer/advanced-filters-drawer.component';
import { Subject } from 'rxjs';
import { SearchHistoryService } from 'src/app/services/search-history.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-find-property',
  standalone: true,
  imports: [CommonModule,
    GridListCardsComponent,
    SponsoredPropertiesComponent,
    PopularAreasComponent,
    PropertyTagFiltersComponent,
    SearchPropertyComponent,
    FilterDropdownComponent,
    RangeDropdownComponent,
    AdvancedFiltersDrawerComponent,
    TranslocoDirective
  ],
  templateUrl: './find-property.component.html',
  styleUrl: './find-property.component.css'
})
export class FindPropertyComponent {

  toggleAdvancedFilters$: Subject<void> = new Subject();
  advancedFilterOn: boolean = false;
  searchFilter: PropertyListFilter | undefined
  propertyTypes: FilterDropdownItem[] = []
  showPopularAreas: boolean = true;
  selectedType: PropertyType[] | undefined
  selectedPriceRange: RangeDropdownItem | undefined
  selectedSizeRange: RangeDropdownItem | undefined
  isloggedIn: boolean = false;
  priceRanges: RangeDropdownItem[] = [
    {
      from: 30000,
      to: 45000,
      label: "30,000 - 45,000"
    },
    {
      from: 45000,
      to: 60000,
      label: "45,000 - 60,000"
    },
    {
      from: 60000,
      to: 75000,
      label: "60,000 - 75,000"
    },
    {
      from: 75000,
      to: 100000,
      label: "75,000 - 100,000"
    },
    {
      from: 100000,
      to: 200000,
      label: "100,000 - 200,000"
    },
    {
      from: 200000,
      to: 300000,
      label: "200,000 - 300,000"
    },
    {
      from: 300000,
      label: "300,000+"
    }
  ]

  sizeRanges: RangeDropdownItem[] = [
    {
      from: 20,
      to: 40,
      label: "20 - 40"
    },
    {
      from: 40,
      to: 60,
      label: "40 - 60"
    },
    {
      from: 60,
      to: 80,
      label: "60 - 80"
    },
    {
      from: 80,
      to: 100,
      label: "80 - 100"
    },
    {
      from: 100,
      to: 150,
      label: "100 - 150"
    },
    {
      from: 150,
      to: 200,
      label: "150 - 200"
    },
    {
      from: 200,
      label: "200+"
    }
  ]

  constructor(private _geocodeService: GeocodeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private propertyListingService: PropertyListingService,
    private searchHistoryService: SearchHistoryService,
    private authService: AuthService
  ) {
    this.authService.isLoggedIn().subscribe(x => this.isloggedIn = x);
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.searchFilter = this.propertyListingService.getSearchFiltersFromUrl(params);
      this.hasUrlParams(params)

      //Type filter
      if (this.searchFilter.type && this.searchFilter.type.length > 0) {
        this.selectedType = this.searchFilter.type
      }

      //price filter
      if (this.searchFilter.minPrice || this.searchFilter.maxPrice) {
        let label = `${this.searchFilter.minPrice?.toString() ?? ''} - ${this.searchFilter.maxPrice?.toString() ?? ''}`;
        this.selectedPriceRange = {
          from: this.searchFilter.minPrice,
          to: this.searchFilter.maxPrice,
          label
        }
      }

      //size filter
      if (this.searchFilter.minSqMeters || this.searchFilter.maxSqMeters) {
        let label = `${this.searchFilter.minSqMeters?.toString() ?? ''} - ${this.searchFilter.maxSqMeters?.toString() ?? ''}`;
        this.selectedSizeRange = {
          from: this.searchFilter.minSqMeters,
          to: this.searchFilter.maxSqMeters,
          label
        }
      }

    })


    this.propertyTypes = Object.values(PropertyType).filter(x => x != PropertyType.Unknown).map(type => ({
      label: this.formatKey(type),
      key: type,
      icon: this.propertyTypeIcons(type)
    }));
  }


  private hasUrlParams(params: ParamMap) {
    const excludeKey = 'searchTerm';
    const keys = params.keys.filter(key => key !== excludeKey);
    const noFilter = keys.reduce((acc, key) => {
      const value = params.get(key);
      return acc && (value === null || value === '');
    }, true);
    this.showPopularAreas = noFilter;
    if (!noFilter && this.isloggedIn) {
      this.searchHistoryService.saveHistory(params);
    }
  }

  private formatKey(key: string): string {
    const formattedKey = key
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase();

    // Capitalize the first letter of the entire phrase
    return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
  }
  public searchWithLocation(placesToSearch: any[]) {
    if (placesToSearch.length === 0) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { area: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }
    let encodedMessages: string[] = [];
    let placesTerms: string[] = []
    placesToSearch.forEach((place) => {
      let encodedMessage = this._geocodeService.protoEncodeGeoSearch(place);
      encodedMessages.push(btoa(String.fromCharCode(...encodedMessage)));
      placesTerms.push(`${place.PlaceId}~${place.FormatedAddress}`);
    });
    if (encodedMessages.length > 0) {
      let encodedAreaQueryParam = encodedMessages.join('!');
      let filter: PropertyListFilter = new PropertyListFilter({
        area: encodedAreaQueryParam,
        searchTerm: placesTerms.join("!")
      })
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { ...filter },
          queryParamsHandling: 'merge'
        }
      );

    }

  }
  public selectSearchItem(placesToSelect: any[]) {
    let placesTerms: string[] = [];
    if (!placesToSelect || placesToSelect.length == 0) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { searchTerm: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }
    placesToSelect.forEach((place) => {
      placesTerms.push(`${place.PlaceId}~${place.FormatedAddress}`);
    });
    placesTerms.sort();
    let filter = new PropertyListFilter({
      searchTerm: placesTerms.join("!")
    })
    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
  }
  searchWithTypeFilter(propertyType: any | undefined) {
    if (!propertyType || propertyType.length == 0) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { type: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }

    let filter: PropertyListFilter = new PropertyListFilter({
      type: propertyType.join(","),
    })
    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
  }
  searchWithPriceFilter(range: RangeDropdownItem | undefined) {
    if (!range) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { minPrice: undefined, maxPrice: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }

    let filter: PropertyListFilter = new PropertyListFilter({
      minPrice: range.from,
      maxPrice: range.to
    })

    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
  }
  searchWithSizeFilter(range: RangeDropdownItem | undefined) {
    if (!range) {
      this.router.navigate(
        ['/properties'],
        {
          queryParams: { minSqMeters: undefined, maxSqMeters: undefined },
          queryParamsHandling: 'merge'
        }
      );
      return;
    }

    let filter: PropertyListFilter = new PropertyListFilter({
      minSqMeters: range.from,
      maxSqMeters: range.to
    })

    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...filter },
        queryParamsHandling: 'merge'
      }
    );
  }
  advancedSearchHidden(val: boolean) {
    this.advancedFilterOn = false;
    if (val) {
      document.documentElement.className = document.documentElement.className.replaceAll(" overflow-hidden", "")
    }
  }
  toggleAdvancedFilers() {
    this.toggleAdvancedFilters$.next();
    this.advancedFilterOn = !this.advancedFilterOn
    if (this.advancedFilterOn) {
      document.documentElement.className += " overflow-hidden"
    }
    else {
    }
  }
  searchWithAdvancedFilters(filter: PropertyListFilter) {
    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...this.convertArrayValues(filter) },
        queryParamsHandling: 'merge'
      }
    );
  }
  private propertyTypeIcons(propertyType: PropertyType | undefined): string | undefined {
    const defaultIcon = "icon-Apartment"

    switch (propertyType) {
      case PropertyType.Apartment:
        return "icon-Apartment";
      case PropertyType.Maisonette:
        return "icon-maisonette"
      case PropertyType.Business:
        return "icon-briefcase"
      case PropertyType.Villa:
        return "icon-villas"
      default:
        return defaultIcon
    }
  }
  private convertArrayValues(obj: { [key: string]: any }): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (Array.isArray(value)) {
          result[key] = (value as any[]).join(',');
        } else {
          result[key] = value;
        }
      }
    }

    return result;
  }
}

