import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-floor-plan-section',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './floor-plan-section.component.html',
  styleUrl: './floor-plan-section.component.css'
})
export class FloorPlanSectionComponent {
  @Input() address: string | any;
  @Input() plotArea: number | any;
  @Input() numOfRooms: number | any;

}
