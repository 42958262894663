import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'progress-stepper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progress-stepper.component.html',
  styleUrl: './progress-stepper.component.css'
})
export class ProgressStepperComponent {
  @Input() StepNo: number = 1;
  @Input() Steps: string[] = [];
  ngOnInit() {
  }

}


