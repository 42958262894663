import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Modal, ModalOptions } from '@indice/ng-components';
import { AuthService } from 'src/app/core/services/auth.service';
import { PropertyDetails } from 'src/app/services/portal-api.service';
import { RequestsUnauthorizedComponent } from '../requests-unauthorized/requests-unauthorized.component';
import * as environment from '../../../core/appSettings';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-request-callback-modal',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, RequestsUnauthorizedComponent, TranslocoDirective, TranslocoPipe],
  templateUrl: './request-callback-modal.component.html'
})
export class RequestCallbackModalComponent {

  @Input()
  property: PropertyDetails | undefined;

  @Input()
  isLoggedIn: boolean = false;

  form = new UntypedFormGroup({
    message: new UntypedFormControl(''),
  });

  constructor(public modalRef: Modal, @Inject(ModalOptions) private _options: ModalOptions, private _authService: AuthService) { }

  ngOnInit(): void {
    this.property = (this._options?.initialState?.['property'] as PropertyDetails);
    this.isLoggedIn = (this._options?.initialState?.['isLoggedIn'] as boolean);
  }

  close = () => this.modalRef.hide({ name: this.form.get('message')?.value });

  sendRequest = () => {
    this.modalRef.hide({ name: this.form.get('message')?.value, success: true });
  }

  login() {
    this._authService.signinRedirect(`/properties/${this.property?.id}`);
  }

  signUp() {
    var authBasePath = environment.appSettings.auth_settings.authority;
    var portalAuthRedirect = environment.appSettings.auth_settings.post_logout_redirect_uri;
    window.location.href=`${authBasePath}/on-boarding/get-started?returnurl=${portalAuthRedirect}/properties/${this.property?.id}`;
    ///home?action=walkthrough&propertyId=${this.property?.id}&date=${this.selectedDate?.date}&from=${this.selectedDate?.fromTime}&to=${this.selectedDate?.toTime}
  }
}
