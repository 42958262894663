import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-point-of-interest',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './point-of-interest.component.html',
  styleUrl: './point-of-interest.component.css'
})
export class PointOfInterestComponent {
  @Input() lat: number = 0;
  @Input() lon: number = 0;
  @Input() circleColor: string = "";
  @Input() icon: string = "";
  @Input() distanceFromProperty: number = 0;
  @Input() isPropertyPin: boolean = false;
  @Input() hypertitle: string = "";
  @Input() subtitle: string = "";


}
