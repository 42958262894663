import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-paragraph-section',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './paragraph-section.component.html',
  styleUrl: './paragraph-section.component.css'
})
export class ParagraphSectionComponent {
  @Input() headerText: string | any;
  @Input() bodyText: string | any;

  protected isCollapsed: boolean = true;

  toggleCollapse(event: MouseEvent) {
    this.isCollapsed = !this.isCollapsed;
    let buttonNode = event.target as Node;
    let scrollTo = buttonNode.parentElement as Element;
    scrollTo?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
