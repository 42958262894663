<div class="border border-teal px-4 py-3 rounded-small" *transloco="let t; prefix: 'property-details.map-area-section.point-of-interest'">
    <div class="flex justify-between">
        <div class="flex flex-1 gap-2 items-center">
            <div
                class="rounded-full bg-{{circleColor}} flex justify-center items-center text-tone-01 h-[42px] w-full max-w-[42px]">
                <i class="icon-{{icon}} text-2xl"></i>
            </div>
            <!-- <div class="flex flex-col text-{{text-color}}"> -->
            <div class="flex flex-col">
                <p *ngIf="isPropertyPin" class="font-semibold">{{t('propertyLocationText')}}</p>
                <ng-container *ngIf="!isPropertyPin">
                    <p class="font-semibold text-[0.750rem]">
                        {{hypertitle}}
                    </p>
                    <p>
                        {{subtitle}}
                    </p>
                </ng-container>
            </div>
        </div>
        <div *ngIf="distanceFromProperty" class="flex items-end font-semibold text-[0.750rem] text-secondary-dark">
            {{distanceFromProperty}} m
        </div>
    </div>
</div>