import { Inject, Injectable, InjectionToken } from "@angular/core";
import { CmsApiService, IApiContentResponseModel } from "./cms-api.service";
import { Observable } from "rxjs";

export const CMS_API_KEY = new InjectionToken<string>('CMS_API_KEY');

@Injectable({
    providedIn: 'root'
})
export class CmsService {
    api_Key?: string | null;
    constructor(private _cmsApi: CmsApiService, @Inject(CMS_API_KEY) api_Key?: string) {
        this.api_Key = api_Key;
    }

    public getContentItemByPath(
        path: string, expand?: string | undefined, fields?: string | undefined, accept_Language?: string | undefined,
        preview?: boolean | undefined, start_Item?: string | undefined
    ): Observable<IApiContentResponseModel> {
        let result = this._cmsApi.getContentItemByPath2_0(path, expand, fields, accept_Language, this.api_Key ?? undefined, preview, start_Item);

        return result;
    }
}