import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ResolveEnd, Router, RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule, CommonModule, TranslocoDirective],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  onboardingNav: boolean = false;

  constructor(private router: Router) {
  }
  ngOnInit(): void {
    this.router.events.subscribe((routerData) => {
      if (routerData instanceof ResolveEnd) {
        if (routerData.url.includes("welcome") || routerData.url.includes("on-boarding") || routerData.url.includes("property-upload-wizard")) {
          this.onboardingNav = true;
        }
        else {
          this.onboardingNav = false
        }
      }
    });
  }

}
