<div class="py-6 mx-auto max-w-[1056px] flex flex-col gap-6 w-full">
    <h3 class="h2 text-black">{{'shared.headers.financeThisProperty' | transloco}}</h3>
    <div>
        <form>
            <div class="flex flex-row gap-12">
                <app-loan-calulator [propertyValue]="propertyValue"></app-loan-calulator>
                <div class="flex items-center justify-center">
                    <app-doughnut-chart [showEstimatedFees]="true"></app-doughnut-chart>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="isLoggedIn" class="flex flex-col md:flex-row gap-6 items-center">
        <a class="btn-primary" [routerLink]="['/loan-application']"
            [queryParams]="{property: propertyId}">{{'shared.anchors.loanApplication'
            |
            transloco}}</a>
        <a class="text-secondary flex flex-row gap-x-1 items-center" [routerLink]="['/loan-eligibility']"
            [queryParams]="{property: propertyId, propertyValue: propertyValue}">
            <span class="underline">{{'shared.anchors.loanEligibility' | transloco}}</span>
            <i class="icon-arrow-right text-[15px]"></i>
        </a>
    </div>
</div>