<div *ngIf="!closed" [ngClass]="{
  'opacity-100 translate-y-0': !closed,
  'opacity-0 translate-y-4': closed 
}" class="z-50 fixed top-24 bg-gray-50 w-[60%] left-[20%] rounded-medium p-6 shadow-[0_4px_18px_0_rgba(0,0,0,0.17)] border transform transition-opacity transition-transform duration-500 ease-in-out"
    [ngClass]="{'border-success': toast?.type === toastType.Success, 'border-error': toast?.type === toastType.Error  }">
    <div class="flex justify-between gap-4 items-center">
        <div class="flex gap-5">
            <i *ngIf="toast?.type === toastType.Success" class="icon-check-circle text-success text-xl"></i>
            <i *ngIf="toast?.type === toastType.Error" class="icon-close text-error text-xl"></i>
            <div>
                <h4 class="text-sm">{{toast?.title}}</h4>
                <p class="text-sm"> {{toast?.body}}</p>
            </div>
        </div>
        <button (click)="closeToast(i)" class="flex items-end"><i class="icon-close text-gray-700 text-xl"></i></button>
    </div>
</div>

<!-- 

<div aria-live="assertive" [ngClass]="closed ? 'toast-closed': 'toast z-50'"
    [ngStyle]="isMobile ? { 'margin-bottom.px': i * 85 } : {'margin-top.px': i * 85 }">
    <div class="toast-container">
        <div class="toast-pill">
            <div class="toast-context-padding">
                <div class="toast-context" [ngSwitch]="toast?.type">
                    <div class="toast-icon" *ngSwitchCase="'info'">
                        <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="toast-icon" *ngSwitchCase="'success'">
                        <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div class="toast-icon" *ngSwitchCase="'error'">
                        <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="toast-icon" *ngSwitchCase="'warning'">
                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="toast-icon" *ngSwitchDefault>
                        <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>

                    <div class="ml-3 w-0 flex-1">
                        <p class="toast-title" *ngIf="toast?.title">
                            {{toast?.title}}
                        </p>
                        <p class="toast-body">
                            {{toast?.body}}
                        </p>
                    </div>
                    <div class="toast-close-icon">
                        <button class="toast-close-button" (click)="closeToast(i)">
                            <span class="sr-only">Close</span>
                            
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->