import { AfterViewInit, Component, Input } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { LoanCategories, LoanService, LoanValues } from 'src/app/services/loan.service';
import { CommonModule, DecimalPipe } from '@angular/common';
import { tap, pipe } from 'rxjs';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-doughnut-chart',
  standalone: true,
  imports: [CommonModule, DecimalPipe, TranslocoDirective],
  templateUrl: './doughnut-chart.component.html',
  styleUrl: './doughnut-chart.component.css'
})
export class DoughnutChartComponent implements AfterViewInit {
  public fixedDuration = 0;
  public variableDuration = 0;
  public chart: any;

  @Input()
  public showEstimatedFees: boolean = false;
  public totalEstimatedFees = 0;

  public loanValues: LoanValues | undefined;
  public mortgageLoanSelected = false;

  constructor(private _loanService: LoanService) { }

  ngAfterViewInit(): void {
    if (this.showEstimatedFees) {
      this._loanService.calculateEstimatedFees();
    }

    this._loanService.currentLoanValues.pipe(
      tap((res) => {
        this.loanValues = res;

        if (!this.chart) {
          this.createChart();
        } else {
          this.setDurations();
          this.chart.data.datasets[0].data = [this.fixedDuration, this.variableDuration];
          this.chart.update();
        }
      })
    ).subscribe();

    this._loanService.currentEstimatedFees.pipe(
      tap((res) => {
        if (res) {
          this.totalEstimatedFees = 0;
          let property: keyof typeof res;
          for (property in res) {
            this.totalEstimatedFees += res[property]?.fee ?? 0;
          }
          this.showEstimatedFees = true;
        }
      })
    ).subscribe();

    this._loanService.currentSelectedCategory.pipe(
      tap((res) => {
        this.mortgageLoanSelected = LoanCategories[0].id === res;
      })
    ).subscribe();
  }

  private setDurations() {
    this.fixedDuration = this.loanValues!.loanCalculatorValues?.loanFixedDuration ?? 0;
    this.variableDuration = this.loanValues!.loanCalculatorValues?.loanDuration ?? 0;
    if (this.fixedDuration > 0 && this.variableDuration === this.fixedDuration) {
      this.variableDuration = 0;
    }
  }

  private createChart() {
    if (this.loanValues!.loanCalculatorValues?.loanDuration && this.loanValues!.loanCalculatorValues.loanFixedDuration) {
      this.setDurations();

      this.chart = new Chart("yearsChart", {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [this.fixedDuration, this.variableDuration],
            backgroundColor: [
              '#0E4749',
              '#0BC582'
            ],
            hoverOffset: 4,
            borderColor: [
              '#0E4749',
              '#0BC582'
            ],
            borderRadius: 4,
            offset: 20
          }]
        },
        options: {
          cutout: '80%',
          radius: '100%',
        }
      });
    }
  }
}
