<div class="bg-gray-100" [ngClass]="advancedFilterOn? 'overflow-y-hidden':''" *transloco="let t; prefix: 'find-property'">
    <div class="flex flex-col py-8 gap-6 rfs:container mx-auto">
        <h2 class="text-black text-[2rem] font-semibold leading-none">{{t('header')}}</h2>
        <div class="flex xl:flex-row flex-col gap-6">
            <app-search-property (SearchSelected)="searchWithLocation($event)"
                (ItemSelected)="selectSearchItem($event)"></app-search-property>
            <div class="flex md:flex-row flex-col gap-7 xl:justify-end justify-between items-center">
                <div class="flex flex-row gap-7">
                    <app-filter-dropdown [dropdownItems]="propertyTypes" [title]="t('filterTitles.propertyType')"
                        [selectedKeys]="selectedType" [allValuesLabel]="t('filterAllValuesLabels.propertyType')"
                        (selectedItem)="searchWithTypeFilter($event)"></app-filter-dropdown>

                    <app-range-dropdown [dropdownItems]="priceRanges" [title]="t('filterTitles.price')"
                        [preselectedRange]="selectedPriceRange" [allValuesLabel]="t('filterAllValuesLabels.price')"
                        prefixIcon="icon-euro-money"
                        (selectedRangeEvent)="searchWithPriceFilter($event)"></app-range-dropdown>
                    <app-range-dropdown [dropdownItems]="sizeRanges" [title]="t('filterTitles.size')"
                        [preselectedRange]="selectedSizeRange" [allValuesLabel]="t('filterAllValuesLabels.size')" postfix="m²"
                        (selectedRangeEvent)="searchWithSizeFilter($event)"></app-range-dropdown>
                </div>
                <button class="btn-icon-primary flex justify-center items-center" (click)="toggleAdvancedFilers()"><i
                        class="icon-filter text-xl"></i></button>
            </div>
        </div>
    </div>
</div>
<app-popular-areas *ngIf="showPopularAreas"></app-popular-areas>
<app-sponsored-properties></app-sponsored-properties>
<app-property-tag-filters></app-property-tag-filters>
<app-grid-list-cards></app-grid-list-cards>
<app-advanced-filters-drawer [Toggle$]="toggleAdvancedFilters$" (FilterChanged)="searchWithAdvancedFilters($event)"
    (OnHidden)="advancedSearchHidden($event)"></app-advanced-filters-drawer>