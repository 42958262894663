<section class="pt-6 pb-12" *transloco="let t; prefix: 'property-details.detail-section'">
    <h2 class="h2 text-secondary pb-6">{{t('header')}}</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 rfs:gap-8 pb-4 overflow-hidden"
        [ngClass]="{ 'max-h-[245px]': this.isCollapsed }">
        <div class="flex flex-col gap-[14px]">
            <app-detail-table-row [title]="t('titles.type')"
                [text]="'shared.enum.propertyType.'+ model.type | transloco"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.price')"
                [text]="'€ '+ (model.price | number)"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.pricePerSqM')"
                [text]="'€'+ ((model.price ?? 0) / (model.propertyArea ?? 1) | number)"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.livingArea')"
                [text]="model.propertyArea +' m²'"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.floor')" [text]="model.floor"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.rooms')" [text]="model.numberOfRooms"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.availability')"
                [text]="'shared.enum.availability.' + model.availability | transloco"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.constructionYear')"
                [text]="model.constructionYear"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.renovationYear')"
                [text]="model.renovationYear"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.heatingSystem')"
                [text]="'shared.enum.heatingType.' + model.heatingType | transloco"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.heatingControl')"
                [text]="'shared.enum.heatingControl.'+ model.heatingControl | transloco"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.constructionMaterial')"
                [text]="model.constructionMaterial"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.basementSize')"
                [text]="model.basementSize | number"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.category')" [text]="model.category"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.coveredVerandaArea')"
                [text]="model.coveredVeranda"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.developer')" [text]="model.developer"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.energyClass')"
                [text]="'shared.enum.energyClass.' + model.energyClass | transloco"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.estimatedRentalIncome')"
                [text]="model.estRentalIncome | number"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.floorMaterial')"
                [text]="model.floorMaterial"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.frames')" [text]="model.frames"></app-detail-table-row>
        </div>
        <div class="flex flex-col gap-[14px]">
            <app-detail-table-row [title]="t('titles.kaek')" [text]="model.kaek"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.masterRoom')" [text]="model.masterRoom"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.nearbyHighways')"
                [text]="model.nearbyHighways"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.neighborhood')" [text]="model.neighborhood"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.orientation')"
                [text]="'shared.enum.orientation.' + model.orientation | transloco"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.otherCosts')"
                [text]="model.otherCosts | number"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.ownershipPercentage')"
                [text]="model.ownershipPercentage"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.ownershipType')"
                [text]="'shared.enum.ownershipType.' + model.ownershipType | transloco"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.parkingType')" [text]="'shared.enum.parkingType.' + model.parkingType | transloco"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.plotArea')" [text]="model.plotArea"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.propertyStatus')"
                [text]="model.propertyStatus"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.preApproved')" [text]="model.preApproved"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.preApprovalAmount')"
                [text]="'€ '+(model.preApprovalAmmount | number)" *ngIf="model.preApproved"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.preApprovalDate')" [text]="model.preApprovalDate | date"
                *ngIf="model.preApproved"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.previousPrice')"
                [text]="'€ '+ (model.previousPrice | number)"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.price')"
                [text]="'€ '+ (model.price | number)"></app-detail-table-row>
            <app-detail-table-row [title]="'Property usage category'"
                [text]="'shared.enum.propertyUsageCategory.' + model.propertyUsageCategory | transloco"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.residentialZone')"
                [text]="model.residentialZone"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.sharedExpenses')"
                [text]="model.sharedExpenses"></app-detail-table-row>
            <app-detail-table-row [title]="t('titles.singlePropertyOwnershipTax')"
                [text]="model.singlePropertyOwnershipTax"></app-detail-table-row>
        </div>
    </div>
    <button (click)="toggleCollapse($event)" class="btn-text flex gap-2 items-center py-[6px]"><i
            class="icon-chevron-down text-xl button-chevron transition-all duration-500"
            [ngClass]="{ 'icon-chevron-down': this.isCollapsed,'icon-chevron-up': !this.isCollapsed, }"></i>{{t('buttons.collapse')}}</button>
</section>