@if (isRequest) {
  <app-complete-request-callback [requestData]="requestData" (continue)="continue()" (skip)="skip()"></app-complete-request-callback>
}
@else {
  <div class="flex flex-grow container py-8" *transloco="let t; prefix: 'onboarding.onboarding-welcome'">
    <div class="flex-grow flex justify-center">
        <div class="w-full max-w-96 pt-[5vw]">
            <div class="flex flex-col rfs:gap-6">
                <h1 class="text-secondary h1">{{t('header')}}</h1>
                <p class="text-tone-07">{{t('infoText')}}</p>
                <ng-container *ngIf="nav != 'home'">
                    <div>
                        <form class="flex flex-col gap-6">
                            <a [routerLink]="['/'+ nav]" role="button" type="submit"
                                class="btn-primary w-full flex flex-row items-center justify-center">{{t('anchors.knowYourNeeds')}}
                            </a>
                        </form>

                        <a class="text-primary font-semibold text-center flex flex-row items-center w-full justify-center mt-7"
                            [routerLink]="['/'+ 'home']">{{t('anchors.skip')}} <i class="icon-chevron-right"></i></a>
                    </div>
                </ng-container>
                <ng-container *ngIf="nav == 'home'">
                    <div>
                        <form class="flex flex-col gap-6">
                            <a [routerLink]="['/'+ nav]" role="button" type="submit"
                                class="btn-primary w-full flex flex-row items-center justify-center">{{t('anchors.getStarted')}}</a>
                        </form>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="hidden lg:block flex-1 on-boarding-bg-4"></div>
    </div>
</div>
}
