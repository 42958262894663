import { CommonModule, Location } from '@angular/common';
import { Component, Inject, Input, OnInit, } from '@angular/core';
import { ProgressStepperComponent } from "../../shared/components/progress-stepper/progress-stepper.component";
import { NavigationButtonsComponent } from "../../shared/components/navigation-buttons/navigation-buttons.component";
import { OnboardingExitModalComponent } from '../onboarding/onboarding-exit-modal/onboarding-exit-modal.component';
import { ModalService } from '@indice/ng-components';
import { TranslocoDirective, TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { catchError, debounceTime, distinctUntilChanged, mergeMap, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IBranch, IBranchResponse, NbgBranchesApiService } from 'src/app/services/nbg-branches.services';
import { GeolocationService } from 'src/app/core/services/geolocation.service';
import { InitiateWorkflowRequest, PORTAL_API_BASE_URL, PortalApiService, PropertyDetails, PropertySummary, PropertySummaryResultSet } from 'src/app/services/portal-api.service';
import { PropertyCardItemComponent } from "../../shared/components/property-card-item/property-card-item.component";
import { ToasterContainerComponent } from "../../shared/components/toast/toast-container.component";
import { ToasterService, ToastType } from 'src/app/services/toaster.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ILoanApplicationCases, ILoanApplicationModel, LoanApplicationModel, } from './models';
import { LoanApplicationResultComponent } from "./loan-application-result/loan-application-result.component";
import { AuthService } from 'src/app/core/services/auth.service';
import { LoanCalulatorComponent } from "../../shared/components/loan-calulator/loan-calulator.component";
import { DoughnutChartComponent } from "../../shared/components/loan-calulator/doughnut-chart/doughnut-chart.component";
import { LoanService, LoanValues } from 'src/app/services/loan.service';
import { ActivatedRoute } from '@angular/router';
import { DropdownΝationalityComponent, DropdownItem } from 'src/app/shared/components/dropdown-nationality/dropdown-nationality.component';
import { CountryCodeAndCitizen, NationalityService } from 'src/app/services/nationality.service';


@Component({
  selector: 'app-loan-application-wizard',
  standalone: true,
  imports: [CommonModule, ProgressStepperComponent, NavigationButtonsComponent, TranslocoDirective, TranslocoPipe, ReactiveFormsModule, PropertyCardItemComponent, ToasterContainerComponent,
    NgxMaskDirective, LoanApplicationResultComponent, LoanCalulatorComponent, DoughnutChartComponent, DropdownΝationalityComponent],
  templateUrl: './loan-application-wizard.component.html',
  styleUrl: './loan-application-wizard.component.css',
  providers: [provideNgxMask()],
})
export class LoanApplicationWizardComponent {
  protected stepsMap: { [key: string]: string } = {
    //values will be replaced by translation
    'property': '1. THE PROPERTY',
    'loan': '2. YOUR LOAN',
    'details': '3. YOUR DETAILS',
    'guarantor': '4. YOUR GUARANTOR',
    'branch': '5. PREFERRED BRANCH',
    'review': '6. REVIEW AND SUBMIT',
  };

  protected propertyId: string | undefined;
  protected loanForm: FormGroup;
  protected stepKeys: string[] = Object.keys(this.stepsMap);
  protected stepValues: string[] = [];
  protected activeStep: string = this.stepKeys[0];
  protected showChoosePropertyUi = false;
  protected propertySummary: PropertyDetails | undefined;
  protected propertyFavourites: PropertyDetails[] | undefined;
  protected propertyRecentlyViewed: PropertyDetails[] | undefined;
  protected searchControl: FormControl = new FormControl();
  protected searchProperty: FormControl = new FormControl();
  protected nbgBranchesObject$: Observable<IBranchResponse>;
  protected nbgBranches: IBranch[] = [];
  protected filteredBranches: IBranch[] = [];
  protected showBranchList: boolean = false;
  protected isInteractingWithDiv: boolean = false;
  protected coords: { Latitude?: number, Longitude?: number } = {};
  protected currentYear: number = new Date().getFullYear();
  protected thousandSeparator: string = '.';
  protected hiddenSections: boolean[] = Array(5).fill(false);
  protected showResultPage: boolean = false;
  protected loanFormData: ILoanApplicationModel | any;
  protected loanCaseTypeCode: string = 'mortgage-loan';
  private userId: string | undefined;
  protected propertyMortgageCategoryId = '9EEC3373-4411-44C1-82F9-8B12A3C30566'; //this is the category id as provided by nbg
  protected propertyRenovationCategoryId = '300951CE-9CC4-4904-8921-540AD2B6CC20'; //this is the category id as provided by nbg
  protected showFavouritesUi: boolean = true;
  public countryCodesAndCitizens: DropdownItem[] = [];

  constructor(
    private _modalService: ModalService,
    private _nbgBranchesService: NbgBranchesApiService,
    private _translocoService: TranslocoService,
    private _fb: FormBuilder,
    private _geolocationService: GeolocationService,
    private _portalService: PortalApiService,
    private _authService: AuthService,
    private _loanService: LoanService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _nationality: NationalityService,
    @Inject(ToasterService) private _toastService: ToasterService,
  ) {
    _translocoService.selectTranslateObject('loan-wizard').pipe(
      tap(t => {
        this.applyTranslations(t);
      })
    ).subscribe();

    this._nationality.getCountryCodesAndCitizens().map((x,index) => {
      this.countryCodesAndCitizens.push(
        {
          key: index,
          icon: x.code,
          label: x.citizen,
        } as DropdownItem
      )
    });

    this.loanForm = this._fb.group({
      property: this._fb.group({
        propertyId: ['', Validators.required],
        address: ['', Validators.required],
        url: [``], //`${baseUrl}/properties/${this.propertyId}`
      }),
      loan: this._fb.group({
        categoryId: ['', Validators.required],
        realEstateValue: ['', Validators.required],
        amount: ['', Validators.required],
        duration: ['', Validators.required],
        fixedInterestDuration: ['', Validators.required],
      }),
      details: this._fb.group({
        yearBorn: ['', [Validators.required, this.validYearValidator()]],
        professionalStatus: ['', Validators.required],
        marriageStatus: ['', Validators.required],
        children: ['', Validators.required],
        haveCoborrower: [null, Validators.required],
        grossSalaryPerYear: ['', Validators.required],
        householdDebt: ['', Validators.required],
        nationality: ['', Validators.required],
        wherePayTax: ['', Validators.required],
        taxPerYear: ['', Validators.required],
      }),
      guarantor: this._fb.group({
        haveGuarantor: [null, Validators.required],
        yearBornGuarantor: [''],
        childrenGuarantor: [''],
        marriageStatusGuarantor: [''],
        householdIncomeGuarantor: [''],
        taxPerYearGuarantor: [''],
        householdDebtGuarantor: [''],
      }),
      branch: this._fb.group({
        branchInfo: [''],
      }),
      review: this._fb.group({
        consent: [false, Validators.requiredTrue],
      }),
    });

    this.loanForm.get('guarantor.haveGuarantor')?.valueChanges.pipe(
      tap(t => this.setGuarantorValidators(t)),
    ).subscribe();

    this.propertyId = this._route.snapshot.queryParams["property"];

    this.showChoosePropertyUi = !this.propertyId;
    if (!this.showChoosePropertyUi) {
      this._portalService.getPropertyById(this.propertyId ?? '').pipe(
        catchError((err: any) => {
          this._toastService.show(ToastType.Error, 'Failed to get property', err.message || 'Unknown error', 10000);

          return of(undefined);
        }),
        tap((t: PropertyDetails | undefined) => {
          this.propertySummary = t;
          this.loanForm.get('property.propertyId')?.setValue(t?.id);
          let addressOrEmpty = '';
          if (t) {
            addressOrEmpty = this.concatPropertyAddress(t);
          }
          this.loanForm.get('property.address')?.setValue(addressOrEmpty);
        }),
      ).subscribe();
    } else {
      this.searchProperty.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((s) => {
          const propertyUrlNormalized = this.extractPropertyIdFromUrl(s);
          if (!propertyUrlNormalized.isValid) {
            this._toastService.show(ToastType.Error, 'Failed to get property', 'The provided URL is not valid.', 5000);
            return of(undefined);
          }

          return this._portalService.getPropertyById(propertyUrlNormalized.id).pipe(
            catchError((err: any) => {
              this._toastService.show(ToastType.Error, 'Failed to get property', err.message || 'Unknown error', 5000);

              return of(undefined);
            })
          );
        }),
        tap((t: PropertyDetails | undefined) => {
          this.propertySummary = t;

          this.loanForm.get('property.propertyId')?.setValue(t?.id);
          let addressOrEmpty = '';
          if (t) {
            addressOrEmpty = this.concatPropertyAddress(t);
          }
          this.loanForm.get('property.address')?.setValue(addressOrEmpty);
          this.loanForm.get('loan.realEstateValue')?.setValue(t?.price);

        }),
      ).subscribe();

      this._portalService.getMyFavorites(1, 100).pipe(
        catchError((err: any) => {
          this._toastService.show(ToastType.Error, 'Failed to get favourites', err.message || 'Unknown error', 5000);

          return of(undefined);
        }),
        tap((t: PropertySummaryResultSet | undefined) => {
          this.propertyFavourites = t?.items;
        }),
      ).subscribe();

      this._portalService.getMyRecentlyViewed(1, 100).pipe(
        catchError((err: any) => {
          this._toastService.show(ToastType.Error, 'Failed to get recently viewed', err.message || 'Unknown error', 5000);

          return of(undefined);
        }),
        tap((t: PropertySummaryResultSet | undefined) => {
          this.propertyRecentlyViewed = t?.items;
        }),
      ).subscribe();
    }

    this._loanService.currentLoanValues.pipe(
      tap((t: LoanValues) => {
        this.loanForm.get('loan.realEstateValue')?.setValue(t.loanCalculatorValues.propertyValue);
        this.loanForm.get('loan.amount')?.setValue(t.loanCalculatorValues.loanAmount);
        this.loanForm.get('loan.duration')?.setValue(t.loanCalculatorValues.loanDuration);
        this.loanForm.get('loan.fixedInterestDuration')?.setValue(t.loanCalculatorValues.loanFixedDuration);
      })
    ).subscribe();

    this._loanService.currentSelectedCategory.pipe(
      tap(t => this.loanForm.get('loan.categoryId')?.setValue(t)),
    ).subscribe();

    this.nbgBranchesObject$ = this._geolocationService.getCurrentPosition().pipe(
      tap(t => this.coords = { Latitude: t.coords.latitude, Longitude: t.coords.longitude }),
      switchMap((s) => {
        return _nbgBranchesService.findBranches(this.coords);
      }),
      catchError(() => {
        return _nbgBranchesService.findBranches({});
      }),
      tap(t => {
        this.nbgBranches = t.branchMapList;
        this.filterBranches('');
      })
    );

    this.nbgBranchesObject$.subscribe({ error: (err: any) => { console.log(err) }, });

    this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(t => this.filterBranches(t)),
    ).subscribe();
  }

  private isFormValid(): boolean {
    const subForm = this.loanForm.get(this.activeStep) as FormGroup;
    const result = subForm === null || subForm.valid;

    return result;
  }

  private applyTranslations(t: any) {
    this.stepKeys.forEach((key, index) => {
      if (t?.stesps && t?.steps[key]) {
        this.stepsMap[key] = `${index + 1}. ${t?.steps[key]}` ?? this.stepsMap[key];
      }
    });
    this.stepValues = Object.values(this.stepsMap);
  }

  protected onNextClick(): void {
    if (!this.isFormValid()) {
      this.markAllAsTouched(this.loanForm.get(this.activeStep) as FormGroup);

      return;
    }

    const currnetIndex = this.stepKeys.indexOf(this.activeStep);
    const nextIndex = this.stepKeys[currnetIndex + 1];

    if (this.stepKeys.indexOf(nextIndex) > 0) {
      this.activeStep = this.stepKeys[currnetIndex + 1];

      return;
    }

    this.goToResults();
  }
  protected onPrevClick(): void {
    const currnetIndex = this.stepKeys.indexOf(this.activeStep);
    if (currnetIndex === -1) {
      this.activeStep = this.stepKeys[0];;
    } else {
      this.activeStep = this.stepKeys[currnetIndex - 1];
    }
  }

  private goToResults(): void {
    this._authService.getSubjectId().pipe(
      mergeMap(m => {
        const workflowData = this.constructLoanDerailsForWorkflow(m ?? '');
        return this._portalService.initiateWorkflow(workflowData).pipe(
          catchError((err: any) => {
            this._toastService.show(ToastType.Error, 'Failed to create Request', err?.errors?.InitiateWorkflow.join(', ') || 'Unknown error', 5000);

            return of(undefined);
          }),
        );
      }),
      tap((caseId: string | undefined) => {
        if (!caseId) {
          return;
        }
        const applicationId = caseId;
        this.loanFormData = new LoanApplicationModel(applicationId, this.loanForm);
        this.showResultPage = true;
      }),
    ).subscribe();
  }

  private constructLoanDerailsForWorkflow(userIdParam: string): InitiateWorkflowRequest {
    const loanCategoryId = this.loanForm.get('loan.categoryId')?.value;
    let loanType: string = '';
    if (loanCategoryId === this.propertyRenovationCategoryId) {
      loanType = 'renovaction';
    } else {
      loanType = 'mortgage';
    }

    const data: ILoanApplicationCases = {
      propertyId: this.loanForm.get('property.propertyId')?.value,
      userId: userIdParam,
      loanDetails: {
        type: loanType,
        currency: 'EUR',
        amount: Number(this.loanForm.get('loan.amount')?.value),
        children: this.loanForm.get('details.children')?.value,
        durationInYears: Number(this.loanForm.get('loan.duration')?.value),
        fixedInterestRateDurationInYears: Number(this.loanForm.get('loan.fixedInterestDuration')?.value),
        guarantorChildren: this.loanForm.get('guarantor.children')?.value,
        guarantorHouseholdDebt: Number(this.loanForm.get('guarantor.householdDebtGuarantor')?.value),
        guarantorIncome: Number(this.loanForm.get('guarantor.householdIncomeGuarantor')?.value),
        guarantorMarriageStatus: this.loanForm.get('guarantor.marriageStatusGuarantor')?.value,
        guarantorTaxPerYear: Number(this.loanForm.get('guarantor.taxPerYearGuarantor')?.value),
        guarantorYearOfBirth: Number(this.loanForm.get('guarantor.yearBornGuarantor')?.value),
        hasCoBorrower: Boolean(this.loanForm.get('details.haveCoborrower')?.value),
        hasGuarantor: Boolean(this.loanForm.get('guarantor.haveGuarantor')?.value),
        householdDebt: Number(this.loanForm.get('details.householdDebt')?.value),
        marriageStatus: this.loanForm.get('details.marriageStatus')?.value,
        nationality: this.loanForm.get('details.nationality')?.value,
        professionalStatus: this.loanForm.get('details.professionalStatus')?.value,
        propertyValue: Number(this.loanForm.get('loan.realEstateValue')?.value),
        taxPerYear: Number(this.loanForm.get('details.taxPerYear')?.value),
        taxRegion: this.loanForm.get('details.wherePayTax')?.value,
        yearlyGrossSalary: Number(this.loanForm.get('details.grossSalaryPerYear')?.value),
        yearOfBirth: Number(this.loanForm.get('details.yearBorn')?.value),
      }
    };

    let result = new InitiateWorkflowRequest({
      propertyId: this.loanForm.get('property.propertyId')?.value,
      caseTypeCode: this.loanCaseTypeCode,
      data: data
    });

    return result;
  }
  private setGuarantorValidators(haveGuarantor: string): void {
    const guarantorGroup = this.loanForm.get('guarantor') as FormGroup;

    if (!!haveGuarantor) {
      guarantorGroup.get('yearBornGuarantor')?.setValidators([Validators.required, this.validYearValidator()]);
      guarantorGroup.get('childrenGuarantor')?.setValidators([Validators.required]);
      guarantorGroup.get('marriageStatusGuarantor')?.setValidators([Validators.required]);
      guarantorGroup.get('householdIncomeGuarantor')?.setValidators([Validators.required]);
      guarantorGroup.get('taxPerYearGuarantor')?.setValidators([Validators.required]);
      guarantorGroup.get('householdDebtGuarantor')?.setValidators([Validators.required]);
    } else {
      guarantorGroup.get('yearBornGuarantor')?.clearValidators();
      guarantorGroup.get('childrenGuarantor')?.clearValidators();
      guarantorGroup.get('marriageStatusGuarantor')?.clearValidators();
      guarantorGroup.get('householdIncomeGuarantor')?.clearValidators();
      guarantorGroup.get('taxPerYearGuarantor')?.clearValidators();
      guarantorGroup.get('householdDebtGuarantor')?.clearValidators();
    }

    // Update validity after changing validators
    guarantorGroup.get('yearBornGuarantor')?.updateValueAndValidity();
    guarantorGroup.get('childrenGuarantor')?.updateValueAndValidity();
    guarantorGroup.get('marriageStatusGuarantor')?.updateValueAndValidity();
    guarantorGroup.get('householdIncomeGuarantor')?.updateValueAndValidity();
    guarantorGroup.get('taxPerYearGuarantor')?.updateValueAndValidity();
    guarantorGroup.get('householdDebtGuarantor')?.updateValueAndValidity();

  }

  onNationalityChange(selectedValue: string) {
    this.loanForm.get('details.nationality')?.setValue(selectedValue);
  }

  protected hasError(controlName: string): boolean {
    const control = this.loanForm.get(controlName) as FormControl;
    const result = control.invalid && control.touched;
    return result
  }

  private markAllAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        this.markAllAsTouched(control);
      } else {
        control?.markAsTouched();
      }
    });
  }

  private validYearValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const year = control.value;

      if (year >= 1900 && year <= this.currentYear - 16) {
        return null; // Valid year
      } else {
        return { invalidYear: true }; // Invalid year
      }
    };
  }

  protected onSearchControlFocus(): void {
    this.showBranchList = true;
  }

  protected onSerchControlBlur(): void {
    if (!this.isInteractingWithDiv) {
      if (!this.searchControl.value) {
        this.onBranchSelect(null)
      }
      else {
        const branchInfoFormControl = this.loanForm.get('branch.branchInfo') as FormControl;
        this.searchControl.setValue(branchInfoFormControl.value);
      }

      this.showBranchList = false;
    }
    this.isInteractingWithDiv = false;
  }

  protected onDivMouseDown(): void {
    this.isInteractingWithDiv = true;
  }

  private filterBranches(searchTerm: string): void {
    if (!searchTerm || searchTerm.length < 3) {
      this.filteredBranches = this.nbgBranches.slice(0, 10);
    } else {
      const term = searchTerm.toLowerCase();
      this.filteredBranches = this.nbgBranches.filter(f => {
        const combinedText = this.concatBranchInfo(f).toLocaleLowerCase();
        return combinedText.includes(term);
      });
    }
  }

  protected onBranchSelect(branch: IBranch | null): void {
    const branchInfoFormControl = this.loanForm.get('branch.branchInfo') as FormControl;
    let branchText = ''
    if (branch) {
      branchText = this.concatBranchInfo(branch);
    }
    branchInfoFormControl.setValue(branchText);
    this.searchControl.setValue(branchText)

    this.showBranchList = false;
  }

  protected toggleVisibility(index: number): void {
    this.hiddenSections[index] = !this.hiddenSections[index];
  }

  protected goToMyRequests(): void {
    throw new Error('Method not implemented.');
  }

  protected concatBranchInfo(branch: IBranch): string {
    const components = [branch.name, branch.address, branch.areaTitle].filter(f => f); //filter removes empties
    return components.join(', ')
  }

  private concatPropertyAddress(propertyDetails: PropertyDetails): string {
    const components = [
      `${propertyDetails.address?.streetName} ${propertyDetails.address?.streetNumber}`,
      propertyDetails.address?.administrativeAreas,
      propertyDetails.address?.prefecture,
    ].filter(f => f); //filter removes empties

    return components.join(', ');
  }

  protected openModalComponent(): void {
    const exitModal = this._modalService.show(OnboardingExitModalComponent, {
      animated: true,
      keyboard: true
    });
    exitModal.onHidden?.subscribe((response: any) => {

    });
  }

  protected chooseThisProperty(propertyId: string | undefined) {
    this.searchProperty.setValue(`${location.origin}/properties/${propertyId}`);
  }

  /**
  * Returns if url is valid property url and the value is guid. If valid returns the guid.
  * @param url - The url of propertys. Example http://localhost:4200/properties/2705fdb9-2f0d-4860-ac1e-08dca4d6d10d
  */
  private extractPropertyIdFromUrl(url: string): { isValid: boolean, id: string } {
    const guidPattern = /\/properties\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
    const match = url.match(guidPattern);
    let result = { isValid: false, id: '' };
    if (match && match[1]) {
      const guid = match[1];
      const isValidGuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(guid);
      result.isValid = true;
      result.id = guid;
    }
    return result;
  }
}
