<div class="bg-modals-light rounded-medium p-8 shadow-[0_4px_18px_0_rgba(0,0,0,0.17)]"
    *transloco="let t; prefix: 'save-search-modal'">
    <div class="flex flex-col gap-2">
        <div class="flex justify-end">
            <button class="p-2"><i class="icon-close text-xl" (click)="close()"></i></button>
        </div>
        <div class="flex flex-col gap-4">
            <h2 class="h2">{{t('headers.main')}}</h2>
        </div>
    </div>
    <div class="divider-line1 border-b-tone-05 rfs:pt-8"></div>
    <div class="flex flex-col justify-between rfs:pt-6 px-4 h-[306px]">
        <div class="flex flex-col gap-2 group/input" [formGroup]="form">
            <input formControlName="name"
                class="text-tone-09 rounded-full border border-tone-05 py-3 px-4 group-hover/input:bg-tone-01 group-focus-within/input:border-[1.5px] group-focus-within/input:border-tone-07 tranistion-colors duration-300 outline-none">
            <div class="flex flex-col gap-4">
                <h5 class="text-secondary-dark text-lg font-light leading-tight">{{t('headers.input')}}</h5>
            </div>
            <span *ngIf=" !form.get('name')?.valid && (form.get('name')?.touched || form.get('name')?.dirty)">
                {{form.get('name')?.getError('error')}}
            </span>
        </div>
        <div class="flex flex-col gap-4">
            <button class="btn-primary" [disabled]="form.pristine" (click)="save()">{{t('buttons.save')}}</button>
            <button class="btn-text pb-5" (click)="close()">{{t('buttons.close')}}</button>
        </div>
    </div>
</div>