import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild, output } from '@angular/core';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { ActivatedRoute } from '@angular/router';
import { Observable, } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { PropertySummary, } from 'src/app/services/portal-api.service';

class marker {
  icon: string | google.maps.Icon | any;
  title: string | google.maps.MarkerLabel | any;
  id?: string | any;
  content?: any | any;
  position: google.maps.LatLng | any;
}

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [
    CommonModule,
    GoogleMapsModule
  ],
  templateUrl: './map.component.html',
  styleUrl: './map.component.css'
})
export class MapComponent implements AfterViewInit {
  @Input() propertySummaries$ = new Observable<PropertySummary[]>();

  // Set a callback to handle marker clicked on parent component
  @Output() propertyClicked: EventEmitter<string> = new EventEmitter();
  @Output() scrollChanged: EventEmitter<google.maps.LatLngBounds> = new EventEmitter();
  //HTML GoogleMap this is to be used ngAfterViewInit 
  //@ViewChild("propertyMap") 
  map: google.maps.Map | undefined
  markers$: Observable<marker[]> = new Observable<marker[]>();
  bounds = new google.maps.LatLngBounds();
  mapSearch: boolean = false;
  mapOptions: google.maps.MapOptions = {
    maxZoom: 20,
    disableDefaultUI: true, //removes default control icons. this is closer to the designed look.
    streetViewControl: true,
    center: new google.maps.LatLng(38.1169981, 21.6855135), //default map center Athens (when no properties available),
    zoom: 7,
  }

  //style of marker's div.
  contentStyle: string = "bg-cyan-700 rounded-full text-white p-2";

  constructor(private activatedRoute: ActivatedRoute) {

  }
  ngOnInit() {
    this.markers$ = this.propertySummaries$.pipe(
      map(m => this.propertiesToMarkers(m ?? [])),
      tap(t => {
        if (t.length > 0) {
          if (!this.mapSearch) {
            this.map?.fitBounds(this.bounds);
            // this.mapOptions.zoom = undefined;
          }
        }
      }),
    );
    this.activatedRoute.queryParamMap.subscribe(params => {

      this.mapSearch = params.get("mapSearch") === 'true';
    })
  }
  ngAfterViewInit() {
    console.log("AFTER VIEW INIT", this.mapSearch)
    if (!this.mapSearch) {
      if (this.bounds.isEmpty()) {
        this.bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(34.802075, 19.37439),
          new google.maps.LatLng(41.748877, 28.215021)
        );
      }

      this.map?.fitBounds(this.bounds);
      // this.mapOptions.zoom = undefined;
    }
  }

  propertiesToMarkers(properties: PropertySummary[]): marker[] {
    if (properties.length == 0) {
      return [];
    }

    this.bounds = new google.maps.LatLngBounds();

    return properties
      .filter(f => f.address?.geoPoint != null)
      .map(p => {
        let coordinates = p.address?.geoPoint?.split(',');
        let lat = Number.parseFloat(coordinates![0]);
        let lon = Number.parseFloat(coordinates![1]);

        let position = new google.maps.LatLng(lat, lon);
        let m: marker = new marker();
        if (p.price) {
          m.id = p.id;
          m.title = { text: `€ ${p.price!.toLocaleString('el-GR')}`, color: "white" };
          m.icon = "../../assets/tag.png";
          m.position = position;
        }
        else {
          m.id = p.id;
          m.icon = {
            anchor: new google.maps.Point(54, 54),
            url: "../../../assets/PropertyPoint.png"
          };
          m.position = position;
        }
        this.bounds.extend(position);

        return m;
      });
  }

  markerClicked(id?: string) {
    this.propertyClicked.emit(id)
  }

  mapInitialized(map: google.maps.Map) {
    this.map = map;
    this.map!.addListener('idle', () => this.scrollChanged.emit(this.map?.getBounds()));
  }
}