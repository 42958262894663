import { Injectable } from "@angular/core";
import { CaseTypeCodes } from "./workflow.service";

export interface IPaymentsApiService {
}

@Injectable({
    providedIn: 'root'
})
export class PaymentsApiService implements IPaymentsApiService {
    constructor(
    ) {
    }
}

export enum UnikoServiceTypes {
    undefined,
    propertyUpload,
    legalDueDiligence,
    TechnicalReport,
    preapproval,

}

export interface IUnikoServicesConfig {
    type: UnikoServiceTypes;
    sku: string;
    casesCode: string
}

export const UnikoServicesConfig: IUnikoServicesConfig[] = [
    { type: UnikoServiceTypes.propertyUpload, sku: '0001', casesCode: '' },
    { type: UnikoServiceTypes.legalDueDiligence, sku: '0002', casesCode: '' },
    { type: UnikoServiceTypes.TechnicalReport, sku: '0003', casesCode: CaseTypeCodes.technicalReport },
    { type: UnikoServiceTypes.preapproval, sku: '0004', casesCode: CaseTypeCodes.preapproval },
];  