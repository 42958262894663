import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Modal, ModalOptions } from '@indice/ng-components';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { catchError, filter, of, tap } from 'rxjs';
import { ToasterService, ToastType } from 'src/app/services/toaster.service';
import { WorkflowService } from 'src/app/services/workflow.service';

@Component({
  selector: 'app-request-photography-modal',
  standalone: true,
  imports: [CommonModule, TranslocoDirective, TranslocoPipe],
  templateUrl: './request-photography-modal.component.html',
  styleUrl: './request-photography-modal.component.css'
})
export class RequestPhotographyModalComponent {
  public propertyId: string;

  constructor(private _modal: Modal,
    private _workflowService: WorkflowService,
    @Inject(ModalOptions) private _options: ModalOptions<RequestPhotographyModalComponent>,
    @Inject(ToasterService) private _toastService: ToasterService
  ) {
    this.propertyId = _options?.initialState?.propertyId as string;
  }

  protected onConfirm(): void {
    const caseData = {
      propertyId: this.propertyId
    };
    this._workflowService.initiatePhotography(caseData).pipe(
      catchError((error: any) => {
        const errorDetail = error.errors?.InitiateWorkflow?.filter((f: any) => f)
          .join(', ') ?? undefined;

        this._toastService.show(ToastType.Error, 'Failed to initiate request', errorDetail, 10000);
        this._modal.hide();
        return of(undefined)
      }),
      filter((f): f is string => !!f), // Filter out undefined values
      tap(() => {
        this._toastService.show(ToastType.Success, 'Request initiated', undefined, 10000);

        this.closeModal();
      })
    ).subscribe();;
  }
  protected closeModal(): void {
    this._modal.hide();
  }
}
