import { Component, EventEmitter } from '@angular/core';
import { NavigationButtonsComponent } from 'src/app/shared/components/navigation-buttons/navigation-buttons.component';
import { ProgressStepperComponent } from 'src/app/shared/components/progress-stepper/progress-stepper.component';
import { OnboardingExitModalComponent } from '../onboarding/onboarding-exit-modal/onboarding-exit-modal.component';
import { ModalService } from '@indice/ng-components';
import { Router } from '@angular/router';
import { PropertyTypeButtonsComponent } from 'src/app/shared/components/property-type-buttons/property-type-buttons.component';
import { CommonModule } from '@angular/common';
import { Address, CreateListingResponse, LookingForPropertyOfType, PropertySummary, PropertyType } from 'src/app/services/portal-api.service';
import { GoogleMapsSearchAutocompleteSingleComponent } from 'src/app/shared/components/autocomplete/google-maps-search-autocomplete-single/google-maps-search-autocomplete.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip.directive';
import { MapComponent } from 'src/app/shared/components/map/map.component';
import { BehaviorSubject, Subject, catchError, map, of, switchMap, tap } from 'rxjs';
import { RelatedPropertySwiperComponent } from 'src/app/shared/components/related-property-swiper/property-swiper.component';
import { NumericOnlyDirective } from 'src/app/shared/directives/number-only.directive';
import { FormsModule } from '@angular/forms';
import { GeocodeService } from 'src/app/core/services/geocode.service';
import { PlaceViewModel, PropertyListingService, propertyData } from 'src/app/services/property.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { ChangeContext, NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { ServicesPaymentsApiService, InsertOrderItemRequest, InsertOrderRequest, OrderResponse } from 'src/app/core/services/payments-api-service';

@Component({
  selector: 'app-property-upload-wizard',
  standalone: true,
  imports: [
    NavigationButtonsComponent,
    ProgressStepperComponent,
    PropertyTypeButtonsComponent,
    CommonModule,
    GoogleMapsSearchAutocompleteSingleComponent,
    TooltipDirective,
    MapComponent,
    RelatedPropertySwiperComponent,
    NumericOnlyDirective,
    FormsModule,
    TranslocoDirective,
    NgxSliderModule
  ],
  templateUrl: './property-upload-wizard.component.html',
  styleUrl: './property-upload-wizard.component.css'
})
export class PropertyUploadWizardComponent {


  SelectedPropertyType: LookingForPropertyOfType | undefined;
  Step: string = 'Step1';
  Steps: string[] = [
    "1. PROPERTY TYPE",
    "2. FLOOR SPACE",
    "3. LOCATION",
    "4. ASKING PRICE",
    "5. PAYMENT",
  ]
  propertyData: propertyData = {};
  propertyArea: string | null = null;
  placeMarker: BehaviorSubject<PropertySummary[]> = new BehaviorSubject<PropertySummary[]>([]);
  place: PlaceViewModel | null = null;
  minPriceValue: string | null = null;
  maxPriceValue: string | null = null;
  encodedLocationBounds: string | undefined;

  minPrice: number = 20000;
  maxPrice: number = 2000000;

  sliderOptions: Options = {
    floor: 20000,
    ceil: 2000000,
    step: 1000
  };
  constructor(
    private modalService: ModalService,
    private router: Router,
    private geocodeService: GeocodeService,
    private propertyService: PropertyListingService,
    private _paymentsApiService: ServicesPaymentsApiService) {

  }
  OpenModalComponent() {
    const exitModal = this.modalService.show(OnboardingExitModalComponent, {
      animated: true,
      keyboard: true
    });
    exitModal.onHidden?.subscribe((response: any) => {

    });
  }
  SaveStep1() {
    switch (this.SelectedPropertyType) {
      case LookingForPropertyOfType.Apartment:
        this.propertyData.PropertyType = PropertyType.Apartment;
        break;
      case LookingForPropertyOfType.Maisonette:
        this.propertyData.PropertyType = PropertyType.Maisonette;
        break;
      case LookingForPropertyOfType.Villa:
        this.propertyData.PropertyType = PropertyType.Villa;
        break;
      default:
        this.propertyData.PropertyType = PropertyType.Unknown;
        break;
    }
    this.Step = 'Step2';

  }
  propertyTypeSelected(propertyType: LookingForPropertyOfType[]) {
    if (propertyType.length > 0) {
      this.SelectedPropertyType = propertyType[0];
    }
    else {
      this.SelectedPropertyType = undefined
    }
    console.log(this.SelectedPropertyType)
  }

  GoTo(step: string) {
    this.Step = step;
  }
  SaveStep2() {
    this.propertyData.PropertyArea = this.propertyArea ? parseInt(this.propertyArea) : undefined;
    this.Step = 'Step3';
  }
  SaveStep3() {
    this.propertyData.PropertyAddress = this.place ?? undefined;
    this.Step = 'Step4';
  }
  ReturnToStep3() {
    if (this.place) {
      this.placeMarker.next([new PropertySummary({
        address: new Address({ geoPoint: `${this.place.Lat},${this.place.Lon}` })
      })])
    }
    this.Step = 'Step3';
  }
  SaveStep4() {
    this.propertyData.MinPrice = this.minPriceValue ? parseInt(this.minPriceValue) : undefined;
    this.propertyData.MaxPrice = this.maxPriceValue ? parseInt(this.maxPriceValue) : undefined;

    this.Step = 'Step5';
  }
  SaveDataWithPayment() {
    this.propertyService.createPropertyBasicInfo(this.propertyData).pipe(
      switchMap((res: CreateListingResponse) => {
        let orderItems: InsertOrderItemRequest[] = [];
        orderItems.push(new InsertOrderItemRequest({
          sku: '0001',
          quantity: 1,
          propertyId: res.propertyId,
        }));

        let body = new InsertOrderRequest({
          currency: 'EUR',
          orderItems: orderItems,
        });
        return this._paymentsApiService.createMyOrder(body)
      }),
      tap((response: OrderResponse) => {
        if (response && response.number) {
          this.router.navigate([`/checkout/${response.number}`]);
        }
      })
    ).subscribe(
      {
        complete: () => { },
        error: (error: any) => {
          console.error('Error in subscription:', error);
        },
      }
    );
  }

  SaveDataPayLater() {
    this.propertyService.createPropertyBasicInfo(this.propertyData).pipe(
      tap((res: CreateListingResponse) => {
        this.router.navigate([`listing/${res.propertyCode}`]);
      })
    ).subscribe();
  }

  AreaChanged(area: string) {
    this.propertyArea = area;
  }
  SelectedLocation(selectedPlace: PlaceViewModel) {
    this.place = selectedPlace;
    console.log(this.place)
    let encodedMessage = this.geocodeService.protoEncodeGeoSearch(selectedPlace);
    this.encodedLocationBounds = btoa(String.fromCharCode(...encodedMessage));
    this.placeMarker.next([new PropertySummary({
      address: new Address({ geoPoint: `${selectedPlace.Lat},${selectedPlace.Lon}` })
    })])
  }

  MinPriceChanged(price: string) {
    this.minPriceValue = price;
    let realMinPrice = Number(price);
    if (realMinPrice < 20000) {
      realMinPrice = 20000;
    } else if (realMinPrice > 2000000) {
      realMinPrice = 2000000;
    }
    this.minPriceValue = realMinPrice.toString();
    if (this.maxPriceValue && realMinPrice > Number(this.maxPriceValue)) {
      this.minPriceValue = this.maxPriceValue;
    }
    this.minPrice = Number(this.minPriceValue);
  }
  MaxPriceChanged(price: string) {
    this.maxPriceValue = price;
    let realMaxPrice = Number(price);
    if (realMaxPrice > 2000000) {
      realMaxPrice = 2000000;
    } else if (realMaxPrice < 20000) {
      realMaxPrice = 20000;
    }
    this.maxPriceValue = realMaxPrice.toString();
    if (this.minPriceValue && Number(this.minPriceValue) > realMaxPrice) {
      this.maxPriceValue = this.minPriceValue;
    }
    this.maxPrice = Number(this.maxPriceValue);
  }

  public onPriceSliderChange(changeContext: ChangeContext) {
    if (this.minPrice > this.maxPrice) {
      this.maxPrice = this.minPrice;
    }
    this.minPriceValue = changeContext.value.toString();
    if (changeContext.highValue) {
      this.maxPriceValue = changeContext.highValue.toString();
    }
  }


}

