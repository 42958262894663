<ng-container *ngIf="model$ | async; let model">
    <app-hero-section [model]="model"></app-hero-section>
    <div class="container flex pt-10 gap-[84px]">
        <div class="flex-1 max-w-[332px]">
            <app-indication-side-panel [inputData]="sidePanelData"></app-indication-side-panel>
        </div>
        <div class="flex-1">
            <app-paragraph-section scroll-anchor="paragraph" [headerText]="model.description"
                [bodyText]="model.details"></app-paragraph-section>
            <div class="divider-line2 border-b-teal pt-10"></div>
            <app-detail-section scroll-anchor="detail" [model]="model"></app-detail-section>
            <div class="divider-line2 border-b-teal pt-10"></div>
            <app-features-section scroll-anchor="features" [model]="model"></app-features-section>
            <div class="divider-line2 border-b-teal pt-10"></div>
            <app-map-area-section scroll-anchor="mapArea" [lat]="(model?.address?.geoPoint?.split(',')?.at(0))"
                [lon]="(model?.address?.geoPoint?.split(',')?.at(1))"></app-map-area-section>
            <div class="divider-line2 border-b-teal pt-10"></div>
            <app-floor-plan-section scroll-anchor="floorPlan" [address]="model.address?.streetName"
                [plotArea]="model?.plotArea" [numOfRooms]="model.numberOfRooms"></app-floor-plan-section>
            <div class="divider-line2 border-b-teal pt-10"></div>
            <app-documents-section *ngIf="isLoggedIn$ | async; let isLoggedIn"
                scroll-anchor="documents"></app-documents-section>
            <app-property-insights-component [PropertyId]="propertyId"></app-property-insights-component>
            <div *ngIf="isLoggedIn$ | async" class="divider-line2 border-b-teal pt-10"></div>
            <app-financing-section [propertyValue]="model.price" [isLoggedIn]="isLoggedIn$ | async"
                scroll-anchor="financing"></app-financing-section>
            <div>
                <!-- {{>property-details/range-cta}} -->
            </div>
        </div>
    </div>

    <div class="px-20 pb-20" *ngIf="similarProperties && similarProperties.count !== 0">
        <div class="divider-line2 border-b-teal pt-10"></div>
        <app-similar-properties [similarProperties]="similarProperties"></app-similar-properties>
    </div>

</ng-container>
<lib-toaster-container></lib-toaster-container>