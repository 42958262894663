<!-- use the following class to expand the paragraph: paragraph-expand. / paragraph-content/  max-h-[150px]-->
<section class="mb-[78px]" *transloco="let t; prefix: 'property-details.paragraph-section'">
    <div class="relative">
        <h3 class="h3 mb-6">
            {{ this.headerText }}
        </h3>
        <p class="mb-4 overflow-hidden paragraph-content transition-all duration-500"
            [ngClass]="{ 'max-h-[150px]': this.isCollapsed }">
            {{ this.bodyText }}
        </p>
        <div
            class="from-primary-very-light absolute left-0 right-0 bottom-0 top-[70%] z-20 paragraph-opacity transition-all duration-500">
        </div>
    </div>
    <button (click)="toggleCollapse($event)" class="btn-text flex gap-2 items-center py-[6px]"><i
            class="icon-chevron-down text-xl button-chevron transition-all duration-500"
            [ngClass]="{ 'icon-chevron-down': this.isCollapsed,'icon-chevron-up': !this.isCollapsed, }"></i>{{t('buttons.fullDescription')}}</button>
</section>