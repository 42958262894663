import { CommonModule } from '@angular/common';
import { ProgressStepperComponent } from 'src/app/shared/components/progress-stepper/progress-stepper.component';
import { Component } from '@angular/core';
import * as _ from 'lodash';
import { OnboardingData, TogglePropertyTypeButton, ToggleSupportingServicesTypeButton } from './models';
import { GoogleMapsAutocompleteComponent } from 'src/app/shared/components/google-maps-autocomplete/google-maps-autocomplete.component';
import { KindOfSupportPreference, LookingForPropertyOfType, ProfilingAnswers } from 'src/app/services/portal-api.service';
import { Router, RouterModule } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { ModalService } from '@indice/ng-components';
import { OnboardingExitModalComponent } from '../onboarding-exit-modal/onboarding-exit-modal.component';
import { NavigationButtonsComponent } from 'src/app/shared/components/navigation-buttons/navigation-buttons.component';
import { PropertyTypeButtonsComponent } from 'src/app/shared/components/property-type-buttons/property-type-buttons.component';
import { TranslocoDirective } from '@jsverse/transloco';


@Component({
  selector: 'app-onboarding-seller',
  standalone: true,
  imports: [CommonModule, ProgressStepperComponent, GoogleMapsAutocompleteComponent, RouterModule, OnboardingExitModalComponent, NavigationButtonsComponent, PropertyTypeButtonsComponent, TranslocoDirective],
  templateUrl: './onboarding-seller.component.html',
  styleUrl: './onboarding-seller.component.css'
})
export class OnboardingSellerComponent {
  Step: string = "Step1"
  Steps: string[] = [
    "1. YOUR PROPERTY",
    "2. YOUR NEEDS"
  ]
  SelectedPropertyType: LookingForPropertyOfType | undefined;
  SelectedSupportServices: KindOfSupportPreference[] = [];

  SupportServices: ToggleSupportingServicesTypeButton[] = [
    {
      "id": KindOfSupportPreference.LegalSupport,
      "title": "Legal support",
      "icon": "icon-auction",
      "isToggled": false
    },
    {
      "id": KindOfSupportPreference.TechnicalDueDiligenceSupport,
      "title": "Technical due diligence support",
      "icon": "icon-technical-dd",
      "isToggled": false
    }
  ]
  OnboardingData: OnboardingData = new OnboardingData;

  constructor(private _router: Router, private _onboardingService: OnboardingService, private modalService: ModalService) { }
  NgOnInit() {
  }

  buttonSelected(buttonIds: LookingForPropertyOfType[]) {
    if (buttonIds.length > 0) {
      this.SelectedPropertyType = buttonIds[0];
    }
  }
  toggleStep3Select(buttonId: KindOfSupportPreference | undefined) {
    var button = this.SupportServices.find(x => x.id == buttonId);
    if (!button || !button.id)
      return;
    button.isToggled = !button.isToggled;
    let index = this.SelectedSupportServices.indexOf(button.id);
    if (index > -1) {
      this.SelectedSupportServices.splice(index, 1);
    }
    else {
      this.SelectedSupportServices.push(button.id);
    }
  }
  Goto(step: string) {
    this.Step = step;
  }
  SaveStep2() {
    this.OnboardingData.propertyType = this.SelectedPropertyType;
    this.Goto("Step3")
    console.log(this.OnboardingData);
  }
  SaveStep3() {
    this.OnboardingData.services = this.SelectedSupportServices;
    this.Goto("Step4")
    console.log(this.OnboardingData);
  }
  SaveAnswers() {
    var profilingAnswers: ProfilingAnswers = new ProfilingAnswers({
      sellerProtertyType: this.OnboardingData.propertyType,
      sellerKindOfSupportPreference: this.OnboardingData.services
    });
    this._onboardingService.updateAccountOwner(undefined, profilingAnswers).subscribe();
    this._router.navigate(['/login'], { queryParams: { location: '/sell-property-get-started' } });
  }
  openModalComponent() {
    const exitModal = this.modalService.show(OnboardingExitModalComponent, {
      animated: true,
      keyboard: true
    });
    exitModal.onHidden?.subscribe((response: any) => {

    });
  }
}
