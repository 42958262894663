<ng-container *transloco="let t; prefix: 'shared.insights'">
    <div *ngIf="model$ | async as model; else nodata" class="pt-6 pb-12">
        <h2 class="h2 text-black pb-6">{{t('evaluation')}}</h2>
        <div class="grid grid-cols-2 md:grid-cols-4 border border-gray-300 rounded-small items-center">
            <div class="grid grid-cols-1 gap-5 border-r border-r-gray-300 rfs:pt-6 rfs:pb-12">
                <app-doughnut-chart #hottness [stringValue]="model.hotnessScore??''" [title]="t('hotness-score')"
                    [type]="1"></app-doughnut-chart>
            </div>
            <div class="grid grid-cols-1 gap-5 md:border-r border-r-gray-300 rfs:pt-6 rfs:pb-12">
                <app-doughnut-chart #liquidity [stringValue]="model.liquidityScore ?? ''" [title]="t('liquidity-score')"
                    [type]="0"></app-doughnut-chart>
            </div>
            <div class="grid grid-cols-1 h-full grid-rows-2 border-r border-r-gray-300">
                <div class="flex flex-col text-center rfs:py-6">
                    <p class="text-gray-700">{{t('objective-value')}}</p>
                    <h4 class="h4 text-xl text-black flex-1 flex items-center justify-center">{{model.objectiveValue ??
                        '-'}}</h4>
                </div>
                <div class="flex flex-col text-center border-t border-t-gray-300 rfs:py-6">
                    <p class="text-gray-700">{{t('opinion-of-rent')}}</p>
                    <div class="flex-1 flex flex-col items-center justify-center">
                        <h4 class="h4 text-xl text-black">{{t('min')}} {{model.minRentPrice}}</h4>
                        <h4 class="h4 text-xl text-black">{{t('max')}} {{model.maxRentPrice}}</h4>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 h-full grid-rows-2">
                <div class="flex flex-col text-center rfs:py-6">
                    <p class="text-gray-700">{{t('opinion-of-value')}}</p>
                    <div class="flex-1 flex flex-col items-center justify-center">
                        <h4 class="h4 text-xl text-black">{{t('min')}} {{model.minPrice}}</h4>
                        <h4 class="h4 text-xl text-black">{{t('max')}} {{model.maxRentPrice}}</h4>
                    </div>
                </div>
                <div class="flex flex-col text-center border-t border-t-gray-300 rfs:py-6">
                    <p class="text-gray-700">{{t('rental-yield')}}</p>
                    <div class="flex-1 flex flex-col items-center justify-center">
                        <h4 class="h4 text-xl text-black">{{t('min')}} {{model.minRentYieldPercentage}}%</h4>
                        <h4 class="h4 text-xl text-black"> {{t('max')}} {{model.maxRentYieldPercentage}}%</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-4">
            <a href="#" class="btn-text flex gap-2 items-center py-[6px] hidden"><i
                    class="icon-arrow-alt-to-bottom text-xl"></i>{{t('download-marketability-report')}}</a>
        </div>
    </div>
    <ng-template #nodata class="hidden"></ng-template>
</ng-container>