<div (clickOutside)="closeDropdown()" *transloco="let t; prefix: 'find-property.range-dropdown'">
    <div class="flex flex-col gap-1 min-w-[115px]">
        <span
            class=" text-[10px] pb-2 text-gray-700 leading-none border-b border-gray-300 font-semibold">{{title}}</span>
        <button (click)="toggleDropdown()" class="btn-text flex items-center justify-between">
            <p *ngIf="(prefix || prefixIcon) && selectedLabel != allValuesLabel"
                class="flex justify-center items-center gap-1 pr-1">
                <i class="text-gray-700 text-xl" [ngClass]="prefixIcon">{{prefix}}</i>
            </p>
            {{selectedLabel}}
            <p *ngIf="(postfix || postfixIcon) && selectedLabel != allValuesLabel"
                class="flex justify-center items-center gap-1 pl-1">
                <i class="text-gray-700 text-xl" [ngClass]="postfixIcon">{{postfix}}</i>
            </p> <i class="ml-3 text-xl text-primary-500"
                [ngClass]="showResults? 'icon-chevron-up':'icon-chevron-down'"></i>
        </button>
    </div>

    <div *ngIf="showResults"
        class="absolute top-[100%] translate-y-4 left-0 rounded-xl px-4 py-4 shadow-2xl bg-gray-50 z-50">

        <fieldset class="flex flex-col gap-2 group/input"> {{t('from')}}
            <div
                class="flex text-gray-500 rounded-full bg-white border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300">
                <p *ngIf="prefix || prefixIcon" class="flex justify-center items-center gap-1 pr-4">
                    <i class="text-gray-700 text-xl" [ngClass]="prefixIcon">{{prefix}}</i>
                </p>
                <input numericOnly
                    class="bg-white outline-none ml-2 flex-1 group-hover/input:bg-white transition-colors duration-300"
                    [value]="minValue" (change)="minValueChanged($event)">
                <p *ngIf="postfix || postfixIcon" class="flex justify-center items-center gap-1 pl-4">
                    <i class="text-gray-700 text-xl" [ngClass]="postfixIcon">{{postfix}}</i>
                </p>
            </div>
        </fieldset>

        <fieldset class="flex flex-col gap-2 group/input"> {{t('to')}}
            <div
                class="flex text-gray-500 rounded-full bg-white border border-gray-300 py-3 px-4 group-hover/input:bg-white group-focus-within/input:border-[1.5px] group-focus-within/input:text-gray-900 group-focus-within/input:border-primary-500 transition-colors duration-300">
                <p *ngIf="prefix || prefixIcon" class="flex justify-center items-center gap-1 pr-4">
                    <i class="text-gray-700 text-xl" [ngClass]="prefixIcon">{{prefix}}</i>
                </p>
                <input numericOnly
                    class="bg-white outline-none ml-2 flex-1 group-hover/input:bg-white transition-colors duration-300"
                    [value]="maxValue" (change)="maxValueChanged($event)">
                <p *ngIf="postfix || postfixIcon" class="flex justify-center items-center gap-1 pl-4">
                    <i class="text-gray-700 text-xl" [ngClass]="postfixIcon">{{postfix}}</i>
                </p>
            </div>
        </fieldset>

        <button (click)="ClearFilter()"
            class="group/menu-item [&.error]:bg-error-light [&.error]:hover:bg-error-light [&.error]:focus-within:bg-error-light border-b-[1px] border-gray-300 py-3 px-1 [&:not(:disabled,.error)]:focus-within:hover:bg-black/20 [&:not(:disabled,.error)]:focus-within:bg-black/20 [&:not(:disabled,.error)]:hover:bg-black/03 flex items-center justify-between min-w-[280px] w-full">
            <div class="flex items-center ">
                <span class="bg-transparent group-disabled/menu-item:text-gray-50">{{allValuesLabel}}</span>
            </div>
        </button>

        <label *ngFor="let item of dropdownItems; let i = index" role="button" [attr.for]="'menu-item-radio-'+i"
            class="menu-item-radio small my-2">
            <input type="radio" class="hidden" [attr.id]="'menu-item-radio-'+i" name="menu-item-radio-group1"
                (change)="itemSelected(item)" [checked]="selectedLabel==item.label">
            <div class="menu-item-radio-content">
                <div class="flex flex-1 justify-start items-center">
                    <p *ngIf="prefix || prefixIcon" class="flex justify-center items-center gap-1 pr-1">
                        <i class="text-gray-700 text-xl" [ngClass]="prefixIcon">{{prefix}}</i>
                    </p>
                    <span class="menu-item-radio-text">{{item.label}}</span>
                    <p *ngIf="postfix || postfixIcon" class="flex justify-center items-center gap-1 pl-1">
                        <i class="text-gray-700 text-xl" [ngClass]="postfixIcon">{{postfix}}</i>
                    </p>
                </div>
            </div>
        </label>
    </div>
</div>