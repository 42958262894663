import { Injectable } from "@angular/core";
import { AccountOwner, AccountOwnerIntent, PortalApiService, ProfilingAnswers } from "./portal-api.service";
import { Observable } from "rxjs";
import { UserProfile } from "oidc-client-ts";
import { AuthService } from "../core/services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    constructor(private _portalApi: PortalApiService, private _authService: AuthService) { }

    public createAccountOwner(userProfile: UserProfile, profilingAnswers: ProfilingAnswers | undefined): Observable<string> {
        let accountIntent: AccountOwnerIntent | undefined;
        if (<any>userProfile[this._authService.INTENT_CLAIM] == this._authService.INTENT_BUYER) {
            accountIntent = AccountOwnerIntent.Buyer;
        }
        else if (<any>userProfile[this._authService.INTENT_CLAIM] == this._authService.INTENT_SELLER) {
            accountIntent = AccountOwnerIntent.Seller;
        }
        else if (<any>userProfile[this._authService.INTENT_CLAIM] == this._authService.INTENT_UNKNOWN) {
            accountIntent = AccountOwnerIntent.Unknown;
        }

        var accountOwner = new AccountOwner({
            email: userProfile.email,
            firstName: userProfile.given_name,
            lastName: userProfile.family_name,
            intent: accountIntent,
            legalRepresentativeFullName: <any>userProfile[this._authService.LEGAL_REPRESENATIVE_NAME_CLAIM],
            taxNumber: <any>userProfile[this._authService.TAX_NUMBER_CLAIM],
            marketingInfoConsentDate: <any>userProfile[this._authService.CONSENT_COMMERCIAL_DATE_CLAIM],
            tnCConsentDate: <any>userProfile[this._authService.CONSENT_TERMS_DATE_CLAIM],
            mobileNumber: userProfile.phone_number,
            profilingAnswers: profilingAnswers
        });

        return this._portalApi.createAccountOwner(accountOwner);
    }


    public updateAccountOwner(userProfile: UserProfile | undefined, profilingAnswers: ProfilingAnswers | undefined): Observable<void> {

        var accountOwner = new AccountOwner({
            email: userProfile?.email ?? undefined,
            firstName: userProfile?.given_name ?? undefined,
            lastName: userProfile?.family_name ?? undefined,
            intent: userProfile ? <any>userProfile[this._authService.INTENT_CLAIM] : undefined,
            legalRepresentativeFullName: userProfile ? <any>userProfile[this._authService.LEGAL_REPRESENATIVE_NAME_CLAIM] : undefined,
            taxNumber: userProfile ? <any>userProfile[this._authService.TAX_NUMBER_CLAIM] : undefined,
            marketingInfoConsentDate: userProfile ? <any>userProfile[this._authService.CONSENT_COMMERCIAL_DATE_CLAIM] : undefined,
            tnCConsentDate: userProfile ? <any>userProfile[this._authService.CONSENT_TERMS_DATE_CLAIM] : undefined,
            mobileNumber: userProfile?.phone_number ?? undefined,
            profilingAnswers: profilingAnswers
        })
        return this._portalApi.updateAccountOwner(accountOwner);
    }

}
