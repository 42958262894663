<section class="pt-6" *transloco="let t; prefix: 'property-details.floor-plan-section'">
    <h2 class="h2 text-secondary">{{t('header')}}</h2>
    <div class="flex justify-between md:flex-row">
        <div>
            <img class="w-full h-[380px] object-contain" src="assets/static/floor-plan.webp"
                alt="floor plan of a house">
        </div>
        <div class="flex flex-col text-metric-dark justify-center md:justify-end max-w-[180px] w-full pb-12">
            <p *ngIf="address">{{ address }}</p>
            <p *ngIf="plotArea">{{ plotArea }} m²</p>
            <p *ngIf="numOfRooms">{{ numOfRooms }} {{t('text.rooms')}}</p>
        </div>
    </div>
</section>