<section
    class="relative"
    *transloco="let t; prefix: 'property-details.hero-section'"
>
    <div class="w-full h-screen">
        <img
            src="{{ model.defaultPhoto?.uri ?? model.defaultImagePath }}"
            class="w-full h-full object-cover object-center img-disable-context"
        />

        <div
            class="absolute top-[30%] left-[5%] container flex flex-col md:flex-row justify-between"
        >
            <div class="flex py-6">
                <div
                    class="bg-white rounded-[1rem] pb-7 flex flex-col max-w-[333px] z-10"
                >
                    <p
                        class="py-8 text-center text-xl text-gray-700 border-b-[3px] border-gray-300 px-6"
                    >
                        <span class="font-bold text-black">
                            {{ t("text.interestedInProperty") }}
                        </span>
                        {{ t("text.helpFromAdvisor") }}
                    </p>
                    <div
                        class="py-[21px] px-6 flex text-black font-semibold justify-between items-center border-b-[3px] border-gray-300"
                    >
                        {{ t("text.callbackRequest") }}
                        <button
                            class="btn-arrow flex items-center justify-center"
                            (click)="requestCallback()"
                        >
                            <i class="icon-chevron-right text-[20px]"></i>
                        </button>
                    </div>
                    <div
                        class="py-[21px] px-6 flex text-black font-semibold justify-between items-center border-b-[3px] border-gray-300"
                    >
                        {{ t("text.walkthroughRequest") }}
                        <button
                            class="btn-arrow flex items-center justify-center"
                            (click)="requestWalkthrough()"
                        >
                            <i class="icon-chevron-right text-[20px]"></i>
                        </button>
                    </div>
                    <div class="px-6 flex pt-8 pb-4">
                        <button class="btn-primary flex-1">
                            {{ t("buttons.placeOffer") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex z-10 items-start gap-2 py-6">
            <button (click)="openModalComponent()" class="btn-primary-white-sm">
                {{ photos?.count ?? 0 }} {{ t("text.images") }}
            </button>
            <button class="btn-primary-white-sm">
                {{ t("buttons.vrTour") }}
            </button>
            <button class="btn-primary-white-sm">
                {{ t("buttons.floorplan") }}
            </button>
        </div>
    </div>
    <div class="flex justify-between">
        <div class="absolute top-1/2 px-4">
            <button
                class="w-9 h-9 rounded-full text-white bg-transparent border border-white hover:text-primary hover:border-primary transition-all flex justify-center items-center"
            >
                <i class="icon-chevron-left text-xl"></i>
            </button>
        </div>
        <div class="absolute top-1/2 right-0 px-4">
            <button
                class="w-9 h-9 rounded-full text-white bg-transparent border border-white hover:text-primary hover:border-primary transition-all flex justify-center items-center"
            >
                <i class="icon-chevron-right text-xl"></i>
            </button>
        </div>
    </div>

    <div class="absolute top-[84px] w-full backdrop-blur-sm">
        <div class="bg-white/80">
            <div class="container pt-3 pb-7 flex flex-col gap-4 items-start">
                <button
                    (click)="GoBack()"
                    class="btn-text py-[6px] flex gap-2 justify-center items-center"
                >
                    <i class="icon-chevron-left text-xl"></i
                    >{{ t("buttons.back") }}
                </button>
                <div
                    class="flex flex-col md:flex-row gap-2 justify-between items-center w-full"
                >
                    <div class="flex items-center gap-3">
                        <h1 class="h1 text-black">
                            {{ model.title }},
                            {{ model.address?.administrativeAreas }}
                        </h1>
                        <div>
                            <div class="flex gap-2 text-white">
                                <div
                                    *ngIf="model.isHot"
                                    class="py-1 px-2 rounded bg-accent-02 text-[0.625rem] font-bold w-fit border-[1px] border-strawberry leading-tight whitespace-nowrap"
                                >
                                    {{ t("tags.hot") }}
                                </div>
                                <div
                                    *ngIf="model.preApproved"
                                    class="py-1 px-2 rounded bg-aqua text-[0.625rem] font-bold w-fit border-[1px] border-aqua leading-tight whitespace-nowrap"
                                >
                                    {{ t("tags.preApproved") }}
                                </div>
                                <div
                                    *ngIf="model?.auctionRound"
                                    class="py-1 px-2 rounded bg-primary text-[0.625rem] font-bold w-fit border-[1px] border-primary leading-tight whitespace-nowrap"
                                >
                                    {{ t("tags.auction") }}
                                </div>
                                <div
                                    *ngIf="model?.isSponsored"
                                    class="py-1 px-2 rounded bg-dogwood text-[0.625rem] font-bold w-fit border-[1px] border-dogwood leading-tight whitespace-nowrap"
                                >
                                    {{ t("tags.sponsored") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex gap-2 text-black items-center justify-center"
                    >
                        <h2
                            *ngIf="model.previousPrice"
                            class="h2 regular line-through pt-1"
                        >
                            €{{ model.previousPrice | number }}
                        </h2>
                        <h1 class="h1">€{{ model.price | number }}</h1>
                    </div>
                </div>
                <div class="flex justify-between w-full">
                    <div
                        class="text-black text-xl border-gray-300 peer-last:border-transparent"
                    >
                        <span class="leading-none border-r-[1px] pr-4"
                            >{{ t("text.id") }}: {{ model.code }}</span
                        >
                        <span class="leading-none border-r-[1px] pr-4 pl-4">{{
                            model.address?.prefecture
                        }}</span>
                        <span class="leading-none border-r-[1px] pr-4 pl-4"
                            >{{ model.propertyArea | number }} m²</span
                        >
                        <span class="leading-none border-r-[1px] pr-4 pl-4">{{
                            model.type
                        }}</span>
                        <span class="leading-none border-r-[1px] pr-4 pl-4"
                            >{{ model.numberOfRooms }}
                            {{ t("text.bedrooms") }}</span
                        >
                        <span class="leading-none pr-4 pl-4"
                            >{{ model.numberOfWC }}
                            {{ t("text.bathrooms") }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
