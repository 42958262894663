<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    *transloco="let t; prefix: 'property-details.hero-section.modal-image-gallery'"
    [ngClass]="{ 'hidden' : selfHidden }">
    <div class="bg-white rounded-small p-6">
        <div class="flex justify-between">
            <h4 class="h4">{{ title }}</h4>
            <button (click)="closeModal()" class="p-2"><i class="icon-close text-xl"></i></button>
        </div>
        <div class="h-[656px] overflow-hidden pt-2">
            <div class="grid grid-cols-3 gap-3 overflow-y-auto h-full">
                <app-image-item *ngFor="let photo of photos?.items" (modalImageHideEvent)="selfHideSwitch($event)"
                    [imageSrc]="photo?.uri!" [propertyTitle]="photo?.tag??''"></app-image-item>
            </div>
        </div>
        <div class="flex justify-between items-center pt-6">
            <p class="text-tone-09 text-sm">{{ photos?.count ?? 0 }} {{t('text.photos')}}</p>
            <div class="flex gap-4">
                <a href="#" class="btn-primary-sm">{{t('anchors.callbackRequest')}}</a>
                <a href="#" class="btn-secondary-sm">{{t('anchors.walkthroughRequest')}}</a>
            </div>
        </div>

    </div>
</div>