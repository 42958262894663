import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LookingForPropertyOfType } from 'src/app/services/portal-api.service';

@Component({
  selector: 'app-property-type-buttons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './property-type-buttons.component.html',
  styleUrl: './property-type-buttons.component.css'
})
export class PropertyTypeButtonsComponent {
  @Input() IsMultiple: boolean = false;
  @Input() ToggledButtons: LookingForPropertyOfType[] = [];
  @Output() SelectedItems: EventEmitter<LookingForPropertyOfType[]> = new EventEmitter();

  SelectedButtons: LookingForPropertyOfType[] = []
  Buttons: Button[] = [
    {
      "id": LookingForPropertyOfType.Villa,
      "title": "Villa",
      "icon": "icon-villas",
      "isToggled": false
    },
    {
      "id": LookingForPropertyOfType.Apartment,
      "title": "Apartment",
      "icon": "icon-apartment",
      "isToggled": false
    },
    {
      "id": LookingForPropertyOfType.Maisonette,
      "title": "Maisonette",
      "icon": "icon-maisonette",
      "isToggled": false
    },
    {
      "id": LookingForPropertyOfType.HolidayHome,
      "title": "Holliday home",
      "icon": "icon-country-house",
      "isToggled": false
    },
    {
      "id": LookingForPropertyOfType.Professional,
      "title": "Investment",
      "icon": "icon-investment",
      "isToggled": false
    }

  ]
  ngOnInit() {
    this.ToggledButtons.forEach(toggled => {
      this.Buttons.forEach(button => {
        if (button.id === toggled) {
          button.isToggled = true;
        }
      });
    })
  }
  toggleSelect(buttonId: LookingForPropertyOfType) {
    var button = this.Buttons.find(x => x.id == buttonId);
    if (button == null)
      return;
    if (!this.IsMultiple) {
      let turnedOn = true;
      this.Buttons.forEach(x => {
        if (x.id == buttonId) {
          x.isToggled = !x.isToggled;
          turnedOn = x.isToggled;
        }
        else {
          x.isToggled = false;
        }
      });
      if (turnedOn) {
        this.SelectedButtons = [button.id];
      }
      else {
        this.SelectedButtons = []
      }
    }
    else {
      button.isToggled = !button.isToggled;
      let index = this.SelectedButtons.indexOf(button.id);
      if (index > -1) {
        this.SelectedButtons.splice(index, 1);
      }
      else {
        this.SelectedButtons.push(button.id);
      }
    }

    this.SelectedItems.emit(this.SelectedButtons)
  }
}

export class Button {
  id: LookingForPropertyOfType = LookingForPropertyOfType.Unspecified;
  title: string = "";
  icon: string = "";
  isToggled: boolean = false;
}

