<div class="flex-1 flex flex-col mt-[84px]" *transloco="let t; prefix: 'property-listing'">
    <div class="w-full bg-gray-100">
        <div class="container flex flex-col md:flex-row justify-between py-7">
            <h2 class="h2 text-black">{{listingDetails.basicInfo?.address?.streetNumber &&
                listingDetails.basicInfo?.address?.streetName ? listingDetails.basicInfo?.address?.streetNumber + ' '
                + listingDetails.basicInfo?.address?.streetName + ' street' : ''}}</h2>
            <div class="flex gap-4">
                <button class="btn-secondary"><i class="icon-eye mr-1"></i>{{t('buttons.previewListing')}}</button>
                <button class="btn-primary">{{t('buttons.submitForReview')}}</button>
            </div>
        </div>
    </div>
    <section class="rfs:pb-24">
        <div class="container md:flex pt-10 gap-[84px]">
            <div class="flex-1 max-w-[332px] hidden lg:block">
                <aside class="sticky top-24 w-[300px] hidden lg:block">
                    <div class="flex gap-1 items-center mb-8 text-secondary-900 font-semibold">
                        <i class="icon-chevron-left text-lg"></i>
                        <a href="#">{{t('anchors.saveAndExit')}}</a>
                    </div>
                    <nav>
                        <div (click)="onNextTab(propertyListingTab.BasicInfo)"
                            class="py-4 px-4 lg:px-6 border-l-2 flex justify-between items-center transition-colors"
                            [ngClass]="activeTab === propertyListingTab.BasicInfo ? 'bg-white border-black rounded-r-md' : 'hover:text-success hover:border-success'">
                            <button type="button">{{t('tabs.basicInformation')}}</button>
                            <i *ngIf="tabsComplete.basicInfo"
                                class="icon-check text-success text-lg flex justify-between items-center"></i>
                        </div>
                        <div (click)="onNextTab(propertyListingTab.Content)"
                            class="py-4 px-4 lg:px-6 border-l-2 flex justify-between items-center transition-colors"
                            [ngClass]="activeTab === propertyListingTab.Content ? 'bg-white border-black rounded-r-md' : 'hover:text-success hover:border-success'">
                            <button type="button">{{t('tabs.titleAndDescription')}}</button>
                            <i *ngIf="tabsComplete.content" class="icon-check text-success text-lg flex justify-between items-center"></i>
                        </div>
                        <div (click)="onNextTab(propertyListingTab.Features)"
                            class="py-4 px-4 lg:px-6 border-l-2 flex justify-between items-center transition-colors"
                            [ngClass]="activeTab === propertyListingTab.Features ? 'bg-white border-black rounded-r-md' : 'hover:text-success hover:border-success'">
                            <button type="button">{{t('tabs.roomsAndFeatures')}}</button>
                            <i *ngIf="tabsComplete.features" class="icon-check text-success text-lg flex justify-between items-center"></i>
                        </div>
                        <div (click)="onNextTab(propertyListingTab.Construction)"
                            class="py-4 px-4 lg:px-6 border-l-2 flex justify-between items-center transition-colors"
                            [ngClass]="activeTab === propertyListingTab.Construction ? 'bg-white border-black rounded-r-md' : 'hover:text-success hover:border-success'">
                            <button type="button">{{t('tabs.heatingAndConstruction')}}</button>
                            <i *ngIf="tabsComplete.construction" class="icon-check text-success text-lg flex justify-between items-center"></i>
                        </div>
                        <div (click)="onNextTab(propertyListingTab.Surroundings)"
                            class="py-4 px-4 lg:px-6 border-l-2 flex justify-between items-center transition-colors"
                            [ngClass]="activeTab === propertyListingTab.Surroundings ? 'bg-white border-black rounded-r-md' : 'hover:text-success hover:border-success'">
                            <button type="button">{{t('tabs.settingAndSurroundings')}}</button>
                            <i *ngIf="tabsComplete.surroundings" class="icon-check text-success text-lg flex justify-between items-center"></i>
                        </div>
                        <div (click)="onNextTab(propertyListingTab.Ownership)"
                            class="py-4 px-4 lg:px-6 border-l-2 flex justify-between items-center transition-colors"
                            [ngClass]="activeTab === propertyListingTab.Ownership ? 'bg-white border-black rounded-r-md' : 'hover:text-success hover:border-success'">
                            <button type="button">{{t('tabs.ownership')}}</button>
                            <i *ngIf="tabsComplete.ownership" class="icon-check text-success text-lg flex justify-between items-center"></i>
                        </div>
                        <div (click)="onNextTab(propertyListingTab.Photos)"
                            class="py-4 px-4 lg:px-6 border-l-2 flex justify-between items-center transition-colors"
                            [ngClass]="activeTab === propertyListingTab.Photos ? 'bg-white border-black rounded-r-md' : 'hover:text-success hover:border-success'">
                            <button type="button">{{t('tabs.photos')}}</button>
                            <i *ngIf="tabsComplete.photos" class="icon-check text-success text-lg flex justify-between items-center"></i>
                        </div>
                    </nav>
                </aside>
            </div>
            <ng-container *ngIf="listingForm" [ngSwitch]="activeTab">
                <app-basic-information *ngSwitchCase="propertyListingTab.BasicInfo"
                    [basicInfoFormGroup]="listingForm.controls.basicInfo" [advisor]="listingDetails.advisor"
                    (onNextTab)="onNextTab($event)"></app-basic-information>
                <app-content *ngSwitchCase="propertyListingTab.Content"
                    [contentFormGroup]="listingForm.controls.content" [advisor]="listingDetails.advisor"
                    [propertyBasicInfo]="listingDetails.basicInfo" (onNextTab)="onNextTab($event)"></app-content>
                <app-features *ngSwitchCase="propertyListingTab.Features"
                    [featuresFormGroup]="listingForm.controls.features" [advisor]="listingDetails.advisor"
                    (onNextTab)="onNextTab($event)">
                </app-features>
                <app-construction *ngSwitchCase="propertyListingTab.Construction"
                    [constructionFormGroup]="listingForm.controls.construction" [advisor]="listingDetails.advisor"
                    (onNextTab)="onNextTab($event)">
                </app-construction>
                <app-surroundings *ngSwitchCase="propertyListingTab.Surroundings"
                    [surroundingsFormGroup]="listingForm.controls.surroundings" [advisor]="listingDetails.advisor"
                    (onNextTab)="onNextTab($event)"></app-surroundings>
                <app-ownership *ngSwitchCase="propertyListingTab.Ownership"
                    [ownershipFormGroup]="listingForm.controls.ownership" [advisor]="listingDetails.advisor"
                    (onNextTab)="onNextTab($event)"></app-ownership>
                <app-photos [propertyId]="listingDetails.propertyId" *ngSwitchCase="propertyListingTab.Photos"
                    (isComplete)="setPhotosProggress($event)"></app-photos>
            </ng-container>
        </div>
    </section>
</div>