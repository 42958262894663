import { Injectable } from "@angular/core";
import { InitiateWorkflowRequest, PortalApiService } from "./portal-api.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {
    constructor(private _portalApi: PortalApiService) { }

    public initiateEditProperty(data: IEditPropertyData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.editProperty,
            data: data
        });
        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }

    public initiateWalkthrough(data: IWalkthroughData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.walkthrough,
            data: data
        });
        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }

    public initiatePhotography(data: IPhotographyData): Observable<string> {
        const body = new InitiateWorkflowRequest({
            propertyId: data.propertyId,
            caseTypeCode: CaseTypeCodes.photography,
            data: {}
        });

        var rs = this._portalApi.initiateWorkflow(body);

        return rs;
    }
}

export enum CaseTypeCodes {
    mortgageLoan = 'mortgage-loan',
    callback = 'callback',
    preapproval = 'pre-approval',
    editProperty = 'edit-property',
    walkthrough = 'walkthrough',
    technicalReport = 'technical-report',
    photography = 'photography',
}

export interface IEditPropertyData {
    propertyId: string;
    editDetails: IEditDetails;
}

export interface IEditDetails {
    hasPriceChange: boolean;
    hasShortDescriptionChange: boolean;
    hasFloorChange: boolean;
    hasPhotoChange: boolean;
}

export interface IWalkthroughData {
    propertyId: string;
    walkthroughDetails: IWalkthroughDetails;

}
export interface IWalkthroughDetails {
    proposedTimeSlotFrom: string;   //example: "2011-10-05T14:48:00.000Z"
    proposedTimeSlotTo: string;     //example: "2011-10-05T15:48:00.000Z"
}

export interface IPhotographyData {
    propertyId: string;
}