import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, input } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, map, mergeMap, of } from 'rxjs';
import { PortalApiService, PropertyType, Search } from 'src/app/services/portal-api.service';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';
import { AutocompleteBaseComponent } from '../autocomplete-base.component';
import { AddressComponent, AddressComponentModel } from '../../google-maps-autocomplete/helper';
import { ActivatedRoute, Router } from '@angular/router';
import { PlaceViewModel, PropertyListingService } from 'src/app/services/property.service';
import { GeocodeService } from 'src/app/core/services/geocode.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-search-property',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective, TranslocoDirective],
  templateUrl: './search-property.component.html',
  styleUrl: './search-property.component.css'
})
export class SearchPropertyComponent extends AutocompleteBaseComponent {
  @Input() showSearchIcon: boolean = true;
  @Output() public SearchSelected: EventEmitter<any> = new EventEmitter();
  @Output() public ItemSelected: EventEmitter<any> = new EventEmitter();

  public savedSearches: SearchView[] | undefined
  public historySearches: SearchView[] | undefined
  public resultsFound: boolean = false;

  private allSavedSearches: SearchView[] | undefined
  private allhistorySearches: SearchView[] | undefined

  private autocompleteSessionToken: google.maps.places.AutocompleteSessionToken | undefined;
  private savedSearchType = "SavedOnly";
  private historySearchType = "HistoryOnly";
  private _autocompleteService: google.maps.places.AutocompleteService;
  private _placesService: google.maps.places.PlacesService | undefined;
  private showResults$: Subject<void> = new Subject<void>();
  private isLoggedIn: boolean = false

  @ViewChild('searchInput') searchTextbox: any;

  constructor(private router: Router, private portalApiService: PortalApiService,
    private _addressComponentHelper: AddressComponent,
    private _cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private propertyListingService: PropertyListingService,
    private geocodingService: GeocodeService,
    private authService: AuthService) {
    super();
    this._autocompleteService = new google.maps.places.AutocompleteService();
    this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
    this.authService.isLoggedIn().subscribe(x => this.isLoggedIn = x);
  }

  override ngOnInit() {
    this.showResults$.pipe(
      mergeMap(_ => {
        if (this.isLoggedIn) {
          return this.portalApiService
            .getSearches(this.savedSearchType).pipe(
              map(searches => {
                let res: SearchView[] = searches.map(x => {
                  let searchView = new SearchView({ ...x, })
                  searchView.icon = this.propertyTypeIcons(x.query?.type);
                  return searchView
                })
                this.allSavedSearches = res.length > 0 ? res : undefined;
                this.savedSearches = this.allSavedSearches;
              })
            )
        }
        return of(undefined);
      })
    ).subscribe();

    this.showResults$.pipe(
      mergeMap(_ => {
        if (this.isLoggedIn) {
          return this.portalApiService
            .getSearches(this.historySearchType).pipe(
              map(searches => {
                let res: SearchView[] = searches.map(x => {
                  let searchView = new SearchView({ ...x, })
                  return searchView
                })
                this.allhistorySearches = res.length > 0 ? res : undefined;
                this.historySearches = this.allhistorySearches;
              })
            )
        }
        return of(undefined);
      })
    ).subscribe();

    this.debouncer
      .pipe(
        debounceTime(this.debounceMs),
        distinctUntilChanged(),
      )
      .subscribe((value: string) => {
        this.busy = true;
        if (value.length >= this.minLength) {
          this.searchTerm = value;
          this._autocompleteService?.getPlacePredictions(
            {
              input: value,
              sessionToken: this.autocompleteSessionToken,
              componentRestrictions: { country: 'GR' },
              types: ['geocode']
            }, (results, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                this.items = results.filter(this._itemsFilter).sort((one, two) => (one.description < two.description ? -1 : 1));
                this.resultsFound = true;
              } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                this.items = undefined;
                this.resultsFound = false;
              }
              this.busy = false;
            }
          )
        }
        if (value != null && value != "") {
          this.savedSearches = this.allSavedSearches?.filter(search => search.name?.toLowerCase().trim()?.includes(value.toLowerCase().trim()))
          this.historySearches = this.allhistorySearches?.filter(search => search.name?.toLowerCase().trim()?.includes(value.toLowerCase().trim()))
        }
        else {
          this.savedSearches = this.allSavedSearches;
          this.historySearches = this.allhistorySearches;
          this.items = undefined;
        }
      });

  }
  public ngAfterViewInit(): void {
    let map = new google.maps.Map(document.createElement('div') as HTMLElement);
    this._placesService = new google.maps.places.PlacesService(map);

    this.activatedRoute.queryParamMap.subscribe(params => {
      let searchFilters = this.propertyListingService.getSearchFiltersFromUrl(params);
      let searchTerms: string[] = searchFilters.searchTerm?.split('!') ?? [];
      this.selectedItems = [];
      searchTerms.forEach(x => {
        let placeId = decodeURIComponent(x.split('~')[0])
        this.selectPlaceById(placeId, false);
      })
    });

  }

  private propertyTypeIcons(propertyTypes: PropertyType[] | undefined): string | undefined {
    const defaultIcon = "icon-clock"
    if (!propertyTypes || propertyTypes?.length != 1) {
      return defaultIcon
    }
    let propertyType = propertyTypes[0];
    switch (propertyType) {
      case PropertyType.Apartment:
        return "icon-Apartment";
      case PropertyType.Maisonette:
        return "icon-maisonette"
      case PropertyType.Business:
        return "icon-briefcase"
      case PropertyType.Villa:
        return "icon-villas"
      default:
        return "icon-clock"
    }
  }

  public override onListItemSelected(item: google.maps.places.AutocompletePrediction): void {
    this.selectPlaceById(item.place_id, true);
  }

  private selectPlaceById(id: string, emmit: boolean) {
    this._placesService?.getDetails(
      {
        fields: ['address_component', 'geometry', 'formatted_address'],
        placeId: id,
        sessionToken: this.autocompleteSessionToken
      }, (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && result) {
          let addressComponents: AddressComponentModel[] = result['address_components'] ?? [];
          let geometry: google.maps.places.PlaceGeometry = result['geometry'] ?? {};
          let place: PlaceViewModel = {
            PlaceId: id,
            FormatedAddress: result['formatted_address'],
            Country: this._addressComponentHelper.findCountry(addressComponents),
            Prefecture: this._addressComponentHelper.findLocality(addressComponents),
            AdministrativeAreas: this._addressComponentHelper.findAdministativeAreas(addressComponents),
            StreetName: this._addressComponentHelper.findRoute(addressComponents),
            StreetNumber: this._addressComponentHelper.findStreetNumber(addressComponents),
            PostalCode: this._addressComponentHelper.findPostalCode(addressComponents),
            Lon: geometry?.location?.lng() ?? null,
            Lat: geometry?.location?.lat() ?? null,
            Bounds: {
              SwLon: geometry?.viewport?.getSouthWest().lng() ?? undefined,
              NeLat: geometry?.viewport?.getNorthEast().lat() ?? undefined,
              SwLat: geometry?.viewport?.getSouthWest().lat() ?? undefined,
              NeLon: geometry?.viewport?.getNorthEast().lng() ?? undefined
            }
          }

          this.selectedItems.push(place);
          this.items = this.items?.filter(this._itemsFilter);
          this._cdr.detectChanges();
          this.onItemSelected.emit(place);
          this.searchTextbox.nativeElement.value = null;
          this.items = undefined
          this.debouncer.next('')
        }
        if (emmit) {
          this.ItemSelected.emit(this.selectedItems);
        }
        this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
      })
  }
  showDropDown() {
    this.showResults = true
    this.showResults$.next();
  }
  hideDropdown() {
    this.showResults = false
  }

  private _itemsFilter = (item: google.maps.places.AutocompletePrediction) => {
    const selectedItem = this.selectedItems.find(x => x.PlaceId == item.place_id);
    return selectedItem == null || selectedItem == undefined;
  }

  public override onItemRemove(item: any): void {
    const index = this.selectedItems.indexOf(item);
    if (index > - 1) {
      this.selectedItems.splice(index, 1);
      this._cdr.detectChanges();
      this.ItemSelected.emit(this.selectedItems);
    }
  }

  searchBarSearchClicked() {
    this.SearchSelected.emit(this.selectedItems)
  }

  SearchNearby() {
    let userLon: number | undefined;
    let userLat: number | undefined;
    navigator.geolocation.getCurrentPosition((position) => {
      userLon = position.coords.longitude;
      userLat = position.coords.latitude;
      console.log(userLat, userLon)
      this.geocodingService.findNearby(userLat, userLon)
    })
  }

  savedSearchSelected(search: SearchView) {
    this.router.navigate(
      ['/properties'],
      {
        queryParams: { ...search.query }
      }
    );
  }
}


class SearchView extends Search {
  icon?: string
}