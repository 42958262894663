<ng-container *transloco="let t; prefix: 'shared.navigation'">
  <header *ngIf="alternav" class="header fixed top-0 z-50"
    [ngClass]="[isHomePage ? 'secondary' : '', isPropertyDetails ? 'property-details': '']">
    <nav class="flex flex-row h-full items-center justify-between w-full relative">
      <div class="hidden lg:block">
        <a [routerLink]="['/']">
          <img class="contain-content h-8" src="../../../../assets/logo white text.png" alt="" />
        </a>
      </div>
      <div class="lg:hidden block">
        <button (click)="handleMobileMenu()">
          <i class="icon-hamburger-menu text-gray-100 text-2xl"></i>
        </button>
      </div>
    </nav>
  </header>

  <header *ngIf="!alternav" class="header fixed top-0 z-50"
    [ngClass]="[isHomePage ? 'secondary' : '', isPropertyDetails ? 'property-details': '']">
    <nav class="header-menu" *ngIf="onboardingNav">
      <ul>
        <li>
          <a>{{alternavTitle}}</a>
        </li>
      </ul>
    </nav>
    <div *ngIf="onboardingNav"></div>

    <nav class="flex flex-row h-full items-center justify-between w-full relative" *ngIf="!onboardingNav">
      <div class="hidden lg:block">
        <a [routerLink]="['/']">
          <img class="contain-content h-8" src="../../../../assets/logo white text.png" alt="" />
        </a>
      </div>
      <div class="lg:hidden block">
        <button (click)="handleMobileMenu()">
          <i class="icon-hamburger-menu text-gray-100 text-2xl"></i>
        </button>
      </div>
      <div class="header-navigation-menu" [ngClass]="isMobileMenuOpen ? 'show' : ''" *ngIf="!onboardingNav">
        <a [routerLink]="['/properties']" class="tab-wrapper"> {{t('anchors.search')}} </a>
        <a [routerLink]="['/sell-property-get-started']" class="tab-wrapper"> {{t('anchors.sellWithUs')}} </a>
        <a [routerLink]="['/services']" class="tab-wrapper"> {{t('anchors.services')}} </a>
        <a [routerLink]="['/financing']" class="tab-wrapper"> {{t('anchors.financing')}} </a>
      </div>

      <div class="flex flex-row items-center justify-end gap-6" *ngIf="!onboardingNav">
        <ng-container *ngIf="isLoggedIn">
          <a [routerLink]="['/my-profile/selling']" class="text-gray-100 font-light text-xl hover:text-primary">My Pages</a>
          <button (click)="translate()"
            class="flex flex-row items-center justify-center px-4 py-7 bg-transparent hover:bg-black/03 active:bg-black/20 border-b-2 border-transparent hover:border-tone-01 active:border-tone-01 transition-all h-[84px]">
            Translate
          </button>
          <button
            class="flex flex-row items-center justify-center px-4 py-7 bg-transparent hover:bg-black/03 active:bg-black/20 border-b-2 border-transparent hover:border-tone-01 active:border-tone-01 transition-all h-[84px]">
            <div class="w-8 h-8 rounded-full border-2 border-brand-teal overflow-hidden"
              [routerLink]="['account-settings']">
              <img src="{{ authorityUrl }}/avatar/{{ fullName }}/32/278888.png" alt="" class="" />
            </div>
            <i class="icon-chevron-down text-tone-01 ml-2"></i>
          </button>
          <button (click)="logout()" class="text-gray-100 font-light text-xl hover:text-primary">
            {{t('buttons.logout')}}
          </button>
        </ng-container>

        <ng-container *ngIf="!isLoggedIn">
          <a [routerLink]="['/register']"
            class="text-gray-100 font-light text-xl hover:text-primary">{{t('anchors.signUp')}}</a>
          <a [routerLink]="['/login']"
            class="text-gray-100 font-light text-xl hover:text-primary">{{t('anchors.logIn')}}</a>
        </ng-container>
      </div>
    </nav>
  </header>
</ng-container>
