import { Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-sponsored-properties',
  standalone: true,
  imports: [TranslocoDirective],
  templateUrl: './sponsored-properties.component.html',
  styleUrl: './sponsored-properties.component.css'
})
export class SponsoredPropertiesComponent {

}
