<section class="py-8 container mx-auto" *transloco="let t; prefix:'find-property.popular-areas'">
    <h2 class="text-[2rem] text-black font-semibold">{{t('header')}}</h2>
    <div class="relative mt-6 grid grid-cols-2 xl:grid-cols-4 gap-8">
        <div
            class="min-w-[17.5rem] h-[25rem] rounded-lg flex group/area bg-gradient-to-b from-transparent from-45% to-[#0D3232] relative overflow-hidden">
            <div class="absolute inset-0 flex">
                <img src="assets/on-boarding/bg-4.webp" class="flex-1 object-cover object-center">
            </div>
            <div
                class="bg-gradient-to-b from-transparent from-45% to-[#0D3232] z-10 flex-1 flex flex-col justify-end items-start p-4">
                <p class="text-gray-400 font-semibold text-sm leading-5">City name</p>
                <h2 class="h2 text-gray-100">Area name</h2>
                <p
                    class="text-gray-100 line-clamp-3 max-h-0 group-hover/area:max-h-96 overflow-hidden transition-all duration-1000">
                    A
                    short
                    description goes here and can be two or three lines</p>
            </div>
        </div>
        <div
            class="min-w-[17.5rem] h-[25rem] rounded-lg flex group/area bg-gradient-to-b from-transparent from-45% to-[#0D3232] relative overflow-hidden">
            <div class="absolute inset-0 flex">
                <img src="assets/on-boarding/bg-4.webp" class="flex-1 object-cover object-center">
            </div>
            <div
                class="bg-gradient-to-b from-transparent from-45% to-[#0D3232] z-10 flex-1 flex flex-col justify-end items-start p-4">
                <p class="text-gray-400 font-semibold text-sm leading-5">City name</p>
                <h2 class="h2 text-gray-100">Area name</h2>
                <p
                    class="text-gray-100 line-clamp-3 max-h-0 group-hover/area:max-h-96 overflow-hidden transition-all duration-1000">
                    A
                    short
                    description goes here and can be two or three lines</p>
            </div>
        </div>
        <div
            class="min-w-[17.5rem] h-[25rem] rounded-lg flex group/area bg-gradient-to-b from-transparent from-45% to-[#0D3232] relative overflow-hidden">
            <div class="absolute inset-0 flex">
                <img src="assets/on-boarding/bg-4.webp" class="flex-1 object-cover object-center">
            </div>
            <div
                class="bg-gradient-to-b from-transparent from-45% to-[#0D3232] z-10 flex-1 flex flex-col justify-end items-start p-4">
                <p class="text-gray-400 font-semibold text-sm leading-5">City name</p>
                <h2 class="h2 text-gray-100">Area name</h2>
                <p
                    class="text-gray-100 line-clamp-3 max-h-0 group-hover/area:max-h-96 overflow-hidden transition-all duration-1000">
                    A
                    short
                    description goes here and can be two or three lines</p>
            </div>
        </div>
        <div
            class="min-w-[17.5rem] h-[25rem] rounded-lg flex group/area bg-gradient-to-b from-transparent from-45% to-[#0D3232] relative overflow-hidden">
            <div class="absolute inset-0 flex">
                <img src="assets/on-boarding/bg-4.webp" class="flex-1 object-cover object-center">
            </div>
            <div
                class="bg-gradient-to-b from-transparent from-45% to-[#0D3232] z-10 flex-1 flex flex-col justify-end items-start p-4">
                <p class="text-gray-400 font-semibold text-sm leading-5">City name</p>
                <h2 class="h2 text-gray-100">Area name</h2>
                <p
                    class="text-gray-100 line-clamp-3 max-h-0 group-hover/area:max-h-96 overflow-hidden transition-all duration-1000">
                    A
                    short
                    description goes here and can be two or three lines</p>
            </div>
        </div>

        <div class="absolute left-0 top-0 bottom-0 flex items-center -translate-x-1/2">
            <button class="btn-icon-primary flex items-center justify-center">
                <i class="icon-arrow-left text-[20px]"></i>
            </button>
        </div>
        <div class="absolute right-0 top-0 bottom-0 flex items-center translate-x-1/2">
            <button class="btn-icon-primary flex items-center justify-center">
                <i class="icon-arrow-right text-[20px]"></i>
            </button>
        </div>
    </div>
</section>