<section class="pt-6" *transloco="let t; prefix: 'property-details.documents-section'">
    <h2 class="h2 text-secondary pb-6">{{t('header')}}</h2>
    <div class="flex flex-col md:flex-row flex-wrap gap-6">
        <app-document-card [product]="product1" [hyperTitle]="t('documents.energyCertificate')" [hyperIcon]="'file'"
            [title]="t('titles.purchased')" [buttonText]="t('buttonTexts.viewDocument')"></app-document-card>
        <app-document-card [product]="product2" [hyperTitle]="t('documents.legalDueDiligence')" [hyperIcon]="'file'"
            [title]="'€180'" [buttonText]="t('buttonTexts.purchaseNow')" [buttonIcon]="'calendar'"></app-document-card>
        <app-document-card [product]="product3" [hyperTitle]="t('documents.technicalDocument')" [hyperIcon]="'file'"
            [title]="'€180'" [buttonText]="t('buttonTexts.purchaseNow')" [buttonIcon]="'calendar'"></app-document-card>
    </div>
</section>