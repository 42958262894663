import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ComponentRef, Input, NgZone, ViewChild, ViewContainerRef } from '@angular/core';
import { GoogleMap, GoogleMapsModule, MapAdvancedMarker, } from '@angular/google-maps';
import { PointOfInterestComponent } from './point-of-interest/point-of-interest.component';
import { GoogleMapsAutocompleteComponent } from "../../../shared/components/google-maps-autocomplete/google-maps-autocomplete.component";
import { BehaviorSubject, Observable } from 'rxjs';
import { map, scan, take, tap } from 'rxjs/operators';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-map-area-section',
  standalone: true,
  templateUrl: './map-area-section.component.html',
  styleUrl: './map-area-section.component.css',
  imports: [CommonModule, GoogleMapsModule, PointOfInterestComponent, GoogleMapsAutocompleteComponent, TranslocoDirective]
})
export class MapAreaSectionComponent implements AfterViewInit {
  @Input() lat: number | any;
  @Input() lon: number | any;
  @ViewChild("googleMap") map: GoogleMap | undefined;
  @ViewChild("placesHistoryListContainer", { read: ViewContainerRef })
  private vcr!: ViewContainerRef;
  private ref!: ComponentRef<PointOfInterestComponent>
  private bounds = new google.maps.LatLngBounds();

  markerAddedSubject: BehaviorSubject<DtoMarker[]> = new BehaviorSubject<DtoMarker[]>([]);
  markers$: Observable<DtoMarker[]> = this.markerAddedSubject.pipe(
    scan((all: DtoMarker[], current: DtoMarker[]) => {
      all.push(...current);

      this.bounds.extend(new google.maps.LatLng(this.lat, this.lon));

      return all;
    }),
    tap(t => {
      this.mapOptions.zoom = undefined;
      this.map?.fitBounds(this.bounds);
    }),
  );

  //HTML GoogleMap this is to be used ngAfterViewInit 
  protected mapOptions: google.maps.MapOptions = {
    maxZoom: 20,
    disableDefaultUI: true, //removes default control icons. this is closer to the designed look.
    streetViewControl: true,
    center: new google.maps.LatLng(38.1169981, 21.6855135), //default map center Athens (when no properties available),
    zoom: 7,
  }
  /**
   *
   */
  constructor() { }

  ngAfterViewInit() {
    let propertyMarker = new DtoMarker();
    propertyMarker.icon = {
      anchor: new google.maps.Point(54, 54),
      url: "../../../assets/PropertyPoint.png"
    }

    propertyMarker.position = new google.maps.LatLng(this.lat, this.lon);
    // var advancedMarker = new MapAdvancedMarker(map, new NgZone({


    // }));
    this.markerAddedSubject.next([propertyMarker]);
  }

  addPlace($event: any) {
    let newPlaceMarker = new DtoMarker();
    newPlaceMarker.icon = $event.icon;
    newPlaceMarker.position = new google.maps.LatLng(
      $event.geometry.location.lat(),
      $event.geometry.location.lng(),
    );
    this.markerAddedSubject.next([newPlaceMarker]);

    this.addChild(
      ($event.types as Array<any>).at(0).replaceAll('_', ' '),
      $event.name,
      $event.icon
    );
  }

  addChild(hypertitle: string, subtitle: string, icon: string) {
    this.ref = this.vcr.createComponent(PointOfInterestComponent);
    this.ref.instance.hypertitle = hypertitle;
    this.ref.instance.subtitle = subtitle;
    this.ref.instance.icon = 'apartment';
    this.ref.instance.circleColor = 'secondary';
  }

  removeChild() {
    const index = this.vcr.indexOf(this.ref.hostView)
    if (index != -1) this.vcr.remove(index)
  }
}

export class DtoMarker {
  icon: string | google.maps.Icon | any;
  advancedIcon: string | any;
  id?: string | any;
  position: google.maps.LatLng | any;
}